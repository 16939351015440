import { toast } from 'react-toastify';
import Service from '../../lib/Service';
import { adminsActions } from '../ActionTypes';


export const fetchAdmins = () => async (dispatch) => {
  try {
    dispatch({
      type: adminsActions.ADMINS_FETCH_START
    });

    const resData = await Service.GET({
      name: 'admins',
      queryString: ''
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: adminsActions.ADMINS_FETCH_SUCCESS,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: adminsActions.ADMINS_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};

export const saveAdmins = (formData) => async (dispatch) => {
  try {
    const payload = {
      ...formData
    };
    dispatch({
      type: adminsActions.ADMINS_SAVE_START
    });
    await Service[formData.Id === '0' ? 'POST' : 'PUT']({
      name: 'admins',
      payload
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(toast.error(res.message));
    });
    dispatch({
      type: adminsActions.ADMINS_SAVE_SUCCESS
    });
    toast.success('Saved Successfully');
    setTimeout(() => { window.location.href = '/admins'; }, 1000);
  } catch (error) {
    dispatch({
      type: adminsActions.ADMINS_SAVE_FAILED,
      payload: { formError: error.message }
    });
  }
};

export const editAdmins = (AdminsId) => async (dispatch, getState) => {
  try {
    const { adminsList } = getState().admins;
    const data = adminsList.find((item) => item.Id === AdminsId);

    if (!data) {
      throw new Error('Something went wrong');
    }
    dispatch({
      type: adminsActions.ADMINS_SET_FORM,
      payload: { form: data }
    });
  } catch (error) {
    toast.error(error.message);
  }
};

export const resetForm = () => (dispatch) => dispatch({ type: adminsActions.ADMINS_RESET_FORM });

export const deleteAdmins = (Id) => async (dispatch) => Service.DELETE({
  name: 'admins',
  payload: { Id }
}).then((res) => {
  if (res.success) {
    dispatch(fetchAdmins());
    return true;
  }
  throw new Error(res.message);
});
