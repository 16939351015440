import { transActions } from '../ActionTypes';
import Service from '../../lib/Service';


export const fetchTransaction = () => async (dispatch) => {
  try {
    dispatch({
      type: transActions.TRANSACTION_FETCH_START
    });
    const data = await Service.GET({
      name: 'transaction'
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });

    dispatch({
      type: transActions.TRANSACTION_FETCH_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: transActions.TRANSACTION_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};

export const test = () => {};
