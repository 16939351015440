import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../Button';
import {
  InputField, SwitchToggle
} from '../ReduxFormComponents';
import { AdminRoleValidation } from '../../middlewares/formValidator';


const AddEditForm = (props) => (
  <form autoComplete="off" onSubmit={props.handleSubmit}>
    <Field name="Id" component={InputField} type="hidden" className="form-control" />
    <div className="form-group">
      <label>Title</label>
      <Field id="Title" name="Title" component={InputField} className="form-control" placeholder="Enter Title for AdminRoles" />
    </div>
    <div className="form-group">
      <Field label="IsSuperUser" name="IsSuperUser" component={SwitchToggle} type="text" className="form-control" />
    </div>
    <Button
      type="submit"
      className="btn btn-sm btn-success"
      loading={props.formLoading}
      loadingText="Saving..."
      disabled={props.pristine}
    >
      <i className="fas fa-save mr-1" />
      Save
    </Button>
    <Button
      onClick={props.reset}
      className="btn btn-sm btn-outline-secondary ml-2"
      disabled={props.pristine}
    >
      <i className="fas fa-redo mr-1" />
      Reset
    </Button>
  </form>
);

AddEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  pristine: PropTypes.bool.isRequired
};

AddEditForm.defaultProps = {
  formLoading: null
};

const AdminRoleForm = reduxForm({
  form: 'AdminRole Form',
  initialValues: {},
  validate: AdminRoleValidation,
  enableReinitialize: true
})(AddEditForm);

const mapStateToProps = ({ adminRoles }) => ({
  initialValues: adminRoles.form
});

export default connect(mapStateToProps)(AdminRoleForm);
