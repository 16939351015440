import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Button from '../../components/Button';
import SearchBar from '../../components/SearchBar';
import BreadCrumb from '../../components/Breadcrumb';
import * as actions from '../../redux/actions/actions-transaction';
import Loader from '../../components/Loader';


const Transaction = ({ transactionData, tableLoading, getTransaction }) => {
  const [searchVal, setSearchval] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    getTransaction();
  }, []);

  const setTableOption = () => (transactionData && tableLoading ? <Loader /> : 'No Data Found');

  const searchvalue = searchVal && searchVal.toLowerCase();
  const transData = transactionData.filter((item) => (item.Id && `CMP${item.Id.toLowerCase()}`.includes(searchVal)) || (item.CourseName && item.CourseName.toLowerCase().includes(searchvalue)) || ((item.PaymentStatus ? 'Paid' : 'Initiated').toLowerCase().includes(searchvalue))).filter((item) => {
    const createddate = item.CreatedDateTime && item.CreatedDateTime.split('T')[0];
    if (startDate && endDate) {
      return (createddate >= startDate && createddate <= endDate);
    }
    return item;
  });

  const handleTableFilter = (search) => {
    setSearchval(search);
  };

  const handleStartDate = (val) => {
    setStartDate(val);
    if (Date.parse(val) > Date.parse(endDate)) {
      toast.error('Start Date is lesser than End Date');
    }
  };

  const handleEndDate = (val) => {
    setEndDate(val);
    if (Date.parse(val) < Date.parse(startDate)) {
      toast.error('End Date is greater than Start Date');
    }
  };

  const resetFilter = () => {
    setSearchval('');
    setStartDate('');
    setEndDate('');
    return false;
  };

  const columns = [{
    dataField: 'Id',
    text: 'TransactionId / OrderId',
    formatter: (cell, row) => (`CMP${cell} / ${row.OrderId}`),
    headerStyle: {
      width: '305px'
    },
  },
  {
    dataField: 'BatchName',
    text: 'Batch Name'
  },
  {
    dataField: 'CourseName',
    text: 'Course Name'
  },
  {
    dataField: 'UserName',
    text: 'User Name'
  },
  {
    dataField: 'Amount',
    text: 'Amount'
  }, {
    dataField: 'PaymentStatus',
    text: 'Status',
    formatter: (cell, row) => (cell ? <label className="badge badge-success">Paid</label> : <label className="badge badge-info">Initiated</label>),
    headerStyle: {
      width: '80px'
    }
  }, {
    dataField: 'CreatedDateTime',
    text: 'Created at',
    formatter: (cell, row) => (
      new Date(cell).toLocaleDateString()
    ),
    sort: true
  }];

  const defaultSorted = [{
    dataField: 'CreatedDateTime',
    order: 'desc'
  }];


  return (
    <div>
      <div className="container">
        <BreadCrumb currentPage="Transactions" />
        <div className="col-md-12 py-3 mb-5">
          <div className="card border-secondary">
            <div className="card-header">
              <form id="searchFliter">
                <div className="row">
                  <div className="col-md-5">
                    <label className="text-left">Search </label>
                    {/* <span className="fa fa-file-alt cardTitle"> Page</span> */}
                    <SearchBar
                      placeholder="Search by Transaction Id ,Course Name,Status"
                      handleChange={(e) => handleTableFilter(e.target.value)}
                    />
                  </div>
                  <div className="col-md-3">
                    <label className="text-left">From Date</label>
                    <input type="date" name="fromDate" onBlur={(e) => handleStartDate(e.target.value)} className="form-control" />
                  </div>
                  <div className="col-md-3">
                    <label className="text-left">To Date</label>
                    <input type="date" name="toDate" onBlur={(e) => handleEndDate(e.target.value)} className="form-control" />
                  </div>
                  <div className="col-md-1">
                    <Button
                      className="btn btn-info btn-sm mt-4 py-2"
                      name="reset"
                      type="reset"
                      onClick={resetFilter}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </form>

            </div>
            <div className="card-body text-secondary">

              <BootstrapTable
                keyField="Id"
                data={transData}
                columns={columns}
                pagination={paginationFactory()}
                defaultSorted={defaultSorted}
                noDataIndication={setTableOption}
                hover
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


Transaction.defaultProps = {
  tableLoading: false,
  transactionData: [],
  getTransaction: null
};

Transaction.propTypes = {
  tableLoading: PropTypes.bool,
  transactionData: PropTypes.array,
  getTransaction: PropTypes.func,
};

const mapStateToProps = ({ transaction }) => ({
  transactionData: transaction.transactionList,
  tableLoading: transaction.tableLoading
});

const mapDispatchToProps = (dispatch) => ({
  getTransaction: () => dispatch(actions.fetchTransaction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Transaction);
