import { leadsActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utils';

const initialFormData = {
  Id: '0',
  Name: '',
  EmailID: '',
  MobileNumber: '',
  Country: '',
  Course: '',
  Source: '',
  Medium: '',
  Campaign: '',
  PageURL: '',
  ReferenceType: '',
  ReferenceId: ''
};

const initialStatusForm = {
  leadId: '',
  formType: 'status',
  status: '',
  assignedTo: '',
  followUpDate: '',
  remarks: ''
};

const defaultState = {
  leadList: [],
  courseList: [],
  followupList: [],
  tableLoading: false,
  tableStatusLoading: false,
  form: initialFormData,
  statusform: initialStatusForm,
  formLoading: false,
  formError: null,
  deleteId: null
};


export default (state = defaultState, actions) => {
  switch (actions.type) {
    case leadsActions.LEADS_FETCH_START:
      return updateState(state, { tableLoading: true });
    case leadsActions.LEADS_FETCH_SUCCESS:
      return updateState(state, { tableLoading: false, leadList: actions.payload });
    case leadsActions.LEADS_FETCH_ERROR:
      return updateState(state, { tableLoading: false, ...actions.payload });
    case leadsActions.LEADS_COURSES_FETCH_START:
      return updateState(state, { tableLoading: true });
    case leadsActions.LEADS_COURSES_FETCH_SUCCESS:
      return updateState(state, { tableLoading: false, courseList: actions.payload });
    case leadsActions.LEADS_COURSES_FETCH_ERROR:
      return updateState(state, { tableLoading: false, ...actions.payload });
    case leadsActions.LEADS_ADMINS_FETCH_START:
      return updateState(state, { formLoading: true });
    case leadsActions.LEADS_ADMINS_FETCH_SUCCESS:
      return updateState(state, { formLoading: false, adminsList: actions.payload });
    case leadsActions.LEADS_ADMINS_FETCH_ERROR:
      return updateState(state, { formLoading: false, ...actions.payload });
    case leadsActions.LEADS_SAVE_START:
      return updateState(state, { formLoading: true, formError: null, form: initialFormData });
    case leadsActions.LEADS_SAVE_SUCCESS:
      return updateState(state, { formLoading: false, form: initialFormData });
    case leadsActions.LEADS_SAVE_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    case leadsActions.LEADS_STATUS_SAVE_START:
      return updateState(state, { formLoading: true, formError: null, statusform: initialStatusForm });
    case leadsActions.LEADS_STATUS_SAVE_SUCCESS:
      return updateState(state, { formLoading: false, statusform: initialStatusForm });
    case leadsActions.LEADS_STATUS_SAVE_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    case leadsActions.LEADS_SET_FORM:
      return updateState(state, { ...actions.payload, formError: null });
    case leadsActions.LEADS_RESET_FORM:
      return updateState(state, { form: initialFormData });
    default:
      return state;
  }
};
