import { webinarActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utils';

const initialFormData = {
  Id: '0',
  Title: '',
  SlugURL: '',
  ShortDescription: '',
  LongDescription: '',
  AuthorName: '',
  AboutAuthor: '',
  AuthorPic: '',
  OrderNumber: '',
  FeaturedImageURL: ''
};

const defaultState = {
  webinarList: [],
  tableLoading: false,
  form: initialFormData,
  formLoading: false,
  formError: null,
  deleteId: null
};


export default (state = defaultState, actions) => {
  switch (actions.type) {
    case webinarActions.WEBINAR_FETCH_START:
      return updateState(state, { tableLoading: true });
    case webinarActions.WEBINAR_FETCH_SUCCESS:
      return updateState(state, { tableLoading: false, webinarList: actions.payload });
    case webinarActions.WEBINAR_FETCH_ERROR:
      return updateState(state, { tableLoading: false, ...actions.payload });
    case webinarActions.WEBINAR_SAVE_START:
      return updateState(state, { formLoading: true, formError: null, form: initialFormData });
    case webinarActions.WEBINAR_SAVE_SUCCESS:
      return updateState(state, { formLoading: false, form: initialFormData });
    case webinarActions.WEBINAR_SAVE_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    case webinarActions.WEBINAR_SET_FORM:
      return updateState(state, { ...actions.payload, formError: null });
    case webinarActions.WEBINAR_RESET_FORM:
      return updateState(state, { form: initialFormData });
    default:
      return state;
  }
};
