import React from 'react';
import PropTypes from 'prop-types';

const BreadCrumb = (props) => (
  <>
    <nav aria-label="breadcrumb" className="breadcrumb" style={{ marginTop: '25px' }}>
      <h4 className="">{props.currentPage}</h4>
      <ol>
        <li className="breadcrumb-item" aria-current="page">Home</li>
        <li className="breadcrumb-item active" aria-current="page" style={{ textTransform: 'capitalize' }}>{`${props.currentPage}`}</li>
      </ol>
    </nav>
  </>
);

BreadCrumb.propTypes = {
  currentPage: PropTypes.string.isRequired
};

export default BreadCrumb;
