import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import LeadsForm from '../../components/Leads/LeadsForm';
import StatusForm from '../../components/Leads/StatusForm';
import BreadCrumb from '../../components/Breadcrumb';
import * as actions from '../../redux/actions/actions-leads';

class addLeads extends Component {
  constructor(props) {
    super(props);
    this.props.getAdmins();
    if (props.match.params.id !== '0') {
      props.setForm(props.match.params.id);
    } else {
      props.resetForm();
    }
    this.state = {
      currentTab: '0'
    };
  }

  handleSubmit = (FormData) => {
    if (FormData.formType === 'status') {
      this.props.saveStatus(FormData);
    } else {
      this.props.saveLeads(FormData);
    }
  }

  handleClick = (val) => {
    this.setState({ currentTab: val });
    // if (val === '1') {
    //   this.props.getAdmins();
    // }
  }

  render() {
    return (
      <>
        <div className="container">
          <BreadCrumb currentPage="Leads" />
          <div className="col-md-12 py-3 mb-5">
            <div className="card border-secondary">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    { this.props.match.params.id !== '0'
                      ? <span className="fa fa-pencil-alt cardTitle"> Edit Leads</span>
                      : <span className="fa fa-plus-square cardTitle"> Create New Leads</span>}

                  </div>
                  <div className="col-md-2 text-right">
                    <Button
                      className="btn btn-sm btn-secondary"
                      onClick={() => this.props.history.goBack()}
                    >
                      <span className="fa fa-arrow-left" />
                      {' '}
                      Back
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body text-secondary">


                <ul className="nav nav-tabs nav-justified">
                  <li
                    role="presentation"
                    className={`tab-item ${this.state.currentTab === '0' ? 'active' : ''}`}
                    onClick={() => this.handleClick('0')}
                  >
                    <span className="fa fa-user faIcon">
                      {' '}
                      Personal info
                    </span>
                  </li>
                  {
                    this.props.match.params.id !== '0' ? (
                      <li
                        role="presentation"
                        className={`tab-item ${this.state.currentTab === '1' ? 'active' : ''}`}
                        onClick={() => this.handleClick('1')}
                      >
                        <span className="fa fa-paper-plane faIcon">
                          {' '}
                          Status
                        </span>
                      </li>
                    ) : (
                      <li
                        role="presentation"
                        className="tab-item inactive"
                      >
                        <span className="fa fa-paper-plane faIcon">
                          Status
                        </span>
                      </li>
                    )

                  }


                </ul>
                <div className="py-3">
                  {
                    (
                      this.state.currentTab === '0' ? <LeadsForm onSubmit={this.handleSubmit} />
                        : this.state.currentTab === '1' ? <StatusForm onSubmit={this.handleSubmit} />
                          : null // else
                    )
                  }

                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

addLeads.propTypes = {
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  saveLeads: PropTypes.func,
  saveStatus: PropTypes.func,
  getAdmins: PropTypes.func
};

addLeads.defaultProps = {
  formLoading: null,
  formError: null,
  saveLeads: null,
  saveStatus: null,
  getAdmins: null
};

const mapStateToProps = ({ leads }) => ({});


const mapDispatchToProps = (dispatch) => ({
  saveLeads: (data) => dispatch(actions.saveLeads(data)),
  saveStatus: (data) => dispatch(actions.saveStatus(data)),
  setForm: (id) => dispatch(actions.editLeads(id)),
  resetForm: () => dispatch(actions.resetForm()),
  getAdmins: () => dispatch(actions.fetchAdmins())
});

export default connect(mapStateToProps, mapDispatchToProps)(addLeads);
