import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import BatchForm from '../../components/Batch/BatchForm';
import BreadCrumb from '../../components/Breadcrumb';
import * as actions from '../../redux/actions/actions-batch';

class addBatch extends Component {
  constructor(props) {
    super(props);

    if (props.match.params.id !== '0') {
      props.setForm(props.match.params.id);
    } else {
      props.resetForm();
    }
    this.state = {};
    props.getBranch();
    props.getCourses();
    props.getInstallment();
  }

  handleSubmit = (FormData) => {
    // console.log('FormData', FormData);
    this.props.saveBatch(FormData);
  }


  render() {
    return (
      <>
        <div className="container">
          <BreadCrumb currentPage="Batch" />
          <div className="col-md-12 py-3 mb-5">
            <div className="card border-secondary">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    { this.props.match.params.id !== '0'
                      ? <span className="fa fa-pencil-alt cardTitle"> Edit Batch</span>
                      : <span className="fa fa-plus-square cardTitle"> Create New Batch</span>}
                  </div>
                  <div className="col-md-2 text-right">
                    <Button
                      className="btn btn-sm btn-secondary"
                      onClick={() => this.props.history.goBack()}
                    >
                      <span className="fa fa-arrow-left" />
                      {' '}
                      Back
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body text-secondary">
                <BatchForm
                  onSubmit={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

addBatch.propTypes = {
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  saveBatch: PropTypes.func,
  getBranch: PropTypes.func,
  getInstallment: PropTypes.func,
  getCourses: PropTypes.func
};

addBatch.defaultProps = {
  formLoading: null,
  formError: null,
  saveBatch: null,
  getBranch: null,
  getInstallment: null,
  getCourses: null
};

const mapStateToProps = ({ batch }) => ({
});


const mapDispatchToProps = (dispatch) => ({
  getInstallment: () => dispatch(actions.fetchInstallment()),
  getBranch: () => dispatch(actions.fetchBranch()),
  getCourses: () => dispatch(actions.fetchCourses()),
  saveBatch: (data) => dispatch(actions.saveBatch(data)),
  setForm: (id) => dispatch(actions.editBatch(id)),
  resetForm: () => dispatch(actions.resetForm())
});

export default connect(mapStateToProps, mapDispatchToProps)(addBatch);
