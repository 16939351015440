import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import EmailTemplateForm from '../../components/EmailTemplate/EmailTemplateForm';
import BreadCrumb from '../../components/Breadcrumb';
import * as actions from '../../redux/actions/actions-emailTemplate';

class addEmailTemplate extends Component {
  constructor(props) {
    super(props);
    if (props.match.params.id !== '0') {
      props.setForm(props.match.params.id);
    } else {
      props.resetForm();
    }
    this.state = {};
  }

  handleSubmit = (FormData) => {
    this.props.saveEmailTemplate(FormData);
  }

  render() {
    return (
      <>
        <div className="container">
          <BreadCrumb currentPage="EmailTemplate" />
          <div className="col-md-12 py-3 mb-5">
            <div className="row">
              <div className="col-md-12">
                <div className="alert alert-info">
                  <strong>Instruction:</strong>
                  <p>
                    Please copy and paste the following tags to use the content dynamically to send email for the candidates.
                    <br />
                    {'{{name}} {{emailId}} {{mobileNumber}}'}
                  </p>
                </div>
              </div>
            </div>
            <div className="card border-secondary">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    { this.props.match.params.id !== '0'
                      ? <span className="fa fa-pencil-alt cardTitle"> Edit Email Template</span>
                      : <span className="fa fa-plus-square cardTitle"> Create New Email Template</span>}
                  </div>
                  <div className="col-md-2 text-right">
                    <Button
                      className="btn btn-sm btn-secondary"
                      onClick={() => this.props.history.goBack()}
                    >
                      <span className="fa fa-arrow-left" />
                      {' '}
                      Back
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body text-secondary">

                <EmailTemplateForm
                  onSubmit={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

addEmailTemplate.propTypes = {
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  saveEmailTemplate: PropTypes.func
};

addEmailTemplate.defaultProps = {
  formLoading: null,
  formError: null,
  saveEmailTemplate: null
};

const mapStateToProps = ({ emailTemplate }) => ({});

const mapDispatchToProps = (dispatch) => ({
  saveEmailTemplate: (data) => dispatch(actions.saveEmailTemplate(data)),
  setForm: (id) => dispatch(actions.editEmailTemplate(id)),
  resetForm: () => dispatch(actions.resetForm())
});

export default connect(mapStateToProps, mapDispatchToProps)(addEmailTemplate);
