
export const validateEmail = (email) => {
  if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(email)) {
    return true;
  } return false;
};

export const validateEmailSync = async (email) => new Promise((resolve, reject) => {
  if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    .test(email)) {
    resolve(email);
  } else {
    reject(new Error('Email Invalid'));
  }
});

export const validatePassword = (password) => {
  if (password && password.length >= 3) {
    return true;
  } return false;
};

export const validatePasswordSync = async (password) => new Promise((resolve, reject) => {
  if (password && password.length >= 3) {
    resolve(password);
  } else {
    reject(new Error('Password must have atleast 6 characters'));
  }
});

export const validateMobileNo = (mobileNumber) => {
  if (mobileNumber && mobileNumber.length === 10) {
    return true;
  } return false;
};

export const validateMobileNoSync = async (mobileNumber) => new Promise((resolve, reject) => {
  if (mobileNumber && mobileNumber.length === 10) {
    resolve(mobileNumber);
  } else {
    reject(new Error('Mobile Number Invalid'));
  }
});

export const validateURL = (url) => /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!%$&'()*+,;=.]+$/.test(url);

export const validatePincode = (pincode) => /^[0-9]{6}$/.test(pincode);

export const validateTime = (time) => /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/.test(time);
