import React from 'react';

const Loader = () => (
  <div>
    <div className="divLoader">
      <i className="fas fa-sync fa-spin loader" />
    </div>
  </div>
);

export default Loader;
