import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../Button';
import {
  InputField, TinyMCEEditor
} from '../ReduxFormComponents';


const LMForm = (props) => (
  <form onSubmit={props.handleSubmit}>
    <Field name="Id" component={InputField} type="hidden" className="form-control" />
    <div className="row form-group">
      <div className="col-md-8">
        <label className="text-left">Name</label>
        <div>
          <Field name="Name" component={InputField} type="text" className="form-control" />
        </div>
      </div>
    </div>
    <div className="form-group ">
      <label>Description</label>
      <div>
        <Field
          name="Description"
          className="form-control"
          component={TinyMCEEditor}
          minHeight={600}
          inlineEditor
        />
      </div>
    </div>
    <div className="py-2 mt-2">
      <Button
        type="submit"
        className="btn btn-sm btn-success"
        loading={props.formLoading}
        loadingText="Saving..."
        disabled={props.pristine}
      >
        <i className="fas fa-save mr-1" />
        Save
      </Button>
      <Button
        onClick={props.reset}
        className="btn btn-sm btn-outline-secondary ml-2"
        disabled={props.pristine}
      >
        <i className="fas fa-redo mr-1" />
        Reset
      </Button>
    </div>
  </form>
);

LMForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  pristine: PropTypes.bool.isRequired
};

LMForm.defaultProps = {
  formLoading: null,
  formError: null
};

const lmForm = reduxForm({
  form: 'LM Form',
  initialValues: {},
  enableReinitialize: false
})(LMForm);

const mapStateToProps = ({ learningModule }) => ({
  initialValues: learningModule.form
});

export default connect(mapStateToProps)(lmForm);
