/* eslint-disable max-classes-per-file */
import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import SearchSelect from './SearchSelect';
import '../styles/ReduxFormComponents.scss';
import { TINY_MCE_API_KEY } from '../lib/Config';

export const InputField = ({
  input,
  placeholder,
  type,
  className,
  disabled,
  meta: { touched, error, warning }
}) => (
  <>
    <input disabled={disabled} {...input} placeholder={placeholder} type={type} className={`${className} ${touched && error ? 'is-invalid' : ''}`} />
    {touched && error && (
      <div className="invalid-feedback">
        {error}
      </div>
    )}
    {touched && (warning && (
      <span className="redux-form-warning">
        {warning}
      </span>
    ))}
  </>
);


InputField.defaultProps = {
  placeholder: null,
  type: null,
  className: null,
  disabled: false
};

InputField.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export const TextArea = ({
  input,
  placeholder,
  type,
  rows,
  resizable,
  className,
  meta: { touched, error, warning }
}) => (
  <>
    <textarea {...input} rows={rows} placeholder={placeholder} type={type} className={`${className} ${touched && error ? 'is-invalid' : ''}`} style={resizable ? {} : { resize: 'none' }} />
    {touched && error && (
      <div className="invalid-feedback">
        {error}
      </div>
    )}
    {touched && (warning && (
      <span className="redux-form-warning">
        <i className="fas fa-exclamation-circle" data-toggle="tooltip" data-placement="top" title={warning} />
      </span>
    ))}
  </>
);

TextArea.defaultProps = {
  placeholder: null,
  type: null,
  className: null,
  rows: null,
  resizable: true
};

TextArea.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  meta: PropTypes.object.isRequired,
  rows: PropTypes.number,
  resizable: PropTypes.bool
};

export const SwitchToggle = ({
  input,
  label,
  fullWidthLabel,
  meta: { touched, error, warning }
}) => (
  <>
    <div className="switch-container w-100">
      <span className={`label-default ${fullWidthLabel ? 'label-text' : ''}`}>{label}</span>
      <label className="switch ml-2">
        <input
          {...input}
          checked={input.value}
          type="checkbox"
        />
        <span className="slider round" />
      </label>
    </div>
    {touched && (
      <div className="switch-box-status w-100">
        {(error && (
          <span className="redux-form-error">
            <i className="fas fa-times-circle mr-1" />
            {error}
          </span>
        )) || (warning && (
          <span className="redux-form-warning">
            <i className="fas fa-exclamation-circle mr-1" />
            {warning}
          </span>
        ))}
      </div>
    )}
  </>
);

SwitchToggle.defaultProps = {
  label: null,
  fullWidthLabel: false
};


SwitchToggle.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.any,
  fullWidthLabel: PropTypes.bool
};

export const SelectField = ({
  input,
  placeholder,
  className,
  children,
  showSearch,
  list,
  disabled,
  dataField,
  dataKey,
  meta: { touched, error, warning }
}) => (
  <div className="select-field">
    {showSearch ? (
      <SearchSelect
        {...input}
        list={list}
        dataField={dataField}
        dataKey={dataKey}
        disabled={disabled}
        placeholder={placeholder}
        className={`${className} ${touched && error ? 'is-invalid' : ''}`}
      />
    ) : (
      <select
        {...input}
        value={String(input.value)}
        className={`${className} ${touched && error ? 'is-invalid' : ''}`}
        disabled={disabled}
      >
        {children}
      </select>
    )}
    {touched
      && ((error && (
        <span className="redux-form-error">
          {error}
        </span>
      )) || (warning && (
        <span className="redux-form-warning">
          {warning}
        </span>
      )))}
  </div>
);

SelectField.defaultProps = {
  className: null,
  placeholder: null,
  children: null,
  showSearch: false,
  list: [],
  dataField: null,
  dataKey: 'id',
  disabled: false
};

SelectField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  children: PropTypes.any,
  showSearch: PropTypes.bool,
  list: PropTypes.array,
  dataField: PropTypes.string,
  dataKey: PropTypes.string,
  disabled: PropTypes.bool
};


export class TinyMCEEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: ''
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      content: props.input.value
    };
  }

  // handleEditorChange = (content) => {
  //   this.setState({ content }, () => this.props.input.onChange(content));
  // }

  handleEditorChange = (content) => {
    this.props.input.onChange(content);
    if (this.props.editorOnChange) this.props.editorOnChange(content);
  }

  render() {
    const {
      meta: { touched, error, warning }, minHeight, inlineEditor, input
    } = this.props;
    const init = {
      plugins: 'lists link image media imagetools code advlist table fullscreen',
      toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code image media | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | forecolor backcolor | fullscreen',
      advlist_bullet_styles: 'default,circle,disc,square',
      advlist_number_styles: 'default,lower-alpha,lower-greek,lower-roman,upper-alpha,upper-roman',
      imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
      min_height: minHeight,
      // images_upload_handler(blobInfo, success, failure) {
      //   const file = blobInfo.blob();
      //   let s3Url = null;
      //   Service.GET({
      //     name: 'file-upload',
      //     queryString: `objectName=${file.name}&contentType=${file.type}`,
      //   })
      //     .then((result) => {
      //       const { signedUrl } = result;
      //       s3Url = result.publicUrl;
      //       const options = {
      //         headers: {
      //           'Content-Type': file.type,
      //           'x-amz-acl': 'public-read',
      //         }
      //       };
      //       delete axios.defaults.headers.common.Authorization;
      //       return axios.put(signedUrl, file, options);
      //     }).then(() => {
      //       success(s3Url);
      //     })
      //     .catch((er) => {
      //       failure(er.message);
      //     });
      // }
    };
    if (inlineEditor) {
      init.selector = `#${input.name}`;
      init.inline = true;
    }
    return (
      <div className="d-block tiny-mce-wrapper">
        {inlineEditor && (
          <div style={{ minHeight, overflow: 'auto' }} id={input.name} className={`form-control ${touched && error ? 'is-invalid' : ''}`} />
        )}
        <div className={`tiny-mce-editor ${inlineEditor ? 'inline' : ''}`}>
          <Editor
            apiKey={TINY_MCE_API_KEY}
            // tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
            init={init}
            value={this.state.content || ''}
            onEditorChange={(e) => this.handleEditorChange(e)}
          />
        </div>
        {touched && (
          <div className="tiny-mce-status w-100">
            {(error && (
              <span className="redux-form-error">
                {error}
              </span>
            )) || (warning && (
              <span className="redux-form-warning">
                {warning}
              </span>
            ))}
          </div>
        )}
      </div>
    );
  }
}

TinyMCEEditor.defaultProps = {
  minHeight: 500,
  inlineEditor: false
};

TinyMCEEditor.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  minHeight: PropTypes.number,
  inlineEditor: PropTypes.bool
};


export class MultiSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listOpen: false,
      mouseOnList: false,
      inputText: '',
      selectedValue: []
    };
  }

  static getDerivedStateFromProps(props) {
    const getIds = props.input.value;
    if (Array.isArray(getIds) && getIds.length > 0) {
      const selectedValue = [];
      getIds.forEach((item) => {
        const selectedOption = props.suggestionList.find((i) => i[props.dataKey] === (typeof item === 'string' ? item : item.Id));
        if (selectedOption) {
          selectedValue.push(selectedOption);
        }
      });
      return { selectedValue };
    }
    return null;
  }

  handleEditorChange = (search) => {
    this.setState({
      inputText: search.value,
    });
  }

  getSelectedOption = (Id) => {
    const selectedValCopy = this.state.selectedValue.slice();
    const selectedOption = this.props.suggestionList.find((p) => p[this.props.dataKey] === Id);
    if (selectedOption) {
      selectedValCopy.push(selectedOption);
    }
    this.setState(() => ({
      selectedValue: selectedValCopy,
      inputText: '',
      listOpen: false
    }), () => {
      this.props.input.onChange(selectedValCopy);
    });
  }

  removeTag = (Id) => {
    const index = this.state.selectedValue.findIndex((i) => i[this.props.dataKey] === Id);
    if (index > -1) {
      const selectedValCopy = this.state.selectedValue.slice();
      selectedValCopy.splice(index, 1);
      this.setState(() => ({
        selectedValue: selectedValCopy,
        listOpen: false
      }), () => {
        this.props.input.onChange(selectedValCopy);
      });
    }
  }

  render() {
    const {
      input, label, meta: { touched, error, warning }
    } = this.props;
    const fliterList = this.props.suggestionList
      .filter((p) => p[this.props.dataField].toLowerCase().includes(this.state.inputText.toLowerCase()) && !this.state.selectedValue.find((val) => (val[this.props.dataKey] === p[this.props.dataKey])));
    return (
      <>
        <label>{label}</label>
        <div>
          <div className="multiSelect">
            <div className="taginputs">
              <span className="inputSpan">
                {
                  this.state.selectedValue.map((values) => (
                    <span
                      role="presentation"
                      className="tag label label-info"
                      onClick={() => this.removeTag(values[this.props.dataKey])}
                      key={values[this.props.dataKey]}
                    >
                      {values[this.props.dataField]}
                      <span className="fa fa-times ml-3 removeTag" />
                    </span>
                  ))
                }
                <input
                  {...input}
                  className="multiselectInput"
                  value={this.state.inputText}
                  autoComplete="false"
                  onChange={(e) => this.handleEditorChange(e.target)}
                  onFocus={() => this.setState({ listOpen: true })}
                  onBlur={() => this.setState((state) => ({ listOpen: state.mouseOnList }))}
                />
              </span>
            </div>
            {fliterList.length > 0 && (
              <ul
                onMouseEnter={() => {
                  this.setState({
                    mouseOnList: true
                  });
                }}
                onMouseLeave={() => {
                  this.setState({
                    mouseOnList: false
                  });
                }}
                className={`list-group ${this.state.listOpen ? 'd-block' : 'd-none'}`}
              >
                {
                  fliterList.map((item) => (
                    <li
                      role="presentation"
                      className="list-group-item"
                      key={item[this.props.dataKey]}
                      onClick={() => this.getSelectedOption(item[this.props.dataKey])}
                    >
                      {item[this.props.dataField]}
                    </li>
                  ))
                }
              </ul>
            )}
          </div>
          {touched
            && ((error && <span>{error}</span>)
              || (warning && <span>{warning}</span>))}
        </div>
      </>
    );
  }
}

MultiSelect.defaultProps = {
  label: null,
  setValues: [],
  suggestionList: []
};

MultiSelect.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  dataKey: PropTypes.any.isRequired,
  dataField: PropTypes.any.isRequired,
  label: PropTypes.any,
  setValues: PropTypes.array,
  suggestionList: PropTypes.array
};


export const RepeaterTwoFields = ({
  input, type, dataKey, setValue, dataField, className, meta: { touched, error }, handleRepeaterChange
}) => {
  const initalVal = setValue.length > 0 ? setValue.map((item) => ({ id: Math.random(), keyName: item.param, value: item.value })) : [{ id: Math.random(), keyName: '', value: '' }];
  const [fieldArray, setFieldArray] = useState(initalVal);

  const incFieldCount = () => {
    const payloadArr = fieldArray.slice();
    payloadArr.push({
      id: new Date().getTime(),
      keyName: '',
      value: ''
    });
    setFieldArray(payloadArr);
    input.onChange(payloadArr);
  };

  const decFieldCount = (id) => {
    const selPayloadArr = fieldArray.slice();
    const delIndex = selPayloadArr.findIndex((i) => i.id === id);
    if (delIndex > -1) {
      selPayloadArr.splice(delIndex, 1);
      setFieldArray(selPayloadArr);
      input.onChange(selPayloadArr);
    }
  };

  const handlekeyChange = ({ id }, value) => {
    const selkeyArr = fieldArray.slice();
    const keyIndexArr = selkeyArr.findIndex((i) => i.id === id);
    if (keyIndexArr > -1) {
      selkeyArr[keyIndexArr].keyName = value;
      setFieldArray(selkeyArr);
    }
  };

  const handleValueChange = ({ id }, value) => {
    const selPayloadArr = fieldArray.slice();
    const delIndex = selPayloadArr.findIndex((i) => i.id === id);
    if (delIndex > -1) {
      selPayloadArr[delIndex].value = value;
      setFieldArray(selPayloadArr);
    }
  };


  useEffect(() => {
    handleRepeaterChange(fieldArray);
  }, [fieldArray]);

  return (
    <div>
      {
        fieldArray.map((p, index) => (
          <div className="form-group" key={p.id}>
            <div className="row">
              <div className="col-md-5">
                <label>{dataKey}</label>
                <input
                  {...input}
                  type={type}
                  value={p.keyName}
                  className={`${className}`}
                  placeholder={`Enter ${dataKey}`}
                  onChange={(e) => handlekeyChange(p, e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label>
                  {' '}
                  {dataField}
                </label>
                <input
                  {...input}
                  type={type}
                  value={p.value}
                  className={`${className}`}
                  placeholder={`Enter ${dataField}`}
                  onChange={(e) => handleValueChange(p, e.target.value)}
                />
              </div>
              <div className="col-md-1">
                {
                  index === 0 ? (
                    <div
                      className="row mt-5"
                      onKeyPress={incFieldCount}
                      role="link"
                      tabIndex="0"
                      onClick={incFieldCount}
                    >
                      <i className="fas fa-plus-circle fa-lg" style={{ cursor: 'pointer' }} />
                    </div>
                  ) : (
                    <div
                      className="row mt-5"
                      onKeyPress={() => decFieldCount(p.id)}
                      role="link"
                      tabIndex="0"
                      onClick={() => decFieldCount(p.id)}
                    >
                      <i className="fas fa-times-circle fa-lg" style={{ cursor: 'pointer' }} />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        ))
      }
      {touched && error && <span>{error}</span>}
    </div>
  );
};
RepeaterTwoFields.defaultProps = {
  className: null,
  type: null,
  fieldArray: []
};

RepeaterTwoFields.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  dataKey: PropTypes.any.isRequired,
  dataField: PropTypes.any.isRequired,
  handleRepeaterChange: PropTypes.func.isRequired,
  setValue: PropTypes.array.isRequired,
  fieldArray: PropTypes.array
};
