import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as actions from '../../redux/actions/actions-emailTemplate';
import Button from '../../components/Button';
import SearchBar from '../../components/SearchBar';
import BreadCrumb from '../../components/Breadcrumb';
import Loader from '../../components/Loader';

class EmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: ''
    };
    props.getEmailTemplatelist();
  }


  deleteEmailTemplate = (id) => {
    confirmAlert({
      title: 'Delete EmailTemplate',
      message: 'Are you sure to delete the EmailTemplate?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.deleteEmailTemplate(id)
              .then(() => {
                toast.success('Deleted Successfully');
              }).catch((error) => {
                toast.error(error.message);
              });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleTableFilter = (search) => {
    this.setState({ searchVal: search });
  }

  setTableOption = () => (this.props.emailTemplateList && this.props.tableLoading ? <Loader /> : 'No Data Found');

  render() {
    const searchVal = this.state.searchVal.toLowerCase();
    let emailTemplate = this.props.emailTemplateList.filter((item) => item.templateName.toLowerCase().includes(searchVal));
    emailTemplate = emailTemplate.map((item, index) => ({ ...item, Id: index + 1 }));
    const columns = [{
      dataField: 'templateName',
      text: 'Template Name'
    }, {
      dataField: 'textPart',
      text: 'Text Part'
    }, {
      dataField: 'CreatedDateTime',
      text: 'Created at',
      formatter: (cell, row) => (
        new Date(cell).toLocaleDateString()
      ),
      sort: true
    },
    {
      dataField: 'Id',
      text: 'Actions',
      headerStyle: {
        width: '140px'
      },
      formatter: (cell, row) => (
        <>
          <span
            role="presentation"
            className="fa fa-pencil-alt tableEdit"
            onClick={() => this.props.history.push(`/email-template-editor/${row.templateName}`)}
          />
          <span
            role="presentation"
            className="fa fa-trash tableDelete"
            onClick={() => this.deleteEmailTemplate(row.templateName)}
          />
        </>
      )
    }
    ];

    const defaultSorted = [{
      dataField: 'CreatedDateTime',
      order: 'desc'
    }];

    return (
      <div>
        <div className="container">
          <BreadCrumb currentPage="EmailTemplate" />
          <div className="col-md-12 py-3 mb-5">
            <div className="card border-secondary">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    {/* <span className="fa fa-file-alt cardTitle"> EmailTemplate</span> */}
                    <SearchBar
                      placeholder="Search by Template Name"
                      handleChange={(e) => this.handleTableFilter(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2">
                    <Button
                      className="btn btn-sm btn-secondary ml-3"
                      onClick={() => this.props.history.push({
                        pathname: '/email-template-editor/0',
                        state: { currentMenu: 'email-template' }
                      })}
                    >
                      <span className="fas fa-plus" />
                      {' '}
                      Add New
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body text-secondary">

                <BootstrapTable
                  keyField="Id"
                  data={emailTemplate}
                  columns={columns}
                  pagination={paginationFactory()}
                  defaultSorted={defaultSorted}
                  noDataIndication={() => this.setTableOption()}
                  hover
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EmailTemplate.defaultProps = {
  tableLoading: false,
  emailTemplateList: null,
  getEmailTemplatelist: null,
  deleteEmailTemplate: null
};

EmailTemplate.propTypes = {
  tableLoading: PropTypes.bool,
  emailTemplateList: PropTypes.array,
  getEmailTemplatelist: PropTypes.func,
  deleteEmailTemplate: PropTypes.func
};

const mapStateToProps = ({ emailTemplate }) => ({
  tableLoading: emailTemplate.tableLoading,
  emailTemplateList: emailTemplate.emailTemplateList
});

const mapDispatchToProps = (dispatch) => ({
  getEmailTemplatelist: () => dispatch(actions.fetchEmailTemplate()),
  deleteEmailTemplate: (id) => dispatch(actions.deleteEmailTemplate(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate);
