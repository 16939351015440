import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../redux/actions/actions-login';
import * as Cookies from '../lib/Cookie';
import AppRoutes from './AppRoutes';
import AuthRoutes from './AuthRoutes';

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (Cookies.get('cadmin_token')) {
      props.validateToken();
    }
  }

  render() {
    return (
      <div>
        { this.props.token ? <AppRoutes /> : <AuthRoutes /> }
      </div>
    );
  }
}

Routes.defaultProps = {
  token: ''
};

Routes.propTypes = {
  token: PropTypes.string,
  validateToken: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  token: auth.token
});

const mapDispatchToProps = (dispatch) => ({
  validateToken: () => dispatch(actions.validateToken())
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
