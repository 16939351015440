import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as actions from '../../redux/actions/actions-courses';
import Button from '../../components/Button';
import SearchBar from '../../components/SearchBar';
import BreadCrumb from '../../components/Breadcrumb';
import Loader from '../../components/Loader';

class Courses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: ''
    };
    props.getCourseslist();
  }

  deleteCourses = (id) => {
    confirmAlert({
      title: 'Delete Courses',
      message: 'Are you sure to delete the courses?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.deleteCourses(id)
              .then(() => {
                toast.success('Deleted Successfully');
              }).catch((error) => {
                toast.error(error.message);
              });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleTableFilter = (search) => {
    this.setState({ searchVal: search });
  }

  setTableOption = () => (this.props.coursesData && this.props.tableLoading ? <Loader /> : 'No Data Found');

  render() {
    const searchVal = this.state.searchVal.toLowerCase();
    const courses = this.props.coursesData.filter((item) => item.Name.toLowerCase().includes(searchVal) || item.Type.toLowerCase().includes(searchVal));

    const columns = [{
      dataField: 'Name',
      text: 'Course Name'
    }, {
      dataField: 'Duration',
      text: 'Duration'
    }, {
      dataField: 'SlugURL',
      text: 'Slug URL'
    }, {
      dataField: 'Type',
      text: 'Type',
      formatter: (cell, row) => (cell || '--')
    }, {
      dataField: 'CreatedDateTime',
      text: 'Created at',
      formatter: (cell, row) => (
        new Date(cell).toLocaleDateString()
      ),
      sort: true
    }, {
      dataField: 'Id',
      text: 'Actions',
      headerStyle: {
        width: '160px'
      },
      formatter: (cell, row) => (
        <>
          <span
            role="presentation"
            className="fa fa-pencil-alt tableEdit"
            onClick={() => this.props.history.push(`/courses-editor/${cell}`)}
          />
          <span
            role="presentation"
            className="fa fa-trash tableDelete"
            onClick={() => this.deleteCourses(cell)}
          />
          <span
            role="presentation"
            className="fa fa-search-plus tableLM"
            onClick={() => this.props.history.push(`/courses/learning-module/${cell}`)}
          />
        </>
      )
    }];

    const defaultSorted = [{
      dataField: 'CreatedDateTime',
      order: 'desc'
    }];

    return (
      <div>
        <div className="container">
          <BreadCrumb currentPage="Courses" />
          <div className="col-md-12 py-3 mb-5">
            <div className="card border-secondary">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    {/* <span className="fa fa-graduation-cap cardTitle"> Courses</span> */}
                    <SearchBar
                      placeholder="Search by Course Name, Type"
                      handleChange={(e) => this.handleTableFilter(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2 text-right">
                    <Button
                      className="btn btn-sm btn-secondary"
                      onClick={() => this.props.history.push('/courses-editor/0')}
                    >
                      <span className="fas fa-plus" />
                      {' '}
                      Add New
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body text-secondary">

                <BootstrapTable
                  keyField="Id"
                  data={courses}
                  columns={columns}
                  pagination={paginationFactory()}
                  defaultSorted={defaultSorted}
                  noDataIndication={() => this.setTableOption()}
                  hover
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Courses.defaultProps = {
  tableLoading: false,
  coursesData: null,
  getCourseslist: null,
  deleteCourses: null
};

Courses.propTypes = {
  tableLoading: PropTypes.bool,
  coursesData: PropTypes.array,
  getCourseslist: PropTypes.func,
  deleteCourses: PropTypes.func
};

const mapStateToProps = ({ courses }) => ({
  tableLoading: courses.tableLoading,
  coursesData: courses.courseList
});

const mapDispatchToProps = (dispatch) => ({

  getCourseslist: () => dispatch(actions.fetchCourses()),
  deleteCourses: (id) => dispatch(actions.deleteCourses(id))

});

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
