import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../Button';
import {
  InputField, SelectField
} from '../ReduxFormComponents';
import { LeadValidation } from '../../middlewares/formValidator';


const LeadsForm = (props) => (
  <form onSubmit={props.handleSubmit}>
    <Field name="Id" component={InputField} type="hidden" className="form-control" />
    <div className="row form-group">
      <div className="col-md-4">
        <label className="text-left">Lead Name</label>
        <div>
          <Field name="Name" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-4">
        <label className="text-left">Email ID</label>
        <div>
          <Field name="EmailID" component={InputField} type="email" className="form-control" />
        </div>
      </div>
      <div className="col-md-4">
        <label className="text-left">Mobile Number</label>
        <div>
          <Field name="MobileNumber" component={InputField} type="number" className="form-control" />
        </div>
      </div>
    </div>
    <div className="row form-group">
      <div className="col-md-6">
        <label className="text-left">Country</label>
        <div>
          <Field name="Country" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <label className="text-left">Source (default : CRM)</label>
        <div>
          <Field name="Source" component={InputField} type="text" className="form-control" />
        </div>
      </div>
    </div>
    <div className="row form-group">
      <div className="col-md-6">
        <label className="text-left">Medium</label>
        <div>
          <Field name="Medium" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <label className="text-left">Campaign</label>
        <div>
          <Field name="Campaign" component={InputField} type="text" className="form-control" />
        </div>
      </div>
    </div>
    <div className="row form-group mt-2">
      <div className="col-md-6">
        <label className="text-left">Page URL</label>
        <div>
          <Field name="PageURL" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <label className="text-left">Course (Note : Course1~course2)</label>
        <div>
          <Field name="Course" component={InputField} type="text" className="form-control" />
        </div>
      </div>
    </div>
    <div className="row form-group">
      <div className="col-md-6">
        <label className="text-left">Reference Type</label>
        <div>
          <Field name="ReferenceType" id="ReferenceType" component={SelectField} className="form-control">
            <option value="">Select Type</option>
            <option key="Course" value="Course">Course</option>
            <option key="Guide" value="Guide">Guide</option>
            <option key="Webinar" value="Webinar">Webinar</option>
            <option key="Contest" value="Contest">Contest</option>
          </Field>
        </div>
      </div>
      <div className="col-md-6">
        <label className="text-left">Reference Id</label>
        <div>
          <Field name="ReferenceId" component={InputField} type="text" className="form-control" />
        </div>
      </div>
    </div>
    <div className="py-2 mt-2">
      <Button
        type="submit"
        className="btn btn-sm btn-success"
        loading={props.formLoading}
        loadingText="Saving..."
        disabled={props.pristine}
      >
        <i className="fas fa-save mr-1" />
        Save
      </Button>
      <Button
        onClick={props.reset}
        className="btn btn-sm btn-outline-secondary ml-2"
        disabled={props.pristine}
      >
        <i className="fas fa-redo mr-1" />
        Reset
      </Button>
    </div>
  </form>
);

LeadsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  pristine: PropTypes.bool.isRequired
};

LeadsForm.defaultProps = {
  formLoading: null,
  formError: null
};

const leadsForm = reduxForm({
  form: 'Leads Form',
  initialValues: {},
  validate: LeadValidation,
  enableReinitialize: true
})(LeadsForm);

const mapStateToProps = ({ leads }) => ({
  initialValues: {
    ...leads.form,
    Course: leads.form.Course ? leads.form.Course.join('~') : ''
  }
});

export default connect(mapStateToProps)(leadsForm);
