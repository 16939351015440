import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Button from '../../components/Button';
import SearchBar from '../../components/SearchBar';
import BreadCrumb from '../../components/Breadcrumb';
import * as actions from '../../redux/actions/actions-adminRole';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import AdminRoleForm from '../../components/AdminRoles/AddEditForm';


const AdminRoles = ({
  adminRoleList, tableLoading, getAdminRoles, saveAdminRoles,
  formLoading, formError, editAdminRoles, toggleAdminRoleModal, showCrudModal, deleteAdminRoles, history
}) => {
  const [searchVal, setSearchval] = useState('');

  useEffect(() => {
    getAdminRoles();
  }, []);

  const setTableOption = () => (adminRoleList && tableLoading ? <Loader /> : 'No Data Found');

  const searchvalue = searchVal && searchVal.toLowerCase();
  const adminRoleData = adminRoleList.filter((item) => (item.Title && item.Title.toLowerCase().includes(searchvalue)));

  const handleTableFilter = (search) => {
    setSearchval(search);
  };

  const deleteRole = (id) => {
    confirmAlert({
      title: 'Delete AdminRole',
      message: 'Are you sure to delete the AdminRole?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteAdminRoles(id)
              .then(() => {
                toast.success('Deleted Successfully');
              }).catch((error) => {
                toast.error(error.message);
              });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const columns = [{
    dataField: 'Title',
    text: 'Admin Name'
  },
  {
    dataField: 'IsSuperUser',
    text: 'Super Admin',
    formatter: (cell) => (cell ? 'Yes' : 'No')
  },
  {
    dataField: 'CreatedDateTime',
    text: 'Created at',
    formatter: (cell) => (
      new Date(cell).toLocaleDateString()
    ),
    sort: true
  },
  {
    dataField: 'Id',
    text: 'Actions',
    headerStyle: {
      width: '160px'
    },
    formatter: (cell) => (
      <>
        <span
          role="presentation"
          className="fa fa-pencil-alt tableEdit"
          onClick={() => editAdminRoles(cell)}
        />
        <span
          role="presentation"
          className="fa fa-trash tableDelete"
          onClick={() => deleteRole(cell)}
        />
        <span
          role="presentation"
          className="fa fa-sitemap tableLM"
          onClick={() => history.push(`/adminrole/${cell}`)}
        />
      </>
    )
  }];

  const defaultSorted = [{
    dataField: 'CreatedDateTime',
    order: 'desc'
  }];


  return (
    <div>
      <div className="container">
        <BreadCrumb currentPage="Admin Roles" />
        <div className="col-md-12 py-3 mb-5">
          <div className="card border-secondary">
            <div className="card-header">
              <div className="row">
                <div className="col-md-10">
                  <SearchBar
                    placeholder="Search by Title"
                    handleChange={(e) => handleTableFilter(e.target.value)}
                  />
                </div>
                <div className="col-md-2">
                  <Button
                    className="btn btn-sm btn-secondary ml-3"
                    onClick={() => toggleAdminRoleModal(true)}
                  >
                    <span className="fas fa-plus" />
                    {' '}
                    Add New
                  </Button>
                </div>
              </div>
            </div>
            <div className="card-body text-secondary">
              <Modal
                show={showCrudModal}
                title="Admin Roles"
                onClose={() => toggleAdminRoleModal(false)}
              >
                <AdminRoleForm
                  onSubmit={saveAdminRoles}
                  loading={formLoading}
                  error={formError}
                />
              </Modal>
              <BootstrapTable
                keyField="Id"
                data={adminRoleData}
                columns={columns}
                pagination={paginationFactory()}
                defaultSorted={defaultSorted}
                noDataIndication={() => setTableOption()}
                hover
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


AdminRoles.defaultProps = {
  tableLoading: false,
  adminRoleList: [],
  getAdminRoles: null
};

AdminRoles.propTypes = {
  tableLoading: PropTypes.bool,
  adminRoleList: PropTypes.array,
  getAdminRoles: PropTypes.func,
  deleteAdminRoles: PropTypes.func.isRequired,
  saveAdminRoles: PropTypes.func.isRequired,
  editAdminRoles: PropTypes.func.isRequired,
  formLoading: PropTypes.bool.isRequired,
  formError: PropTypes.bool.isRequired,
  toggleAdminRoleModal: PropTypes.func.isRequired,
  showCrudModal: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = ({ adminRoles }) => ({
  adminRoleList: adminRoles.adminRoleList,
  tableLoading: adminRoles.tableLoading,
  formLoading: adminRoles.formLoading,
  formError: adminRoles.formError,
  showCrudModal: adminRoles.showCrudModal
});

const mapDispatchToProps = (dispatch) => ({
  getAdminRoles: () => dispatch(actions.fetchAdminRoles()),
  saveAdminRoles: (formData) => dispatch(actions.saveAdminRoles(formData)),
  editAdminRoles: (id) => dispatch(actions.editAdminRoles(id)),
  toggleAdminRoleModal: (show) => dispatch(actions.toggleAdminRoleModal(show)),
  deleteAdminRoles: (id) => dispatch(actions.deleteAdminRoles(id))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminRoles));
