import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import blog from './reducer-blog';
import courses from './reducer-courses';
import webinar from './reducer-webinar';
import contest from './reducer-contest';
import guide from './reducer-guide';
import coursepage from './reducer-coursepage';
import page from './reducer-page';
import leads from './reducer-leads';
import learningModule from './reducer-leaningmodule';
import learningObject from './reducer-leaningobject';
import branch from './reducer-branch';
import batch from './reducer-batch';
import testimonial from './reducer-testimonial';
import emailTemplate from './reducer-emailTemplate';
import transaction from './reducer-transaction';
import installment from './reducer-installment';
import users from './reducer-users';
import admins from './reducer-admins';
import auth from './reducer-login';
import adminRoles from './reducer-adminRoles';


const rootReducers = combineReducers({
  form: formReducer,
  blog,
  courses,
  webinar,
  contest,
  guide,
  coursepage,
  page,
  leads,
  auth,
  learningModule,
  learningObject,
  branch,
  batch,
  emailTemplate,
  users,
  admins,
  transaction,
  installment,
  testimonial,
  adminRoles
});


export default rootReducers;
