import { emailTemplateActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utils';

const initialFormData = {
  Id: '0',
  templateName: '',
  subjectPart: '',
  textPart: '',
  isDeletable: false,
  htmlPart: ''
};

const defaultState = {
  emailTemplateList: [],
  tableLoading: false,
  form: initialFormData,
  formLoading: false,
  formError: null,
  deleteId: null
};


export default (state = defaultState, actions) => {
  switch (actions.type) {
    case emailTemplateActions.EMAIL_TEMPLATE_FETCH_START:
      return updateState(state, { tableLoading: true });
    case emailTemplateActions.EMAIL_TEMPLATE_FETCH_SUCCESS:
      return updateState(state, { tableLoading: false, emailTemplateList: actions.payload });
    case emailTemplateActions.EMAIL_TEMPLATE_FETCH_ERROR:
      return updateState(state, { tableLoading: false, ...actions.payload });
    case emailTemplateActions.EMAIL_TEMPLATE_SAVE_START:
      return updateState(state, { formLoading: true, formError: null, form: initialFormData });
    case emailTemplateActions.EMAIL_TEMPLATE_SAVE_SUCCESS:
      return updateState(state, { formLoading: false, form: initialFormData });
    case emailTemplateActions.EMAIL_TEMPLATE_SAVE_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    case emailTemplateActions.EMAIL_TEMPLATE_SET_FORM:
      return updateState(state, { ...actions.payload, formError: null });
    case emailTemplateActions.EMAIL_TEMPLATE_RESET_FORM:
      return updateState(state, { form: initialFormData });
    default:
      return state;
  }
};
