import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import TestimonialForm from '../../components/Testimonial/TestimonialForm';
import BreadCrumb from '../../components/Breadcrumb';
import * as actions from '../../redux/actions/actions-testimonial';

class addTestimonial extends Component {
  constructor(props) {
    super(props);

    if (props.match.params.id !== '0') {
      props.setForm(props.match.params.id);
    } else {
      props.resetForm();
    }
    this.state = {};
  }


  handleSubmit = (FormData) => {
    this.props.saveTestimonial(FormData);
  }


  render() {
    return (
      <>
        <div className="container">
          <BreadCrumb currentPage="Testimonial" />
          <div className="col-md-12 py-3 mb-5">
            <div className="card border-secondary">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    { this.props.match.params.id !== '0'
                      ? <span className="fa fa-pencil-alt cardTitle"> Edit Testimonial</span>
                      : <span className="fa fa-plus-square cardTitle"> Create New Testimonial</span>}
                  </div>
                  <div className="col-md-2 text-right">
                    <Button
                      className="btn btn-sm btn-secondary"
                      onClick={() => this.props.history.goBack()}
                    >
                      <span className="fa fa-arrow-left" />
                      {' '}
                      Back
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body text-secondary">
                {/* <h3>fnkbfgjbfgb</h3> */}
                <TestimonialForm
                  onSubmit={this.handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

addTestimonial.propTypes = {
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  saveTestimonial: PropTypes.func
};

addTestimonial.defaultProps = {
  formLoading: null,
  formError: null,
  saveTestimonial: null
};

const mapStateToProps = ({ testimonial }) => ({});


const mapDispatchToProps = (dispatch) => ({
  saveTestimonial: (data) => dispatch(actions.saveTestimonial(data)),
  setForm: (id) => dispatch(actions.editTestimonial(id)),
  resetForm: () => dispatch(actions.resetForm())
});

export default connect(mapStateToProps, mapDispatchToProps)(addTestimonial);
