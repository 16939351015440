import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { toast, Slide } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './redux';

import Routes from './routes';

import 'bootstrap/dist/css/bootstrap.css';
import './styles/App.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

const store = configureStore();

toast.configure({
  autoClose: 2000,
  hideProgressBar: true,
  pauseOnHover: false,
  transition: Slide,
  toastClassName: 'toast-container',
  bodyClassName: 'toast-body',
});

const renderApp = (App) => ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

renderApp(Routes);

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./routes', () => {
      const NextApp = require('./routes').default;
      renderApp(NextApp);
    });
  }
}
