import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as actions from '../../redux/actions/actions-branch';
import Button from '../../components/Button';
import SearchBar from '../../components/SearchBar';
import BreadCrumb from '../../components/Breadcrumb';
import Loader from '../../components/Loader';

class Branch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: ''
    };
    props.getBranchlist();
  }


  deleteBranch = (id) => {
    confirmAlert({
      title: 'Delete Branch',
      message: 'Are you sure to delete the Branch?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.deleteBranch(id)
              .then(() => {
                toast.success('Deleted Successfully');
              }).catch((error) => {
                toast.error(error.message);
              });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleTableFilter = (search) => {
    this.setState({ searchVal: search });
  }

  setTableOption = () => (this.props.branchList && this.props.tableLoading ? <Loader /> : 'No Data Found');

  render() {
    const searchVal = this.state.searchVal.toLowerCase();
    const branchData = this.props.branchList.filter((item) => item.Name.toLowerCase().includes(searchVal) || item.State.toLowerCase().includes(searchVal));

    const columns = [{
      dataField: 'Name',
      text: 'Branch Name'
    }, {
      dataField: 'Code',
      text: 'Branch Code',
      formatter: (cell) => (cell || '--')
    }, {
      dataField: 'State',
      text: 'State / Province'
    }, {
      dataField: 'Country',
      text: 'Country'
    }, {
      dataField: 'CreatedDateTime',
      text: 'Created at',
      formatter: (cell, row) => (
        new Date(cell).toLocaleDateString()
      ),
      sort: true
    }, {
      dataField: 'Id',
      text: 'Actions',
      headerStyle: {
        width: '140px'
      },
      formatter: (cell, row) => (
        <>
          <span
            role="presentation"
            className="fa fa-pencil-alt tableEdit"
            onClick={() => this.props.history.push(`/branch-editor/${cell}`)}
          />
          <span
            role="presentation"
            className="fa fa-trash tableDelete"
            onClick={() => this.deleteBranch(cell)}
          />
        </>
      )
    }];

    const defaultSorted = [{
      dataField: 'CreatedDateTime',
      order: 'desc'
    }];

    return (
      <div>
        <div className="container">
          <BreadCrumb currentPage="Branch" />
          <div className="col-md-12 py-3 mb-5">
            <div className="card border-secondary">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    {/* <span className="fa fa-cubes cardTitle"> Branch</span> */}
                    <SearchBar
                      placeholder="Search by Branch Name, State"
                      handleChange={(e) => this.handleTableFilter(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2 text-right">
                    <Button
                      className="btn btn-sm btn-secondary"
                      onClick={() => this.props.history.push('/branch-editor/0')}
                    >
                      <span className="fas fa-plus" />
                      {' '}
                      Add New
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body text-secondary">

                <BootstrapTable
                  keyField="Id"
                  data={branchData}
                  columns={columns}
                  pagination={paginationFactory()}
                  defaultSorted={defaultSorted}
                  noDataIndication={() => this.setTableOption()}
                  hover
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Branch.defaultProps = {
  tableLoading: false,
  branchList: null,
  getBranchlist: null,
  deleteBranch: null
};

Branch.propTypes = {
  tableLoading: PropTypes.bool,
  branchList: PropTypes.array,
  getBranchlist: PropTypes.func,
  deleteBranch: PropTypes.func
};

const mapStateToProps = ({ branch }) => ({
  tableLoading: branch.tableLoading,
  branchList: branch.branchList
});

const mapDispatchToProps = (dispatch) => ({

  getBranchlist: () => dispatch(actions.fetchBranch()),
  deleteBranch: (id) => dispatch(actions.deleteBranch(id))

});

export default connect(mapStateToProps, mapDispatchToProps)(Branch);
