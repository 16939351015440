import {
  validateURL, validateMobileNo, validateEmail
} from './regexValidator';


export const LeadValidation = (values) => {
  const errors = {};

  if (!values.Name) {
    errors.Name = 'Please Enter Lead Name';
  }
  if (!validateEmail(values.EmailID)) {
    errors.EmailID = 'Please enter valid Email Id';
  }

  if (!validateMobileNo(values.mobileNumber)) {
    errors.MobileNumber = 'Please enter 10 digit mobile Number';
  }
  if (!values.Source) {
    errors.Source = 'Please Enter Source';
  }

  return errors;
};

export const BlogValidation = (values) => {
  const errors = {};

  if (!values.Title) {
    errors.Title = 'Please Enter Title';
  }
  if (!values.Category) {
    errors.Category = 'Please Enter Category';
  }
  if (!values.SlugURL) {
    errors.SlugURL = 'Please Enter Slug URL';
  }

  return errors;
};

export const CourseValidation = (values) => {
  const errors = {};

  if (!values.Name) {
    errors.Name = 'Please Enter Course Name';
  }
  if (!values.Type) {
    errors.Type = 'Please Enter Type';
  }
  if (!values.SlugURL) {
    errors.SlugURL = 'Please Enter Slug URL';
  }
  if (!values.OrderNumber) {
    errors.OrderNumber = 'Please Enter Order Number or default value is 0';
  }

  return errors;
};

export const WebinarValidation = (values) => {
  const errors = {};

  if (!values.Title) {
    errors.Title = 'Please Enter Webinar Name';
  }
  if (!values.AuthorName) {
    errors.AuthorName = 'Please Enter Author Name';
  }
  if (!values.SlugURL) {
    errors.SlugURL = 'Please Enter Slug URL';
  }
  if (!values.OrderNumber) {
    errors.OrderNumber = 'Please Enter Order Number or default value is 0';
  }

  return errors;
};

export const ContestValidation = (values) => {
  const errors = {};

  if (!values.Title) {
    errors.Title = 'Please Enter Contest Name';
  }
  if (!values.SlugURL) {
    errors.SlugURL = 'Please Enter Slug URL';
  }
  if (!values.OrderNumber) {
    errors.OrderNumber = 'Please Enter Order Number or default value is 0';
  }

  return errors;
};

export const GuideValidation = (values) => {
  const errors = {};

  if (!values.Title) {
    errors.Title = 'Please Enter Guide Name';
  }
  if (!values.DownloadURL) {
    errors.DownloadURL = 'Please Enter Download URL';
  } else if (values.DownloadURL && !validateURL(values.DownloadURL)) {
    errors.DownloadURL = 'Invalid Url';
  }

  if (!values.SlugURL) {
    errors.SlugURL = 'Please Enter Slug URL';
  }
  if (!values.OrderNumber) {
    errors.OrderNumber = 'Please Enter Order Number or default value is 0';
  }
  return errors;
};

export const TestimonialValidation = (values) => {
  const errors = {};

  if (!values.StudentName) {
    errors.StudentName = 'Please Enter Student Name';
  }
  if (!values.StudentPicURL) {
    errors.StudentPicURL = 'Please Enter Student Profile URL';
  }
  if (!values.Content) {
    errors.Content = 'Please Enter Content';
  }
  return errors;
};

export const PageValidation = (values) => {
  const errors = {};

  if (!values.Title) {
    errors.Title = 'Please Enter Page Name';
  }
  if (!values.SlugURL) {
    errors.SlugURL = 'Please Enter Slug URL';
  }
  if (!values.Content) {
    errors.Content = 'Please Enter Content';
  }
  return errors;
};

export const EmailTemplateValidation = (values) => {
  const errors = {};

  if (!values.templateName) {
    errors.templateName = 'Please Enter Template Name';
  }
  if (!values.subjectPart) {
    errors.subjectPart = 'Please Enter subject';
  }
  if (!values.textPart) {
    errors.textPart = 'Please Enter Text Part';
  }
  if (!values.htmlPart) {
    errors.htmlPart = 'Please Enter HTML Part';
  }
  return errors;
};


export const UsersValidation = (values) => {
  const errors = {};

  if (!values.StudentName) {
    errors.StudentName = 'Please enter student name';
  }
  if (!validateEmail(values.EmailId)) {
    errors.EmailId = 'Please enter valid email Id';
  }

  if (!validateMobileNo(values.MobileNumber)) {
    errors.MobileNumber = 'Please enter 10 digit mobile number';
  }

  if (!values.LoginId) {
    errors.LoginId = 'Please Enter login Id';
  } else if (values.EmailId !== values.LoginId) {
    errors.LoginId = 'Please enter login Id same as email Id';
  }

  if (!values.Password) {
    errors.Password = 'Please enter password';
  }
  return errors;
};

export const AdminsValidation = (values) => {
  const errors = {};

  if (!values.AdminName) {
    errors.AdminName = 'Please enter admin name';
  }
  if (!validateEmail(values.EmailId)) {
    errors.EmailId = 'Please enter valid email Id';
  }

  if (!validateMobileNo(values.MobileNumber)) {
    errors.MobileNumber = 'Please enter 10 digit mobile number';
  }

  if (!values.LoginId) {
    errors.LoginId = 'Please Enter login Id';
  }
  // else if (values.EmailId !== values.LoginId) {
  //   errors.LoginId = 'Please enter login Id same as email Id';
  // }

  if (!values.Password) {
    errors.Password = 'Please enter password';
  }
  return errors;
};

export const StatusValidation = (values) => {
  const errors = {};

  if (!values.status) {
    errors.status = 'Please Select Status';
  }

  if (!values.assignedTo) {
    errors.assignedTo = 'Please Select Assigned To';
  }

  if (!values.remarks) {
    errors.remarks = 'Please Enter Remarks';
  }
  return errors;
};

export const AdminRoleValidation = (values) => {
  const errors = {};

  if (!values.Title) {
    errors.Title = 'Please Enter Title';
  }
  return errors;
};

export const validateAdminRoleMappingform = (values) => {
  const errors = {};

  if(!values.OrderNumber) {
    errors.OrderNumber ="Please Enter Order Number";
  }
  if(!values.MenuId){
    errors.MenuId = "Please Select the Menu";
  }
  return errors;
}
