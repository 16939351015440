const BLOG_FETCH_START = 'BLOG_FETCH_START';
const BLOG_FETCH_SUCCESS = 'BLOG_FETCH_SUCCESS';
const BLOG_FETCH_FAILED = 'BLOG_FETCH_FAILED';
const BLOG_SAVE_START = 'BLOG_SAVE_START';
const BLOG_SAVE_SUCCESS = 'BLOG_SAVE_SUCCESS';
const BLOG_SAVE_FAILED = 'BLOG_SAVE_FAILED';
const BLOG_SET_FORM = 'BLOG_SET_FORM';
const BLOG_RESET_FORM = 'BLOG_RESET_FORM';

export const blogActions = {
  BLOG_FETCH_START,
  BLOG_FETCH_SUCCESS,
  BLOG_FETCH_FAILED,
  BLOG_SAVE_START,
  BLOG_SAVE_SUCCESS,
  BLOG_SAVE_FAILED,
  BLOG_SET_FORM,
  BLOG_RESET_FORM
};


const COURSES_FETCH_START = 'COURSES_FETCH_START';
const COURSES_FETCH_SUCCESS = 'COURSES_FETCH_SUCCESS';
const COURSES_FETCH_FAILED = 'COURSES_FETCH_FAILED';
const COURSES_SAVE_START = 'COURSES_SAVE_START';
const COURSES_SAVE_SUCCESS = 'COURSES_SAVE_SUCCESS';
const COURSES_SAVE_FAILED = 'COURSES_SAVE_FAILED';
const COURSES_SET_FORM = 'COURSES_SET_FORM';
const COURSES_RESET_FORM = 'COURSES_RESET_FORM';

export const courseActions = {
  COURSES_FETCH_START,
  COURSES_FETCH_SUCCESS,
  COURSES_FETCH_FAILED,
  COURSES_SAVE_START,
  COURSES_SAVE_SUCCESS,
  COURSES_SAVE_FAILED,
  COURSES_SET_FORM,
  COURSES_RESET_FORM

};

const WEBINAR_FETCH_START = 'WEBINAR_FETCH_START';
const WEBINAR_FETCH_SUCCESS = 'WEBINAR_FETCH_SUCCESS';
const WEBINAR_FETCH_FAILED = 'WEBINAR_FETCH_FAILED';
const WEBINAR_SAVE_START = 'WEBINAR_SAVE_START';
const WEBINAR_SAVE_SUCCESS = 'WEBINAR_SAVE_SUCCESS';
const WEBINAR_SAVE_FAILED = 'WEBINAR_SAVE_FAILED';
const WEBINAR_SET_FORM = 'WEBINAR_SET_FORM';
const WEBINAR_RESET_FORM = 'WEBINAR_RESET_FORM';

export const webinarActions = {
  WEBINAR_FETCH_START,
  WEBINAR_FETCH_SUCCESS,
  WEBINAR_FETCH_FAILED,
  WEBINAR_SAVE_START,
  WEBINAR_SAVE_SUCCESS,
  WEBINAR_SAVE_FAILED,
  WEBINAR_SET_FORM,
  WEBINAR_RESET_FORM
};


const CONTEST_FETCH_START = 'CONTEST_FETCH_START';
const CONTEST_FETCH_SUCCESS = 'CONTEST_FETCH_SUCCESS';
const CONTEST_FETCH_FAILED = 'CONTEST_FETCH_FAILED';
const CONTEST_SAVE_START = 'CONTEST_SAVE_START';
const CONTEST_SAVE_SUCCESS = 'CONTEST_SAVE_SUCCESS';
const CONTEST_SAVE_FAILED = 'CONTEST_SAVE_FAILED';
const CONTEST_SET_FORM = 'CONTEST_SET_FORM';
const CONTEST_RESET_FORM = 'CONTEST_RESET_FORM';

export const contestActions = {
  CONTEST_FETCH_START,
  CONTEST_FETCH_SUCCESS,
  CONTEST_FETCH_FAILED,
  CONTEST_SAVE_START,
  CONTEST_SAVE_SUCCESS,
  CONTEST_SAVE_FAILED,
  CONTEST_SET_FORM,
  CONTEST_RESET_FORM
};


const GUIDE_FETCH_START = 'GUIDE_FETCH_START';
const GUIDE_FETCH_SUCCESS = 'GUIDE_FETCH_SUCCESS';
const GUIDE_FETCH_FAILED = 'GUIDE_FETCH_FAILED';
const GUIDE_SAVE_START = 'GUIDE_SAVE_START';
const GUIDE_SAVE_SUCCESS = 'GUIDE_SAVE_SUCCESS';
const GUIDE_SAVE_FAILED = 'GUIDE_SAVE_FAILED';
const GUIDE_SET_FORM = 'GUIDE_SET_FORM';
const GUIDE_RESET_FORM = 'GUIDE_RESET_FORM';

export const guideActions = {
  GUIDE_FETCH_START,
  GUIDE_FETCH_SUCCESS,
  GUIDE_FETCH_FAILED,
  GUIDE_SAVE_START,
  GUIDE_SAVE_SUCCESS,
  GUIDE_SAVE_FAILED,
  GUIDE_SET_FORM,
  GUIDE_RESET_FORM
};


const COURSE_PAGE_FETCH_START = 'COURSE_PAGE_FETCH_START';
const COURSE_PAGE_FETCH_SUCCESS = 'COURSE_PAGE_FETCH_SUCCESS';
const COURSE_PAGE_FETCH_FAILED = 'COURSE_PAGE_FETCH_FAILED';
const CP_TESTIMONIAL_FETCH_START = 'CP_TESTIMONIAL_FETCH_START';
const CP_TESTIMONIAL_FETCH_SUCCESS = 'CP_TESTIMONIAL_FETCH_SUCCESS';
const CP_TESTIMONIAL_FETCH_FAILED = 'CP_TESTIMONIAL_FETCH_FAILED';
const CP_BATCH_FETCH_START = 'CP_BATCH_FETCH_START';
const CP_BATCH_FETCH_SUCCESS = 'CP_BATCH_FETCH_SUCCESS';
const CP_BATCH_FETCH_FAILED = 'CP_BATCH_FETCH_FAILED';
const COURSE_PAGE_SAVE_START = 'COURSE_PAGE_SAVE_START';
const COURSE_PAGE_SAVE_SUCCESS = 'COURSE_PAGE_SAVE_SUCCESS';
const COURSE_PAGE_SAVE_FAILED = 'COURSE_PAGE_SAVE_FAILED';
const COURSE_PAGE_SET_FORM = 'COURSE_PAGE_SET_FORM';
const COURSE_PAGE_RESET_FORM = 'COURSE_PAGE_RESET_FORM';

export const coursePageActions = {
  COURSE_PAGE_FETCH_START,
  COURSE_PAGE_FETCH_SUCCESS,
  COURSE_PAGE_FETCH_FAILED,
  CP_TESTIMONIAL_FETCH_START,
  CP_TESTIMONIAL_FETCH_SUCCESS,
  CP_TESTIMONIAL_FETCH_FAILED,
  CP_BATCH_FETCH_START,
  CP_BATCH_FETCH_SUCCESS,
  CP_BATCH_FETCH_FAILED,
  COURSE_PAGE_SAVE_START,
  COURSE_PAGE_SAVE_SUCCESS,
  COURSE_PAGE_SAVE_FAILED,
  COURSE_PAGE_SET_FORM,
  COURSE_PAGE_RESET_FORM
};


const PAGE_FETCH_START = 'PAGE_FETCH_START';
const PAGE_FETCH_SUCCESS = 'PAGE_FETCH_SUCCESS';
const PAGE_FETCH_FAILED = 'PAGE_FETCH_FAILED';
const PAGE_SAVE_START = 'PAGE_SAVE_START';
const PAGE_SAVE_SUCCESS = 'PAGE_SAVE_SUCCESS';
const PAGE_SAVE_FAILED = 'PAGE_SAVE_FAILED';
const PAGE_SET_FORM = 'PAGE_SET_FORM';
const PAGE_RESET_FORM = 'PAGE_RESET_FORM';

export const pageActions = {
  PAGE_FETCH_START,
  PAGE_FETCH_SUCCESS,
  PAGE_FETCH_FAILED,
  PAGE_SAVE_START,
  PAGE_SAVE_SUCCESS,
  PAGE_SAVE_FAILED,
  PAGE_SET_FORM,
  PAGE_RESET_FORM
};


const LEADS_FETCH_START = 'LEADS_FETCH_START';
const LEADS_FETCH_SUCCESS = 'LEADS_FETCH_SUCCESS';
const LEADS_FETCH_FAILED = 'LEADS_FETCH_FAILED';
const LEADS_COURSES_FETCH_START = 'LEADS_COURSES_FETCH_START';
const LEADS_COURSES_FETCH_SUCCESS = 'LEADS_COURSES_FETCH_SUCCESS';
const LEADS_COURSES_FETCH_FAILED = 'LEADS_COURSES_FETCH_FAILED';
const LEADS_ADMINS_FETCH_START = 'LEADS_ADMINS_FETCH_START';
const LEADS_ADMINS_FETCH_SUCCESS = 'LEADS_ADMINS_FETCH_SUCCESS';
const LEADS_ADMINS_FETCH_FAILED = 'LEADS_ADMINS_FETCH_FAILED';
const LEADS_SAVE_START = 'LEADS_SAVE_START';
const LEADS_SAVE_SUCCESS = 'LEADS_SAVE_SUCCESS';
const LEADS_SAVE_FAILED = 'LEADS_SAVE_FAILED';
const LEADS_STATUS_SAVE_START = 'LEADS_STATUS_SAVE_START';
const LEADS_STATUS_SAVE_SUCCESS = 'LEADS_STATUS_SAVE_SUCCESS';
const LEADS_STATUS_SAVE_FAILED = 'LEADS_STATUS_SAVE_FAILED';
const LEADS_SET_FORM = 'LEADS_SET_FORM';
const LEADS_RESET_FORM = 'LEADS_RESET_FORM';

export const leadsActions = {
  LEADS_FETCH_START,
  LEADS_FETCH_SUCCESS,
  LEADS_FETCH_FAILED,
  LEADS_SAVE_START,
  LEADS_SAVE_SUCCESS,
  LEADS_SAVE_FAILED,
  LEADS_COURSES_FETCH_START,
  LEADS_COURSES_FETCH_SUCCESS,
  LEADS_COURSES_FETCH_FAILED,
  LEADS_ADMINS_FETCH_START,
  LEADS_ADMINS_FETCH_SUCCESS,
  LEADS_ADMINS_FETCH_FAILED,
  LEADS_STATUS_SAVE_START,
  LEADS_STATUS_SAVE_SUCCESS,
  LEADS_STATUS_SAVE_FAILED,
  LEADS_SET_FORM,
  LEADS_RESET_FORM
};


const AUTH_LOGIN_START = 'AUTH_LOGIN_START';
const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
const AUTH_LOGIN_FAILED = 'AUTH_LOGIN_FAILED';
const AUTH_VALIDATE_TOKEN = 'AUTH_VALIDATE_TOKEN';
const AUTH_VALIDATE_TOKEN_FAILED = 'AUTH_VALIDATE_TOKEN_FAILED';

export const authActions = {
  AUTH_LOGIN_START,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILED,
  AUTH_VALIDATE_TOKEN,
  AUTH_VALIDATE_TOKEN_FAILED
};


const LM_FETCH_START = 'LM_FETCH_START';
const LM_FETCH_SUCCESS = 'LM_FETCH_SUCCESS';
const LM_FETCH_FAILED = 'LM_FETCH_FAILED';
const LM_SAVE_START = 'LM_SAVE_START';
const LM_SAVE_SUCCESS = 'LM_SAVE_SUCCESS';
const LM_SAVE_FAILED = 'LM_SAVE_FAILED';
const LM_SET_FORM = 'LM_SET_FORM';
const LM_RESET_FORM = 'LM_RESET_FORM';

export const learningModuleActions = {
  LM_FETCH_START,
  LM_FETCH_SUCCESS,
  LM_FETCH_FAILED,
  LM_SAVE_START,
  LM_SAVE_SUCCESS,
  LM_SAVE_FAILED,
  LM_SET_FORM,
  LM_RESET_FORM
};

const LOBJ_FETCH_START = 'LOBJ_FETCH_START';
const LOBJ_FETCH_SUCCESS = 'LOBJ_FETCH_SUCCESS';
const LOBJ_FETCH_FAILED = 'LOBJ_FETCH_FAILED';
const LOBJ_SAVE_START = 'LOBJ_SAVE_START';
const LOBJ_SAVE_SUCCESS = 'LOBJ_SAVE_SUCCESS';
const LOBJ_SAVE_FAILED = 'LOBJ_SAVE_FAILED';
const LOBJ_SET_FORM = 'LOBJ_SET_FORM';
const LOBJ_RESET_FORM = 'LOBJ_RESET_FORM';

export const learningObjectActions = {
  LOBJ_FETCH_START,
  LOBJ_FETCH_SUCCESS,
  LOBJ_FETCH_FAILED,
  LOBJ_SAVE_START,
  LOBJ_SAVE_SUCCESS,
  LOBJ_SAVE_FAILED,
  LOBJ_SET_FORM,
  LOBJ_RESET_FORM
};


const BRANCH_FETCH_START = 'BRANCH_FETCH_START';
const BRANCH_FETCH_SUCCESS = 'BRANCH_FETCH_SUCCESS';
const BRANCH_FETCH_FAILED = 'BRANCH_FETCH_FAILED';
const BRANCH_SAVE_START = 'BRANCH_SAVE_START';
const BRANCH_SAVE_SUCCESS = 'BRANCH_SAVE_SUCCESS';
const BRANCH_SAVE_FAILED = 'BRANCH_SAVE_FAILED';
const BRANCH_SET_FORM = 'BRANCH_SET_FORM';
const BRANCH_RESET_FORM = 'BRANCH_RESET_FORM';

export const branchActions = {
  BRANCH_FETCH_START,
  BRANCH_FETCH_SUCCESS,
  BRANCH_FETCH_FAILED,
  BRANCH_SAVE_START,
  BRANCH_SAVE_SUCCESS,
  BRANCH_SAVE_FAILED,
  BRANCH_SET_FORM,
  BRANCH_RESET_FORM
};


const BATCH_FETCH_START = 'BATCH_FETCH_START';
const BATCH_FETCH_SUCCESS = 'BATCH_FETCH_SUCCESS';
const BATCH_FETCH_FAILED = 'BATCH_FETCH_FAILED';
const BATCH_BRANCH_FETCH_START = 'BATCH_BRANCH_FETCH_START';
const BATCH_BRANCH_FETCH_SUCCESS = 'BATCH_BRANCH_FETCH_SUCCESS';
const BATCH_BRANCH_FETCH_FAILED = 'BATCH_BRANCH_FETCH_FAILED';
const BATCH_COURSES_FETCH_START = 'BATCH_COURSES_FETCH_START';
const BATCH_COURSES_FETCH_SUCCESS = 'BATCH_COURSES_FETCH_SUCCESS';
const BATCH_COURSES_FETCH_FAILED = 'BATCH_COURSES_FETCH_FAILED';
const BATCH_INSTALLMENT_FETCH_START = 'BATCH_INSTALLMENT_FETCH_START';
const BATCH_INSTALLMENT_FETCH_SUCCESS = 'BATCH_INSTALLMENT_FETCH_SUCCESS';
const BATCH_INSTALLMENT_FETCH_FAILED = 'BATCH_INSTALLMENT_FETCH_FAILED';
const BATCH_SAVE_START = 'BATCH_SAVE_START';
const BATCH_SAVE_SUCCESS = 'BATCH_SAVE_SUCCESS';
const BATCH_SAVE_FAILED = 'BATCH_SAVE_FAILED';
const BATCH_SET_FORM = 'BATCH_SET_FORM';
const BATCH_RESET_FORM = 'BATCH_RESET_FORM';

export const batchActions = {
  BATCH_FETCH_START,
  BATCH_FETCH_SUCCESS,
  BATCH_FETCH_FAILED,
  BATCH_BRANCH_FETCH_START,
  BATCH_BRANCH_FETCH_SUCCESS,
  BATCH_BRANCH_FETCH_FAILED,
  BATCH_COURSES_FETCH_START,
  BATCH_COURSES_FETCH_SUCCESS,
  BATCH_COURSES_FETCH_FAILED,
  BATCH_INSTALLMENT_FETCH_START,
  BATCH_INSTALLMENT_FETCH_SUCCESS,
  BATCH_INSTALLMENT_FETCH_FAILED,
  BATCH_SAVE_START,
  BATCH_SAVE_SUCCESS,
  BATCH_SAVE_FAILED,
  BATCH_SET_FORM,
  BATCH_RESET_FORM
};


const TESTIMONIAL_FETCH_START = 'TESTIMONIAL_FETCH_START';
const TESTIMONIAL_FETCH_SUCCESS = 'TESTIMONIAL_FETCH_SUCCESS';
const TESTIMONIAL_FETCH_FAILED = 'TESTIMONIAL_FETCH_FAILED';
const TESTIMONIAL_SAVE_START = 'TESTIMONIAL_SAVE_START';
const TESTIMONIAL_SAVE_SUCCESS = 'TESTIMONIAL_SAVE_SUCCESS';
const TESTIMONIAL_SAVE_FAILED = 'TESTIMONIAL_SAVE_FAILED';
const TESTIMONIAL_SET_FORM = 'TESTIMONIAL_SET_FORM';
const TESTIMONIAL_RESET_FORM = 'TESTIMONIAL_RESET_FORM';

export const testimonialActions = {
  TESTIMONIAL_FETCH_START,
  TESTIMONIAL_FETCH_SUCCESS,
  TESTIMONIAL_FETCH_FAILED,
  TESTIMONIAL_SAVE_START,
  TESTIMONIAL_SAVE_SUCCESS,
  TESTIMONIAL_SAVE_FAILED,
  TESTIMONIAL_SET_FORM,
  TESTIMONIAL_RESET_FORM
};


const EMAIL_TEMPLATE_FETCH_START = 'EMAIL_TEMPLATE_FETCH_START';
const EMAIL_TEMPLATE_FETCH_SUCCESS = 'EMAIL_TEMPLATE_FETCH_SUCCESS';
const EMAIL_TEMPLATE_FETCH_FAILED = 'EMAIL_TEMPLATE_FETCH_FAILED';
const EMAIL_TEMPLATE_SAVE_START = 'EMAIL_TEMPLATE_SAVE_START';
const EMAIL_TEMPLATE_SAVE_SUCCESS = 'EMAIL_TEMPLATE_SAVE_SUCCESS';
const EMAIL_TEMPLATE_SAVE_FAILED = 'EMAIL_TEMPLATE_SAVE_FAILED';
const EMAIL_TEMPLATE_SET_FORM = 'EMAIL_TEMPLATE_SET_FORM';
const EMAIL_TEMPLATE_RESET_FORM = 'EMAIL_TEMPLATE_RESET_FORM';

export const emailTemplateActions = {
  EMAIL_TEMPLATE_FETCH_START,
  EMAIL_TEMPLATE_FETCH_SUCCESS,
  EMAIL_TEMPLATE_FETCH_FAILED,
  EMAIL_TEMPLATE_SAVE_START,
  EMAIL_TEMPLATE_SAVE_SUCCESS,
  EMAIL_TEMPLATE_SAVE_FAILED,
  EMAIL_TEMPLATE_SET_FORM,
  EMAIL_TEMPLATE_RESET_FORM
};

const USERS_FETCH_START = 'USERS_FETCH_START';
const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
const USERS_FETCH_FAILED = 'USERS_FETCH_FAILED';
const USERS_SAVE_START = 'USERS_SAVE_START';
const USERS_SAVE_SUCCESS = 'USERS_SAVE_SUCCESS';
const USERS_SAVE_FAILED = 'USERS_SAVE_FAILED';
const USERS_SET_FORM = 'USERS_SET_FORM';
const USERS_RESET_FORM = 'USERS_RESET_FORM';

export const usersActions = {
  USERS_FETCH_START,
  USERS_FETCH_SUCCESS,
  USERS_FETCH_FAILED,
  USERS_SAVE_START,
  USERS_SAVE_SUCCESS,
  USERS_SAVE_FAILED,
  USERS_SET_FORM,
  USERS_RESET_FORM
};


const TRANSACTION_FETCH_START = 'TRANSACTION_FETCH_START';
const TRANSACTION_FETCH_SUCCESS = 'TRANSACTION_FETCH_SUCCESS';
const TRANSACTION_FETCH_FAILED = 'TRANSACTION_FETCH_FAILED';

export const transActions = {
  TRANSACTION_FETCH_START,
  TRANSACTION_FETCH_SUCCESS,
  TRANSACTION_FETCH_FAILED
};

const INSTALLMENT_FETCH_START = 'INSTALLMENT_FETCH_START';
const INSTALLMENT_FETCH_SUCCESS = 'INSTALLMENT_FETCH_SUCCESS';
const INSTALLMENT_FETCH_FAILED = 'INSTALLMENT_FETCH_FAILED';
const INSTALLMENT_SAVE_START = 'INSTALLMENT_SAVE_START';
const INSTALLMENT_SAVE_SUCCESS = 'INSTALLMENT_SAVE_SUCCESS';
const INSTALLMENT_SAVE_FAILED = 'INSTALLMENT_SAVE_FAILED';
const INSTALLMENT_SET_FORM = 'INSTALLMENT_SET_FORM';
const INSTALLMENT_RESET_FORM = 'INSTALLMENT_RESET_FORM';

export const installmentActions = {
  INSTALLMENT_FETCH_START,
  INSTALLMENT_FETCH_SUCCESS,
  INSTALLMENT_FETCH_FAILED,
  INSTALLMENT_SAVE_START,
  INSTALLMENT_SAVE_SUCCESS,
  INSTALLMENT_SAVE_FAILED,
  INSTALLMENT_SET_FORM,
  INSTALLMENT_RESET_FORM
};


const ADMINS_FETCH_START = 'ADMINS_FETCH_START';
const ADMINS_FETCH_SUCCESS = 'ADMINS_FETCH_SUCCESS';
const ADMINS_FETCH_FAILED = 'ADMINS_FETCH_FAILED';
const ADMINS_SAVE_START = 'ADMINS_SAVE_START';
const ADMINS_SAVE_SUCCESS = 'ADMINS_SAVE_SUCCESS';
const ADMINS_SAVE_FAILED = 'ADMINS_SAVE_FAILED';
const ADMINS_SET_FORM = 'ADMINS_SET_FORM';
const ADMINS_RESET_FORM = 'ADMINS_RESET_FORM';

export const adminsActions = {
  ADMINS_FETCH_START,
  ADMINS_FETCH_SUCCESS,
  ADMINS_FETCH_FAILED,
  ADMINS_SAVE_START,
  ADMINS_SAVE_SUCCESS,
  ADMINS_SAVE_FAILED,
  ADMINS_SET_FORM,
  ADMINS_RESET_FORM
};

const ADMINROLES_FETCH_START = 'ADMINROLES_FETCH_START';
const ADMINROLES_FETCH_SUCCESS = 'ADMINROLES_FETCH_SUCCESS';
const ADMINROLES_FETCH_FAILED = 'ADMINROLES_FETCH_FAILED';
const ADMINROLES_SAVE_START = 'ADMINROLES_SAVE_START';
const ADMINROLES_SAVE_SUCCESS = 'ADMINROLES_SAVE_SUCCESS';
const ADMINROLES_SAVE_FAILED = 'ADMINROLES_SAVE_FAILED';
const ADMINROLES_SET_FORM = 'ADMINROLES_SET_FORM';
const ADMINROLES_TOGGLE_MODAL = 'ADMINROLES_TOGGLE_MODAL';
const ADMINROLE_MENU_FETCH_START = 'ADMINROLE_MENU_FETCH_START';
const ADMINROLE_MENU_FETCH_SUCCESS = 'ADMINROLE_MENU_FETCH_SUCCESS';
const ADMINROLE_MENU_FETCH_FAILED = 'ADMINROLE_MENU_FETCH_FAILED';
const ADMINROLE_MENU_EDIT = 'ADMINROLE_MENU_EDIT';
const ADMINROLE_MAPPING_SAVE_START = 'ADMINROLE_MAPPING_SAVE_START';
const ADMINROLE_MAPPING_SAVE_SUCCESS = 'ADMINROLE_MAPPING_SAVE_SUCCESS';
const ADMINROLE_MAPPING_SAVE_FAILED = 'ADMINROLE_MAPPING_SAVE_FAILED';

export const adminRoleActions = {
  ADMINROLES_FETCH_START,
  ADMINROLES_FETCH_SUCCESS,
  ADMINROLES_FETCH_FAILED,
  ADMINROLES_SAVE_START,
  ADMINROLES_SAVE_SUCCESS,
  ADMINROLES_SAVE_FAILED,
  ADMINROLES_SET_FORM,
  ADMINROLES_TOGGLE_MODAL,
  ADMINROLE_MENU_FETCH_START,
  ADMINROLE_MENU_FETCH_SUCCESS,
  ADMINROLE_MENU_FETCH_FAILED,
  ADMINROLE_MENU_EDIT,
  ADMINROLE_MAPPING_SAVE_START,
  ADMINROLE_MAPPING_SAVE_SUCCESS,
  ADMINROLE_MAPPING_SAVE_FAILED
};
