import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RoleEditor from './RoleEditor';

const RoleAccordian = ({
  menuDetails: {
    Id, MenuName, ParentId
  },
  menuDetails, 
  roleId,
  editId,
  children,
  handleEdit,
  handleDelete
}) => {
  const [expand, setExpand] = useState([]);

  const expandCollapse = (expId) => {
    if (expId) {
      const activeId = expand.slice();
      const index = activeId.findIndex((i) => i === expId);
      if (index !== -1) {
        activeId.splice(index, 1);
      } else {
        activeId.push(expId);
      }
      setExpand(activeId);
    }
  };

  if (editId === Id) {
    return (
      <RoleEditor
        editMode
        roleId={roleId}
        menuDetails={menuDetails}
        cancelEdit={() => handleEdit(null)}
        parentId={ParentId}
      />
    );
  }
  return (
    <>
      <div className="accordion" id={`accordionId-${Id}`}>
        <div className="card">
          <div className="card-header" id="headingOne">
            <div id={`section-collapse-title-${Id}`} className="title" data-toggle="collapse" data-target={`#collapseId-${Id}`} aria-expanded="true" aria-controls={`collapseId-${Id}`}>
              <span>{MenuName}</span>
            </div>
            <div className="actions">
              <i role="presentation" onClick={() => handleEdit(Id)} className="fas fa-pencil-alt" />
              <i role="presentation" onClick={handleDelete} className="fas fa-trash" />
              <i
                className="fas fa-chevron-down"
                data-toggle="collapse"
                data-target={`#collapseId-${Id}`}
                aria-expanded="true"
                aria-controls={`collapseId-${Id}`}
                onClick={() => expandCollapse(`collapseId-${Id}`)}
              />
            </div>
          </div>

          <div id={`collapseId-${Id}`} className={`collapse ${expand && expand.includes(`collapseId-${Id}`) && 'show'}`} aria-labelledby="headingOne" data-parent={`#accordionId-${Id}`}>
            <div className="card-body">
              {children}
              <RoleEditor title={MenuName} parentId={Id} roleId={roleId} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

RoleAccordian.defaultProps = {
  menuDetails: {},
  children: null,
  editId: null
};

RoleAccordian.propTypes = {
  menuDetails: PropTypes.object,
  roleId: PropTypes.string.isRequired,
  children: PropTypes.any,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  editId: PropTypes.string
};

export default RoleAccordian;
