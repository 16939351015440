import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../Button';
import {
  InputField, TinyMCEEditor, MultiSelect, SelectField
} from '../ReduxFormComponents';
import DynamicForm from './dynamicForm';
import DynamicMultiForm from './dynamicMultiForm';

class CoursePageForm extends Component {
  constructor(props) {
    super(props);
    const formData = props.initialValues;
    const featureData = formData.CourseFeatures ? formData.CourseFeatures.map((item) => ({ id: Math.random(), keyName: item.param, value: item.value })) : [{ id: Math.random(), keyName: '', value: '' }];

    const SeoData = formData.SEO ? formData.SEO.map((item) => ({ id: Math.random(), keyName: item.param, value: item.value })) : [{ id: Math.random(), keyName: '', value: '' }];

    const moduleData = formData.CourseContent ? formData.CourseContent.map((item) => ({
      id: Math.random(), keyName: item.Name, value: item.Modules.join('~'), descp: item.Description
    })) : [{
      id: Math.random(), keyName: '', value: '', descp: ''
    }];

    const highlightData = formData.CourseHighlights ? formData.CourseHighlights.map((item) => ({ id: Math.random(), keyName: item.icon, value: item.title })) : [{ id: Math.random(), keyName: '', value: '' }];

    const faqData = formData.FAQ ? formData.FAQ.map((item) => ({ id: Math.random(), keyName: item.Q, value: item.A })) : [{ id: Math.random(), keyName: '', value: '' }];

    const projectsData = formData.Projects ? formData.Projects.map((item) => ({ id: Math.random(), keyName: item.Name, value: item.Description })) : [{ id: Math.random(), keyName: '', value: '' }];

    const toolsData = formData.Tools ? formData.Tools.map((item) => ({ id: Math.random(), keyName: item.No, value: item.ImageUrl })) : [{ id: Math.random(), keyName: '', value: '' }];

    this.state = {
      featuresItem: featureData,
      seoItem: SeoData,
      modulesItem: moduleData,
      highlightItem: highlightData,
      faqItem: faqData,
      projectsItem: projectsData,
      toolsItem: toolsData
    };
  }

  moduleChange = (fieldVal) => {
    this.props.change('modules', fieldVal);
  }

  featureChange = (fieldVal) => {
    this.props.change('features', fieldVal);
  }

  seoChange = (fieldVal) => {
    this.props.change('seo', fieldVal);
  }

  highlightsChange = (fieldVal) => {
    this.props.change('highlights', fieldVal);
  }

  faqChange = (fieldVal) => {
    this.props.change('faq', fieldVal);
  }

  projectsChange = (fieldVal) => {
    this.props.change('projects', fieldVal);
  }

  toolsChange = (fieldVal) => {
    this.props.change('tools', fieldVal);
  }

  render() {
    const { props } = this;
    return (
      <div>
        <form onSubmit={props.handleSubmit}>
          <Field name="Id" component={InputField} type="hidden" className="form-control" />
          <div className="row form-group">
            <div className="col-md-4">
              <label className="text-left">Title</label>
              <div>
                <Field name="Title" component={InputField} type="text" className="form-control" />
              </div>
            </div>
            <div className="col-md-4">
              <label className="text-left">Slug URL</label>
              <div>
                <Field name="SlugURL" component={InputField} type="text" className="form-control" />
              </div>
            </div>
            <div className="col-md-4">
              <label className="text-left">Course Type</label>
              <div>
                <Field name="Type" component={SelectField} className="form-control">
                  <option value="">Select</option>
                  <option key="live" value="Cram Code">Cram Code</option>
                  <option key="Online" value="Cram Class Online">Cram Class Online</option>
                  <option key="Offline" value="Cram Class Offline">Cram Class Offline</option>
                </Field>
              </div>
            </div>
          </div>

          <div className="row form-group">
            <div className="col-md-5">
              <label className="text-left">Learning Objectives (ex : lobj1~lobj2)</label>
              <div>
                <Field name="LearningObjectives" component={InputField} type="text" className="form-control" />
              </div>
            </div>
            <div className="col-md-3">
              <label className="text-left">Featured Youtube Id</label>
              <div>
                <Field name="FeaturedYoutubeId" component={InputField} type="text" className="form-control" />
              </div>
            </div>
            <div className="col-md-4">
              <label className="text-left">Thumbnail URL</label>
              <div>
                <Field name="ThumbnailURL" component={InputField} type="text" className="form-control" />
              </div>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-md-4">
              <label className="text-left">Certificate URL</label>
              <div>
                <Field name="CertificateURL" component={InputField} type="text" className="form-control" />
              </div>
            </div>
            <div className="col-md-4">
              <label className="text-left">Brochure URL</label>
              <div>
                <Field name="BrochureURL" component={InputField} type="text" className="form-control" />
              </div>
            </div>
            <div className="col-md-4">
              <label className="text-left">Youtube Thumbnail URL</label>
              <div>
                <Field name="YoutubeThumbnailURL" component={InputField} type="text" className="form-control" />
              </div>
            </div>
          </div>
          <div className="form-group ">
            <label>Banner Description</label>
            <div>

              <Field
                name="BannerDescription"
                className="form-control"
                component={TinyMCEEditor}
                minHeight={250}
                inlineEditor
              />
            </div>
          </div>
          <div className="form-group ">
            <label>Long Description</label>
            <div>
              <Field
                name="LongDescription"
                className="form-control"
                component={TinyMCEEditor}
                minHeight={600}
                inlineEditor
              />

            </div>
          </div>

          <hr />
          <DynamicMultiForm
            fieldName="Course Content"
            onChange={this.moduleChange}
            keyTitle="Module Name"
            valTitle="Module value (ex : test1~test2)"
            despTitle="Module Description"
            setValue={this.state.modulesItem}
          />
          <hr />
          <DynamicForm
            fieldName="Features"
            onChange={this.featureChange}
            keyTitle="Name"
            valTitle="Values"
            setValue={this.state.featuresItem}
          />
          <hr />
          <DynamicForm
            fieldName="Highlights"
            onChange={this.highlightsChange}
            keyTitle="Icon"
            valTitle="Title"
            setValue={this.state.highlightItem}
          />

          <hr />
          <DynamicForm
            fieldName="FAQ"
            onChange={this.faqChange}
            keyTitle="Question"
            valTitle="Answer"
            setValue={this.state.faqItem}
          />

          <hr />
          <DynamicForm
            fieldName="Projects"
            onChange={this.projectsChange}
            keyTitle="Name"
            valTitle="Description"
            setValue={this.state.projectsItem}
          />
          <hr />
          <DynamicForm
            fieldName="Tools"
            onChange={this.toolsChange}
            keyTitle="No"
            valTitle="ImageUrl"
            setValue={this.state.toolsItem}
          />
          <hr />
          <DynamicForm
            fieldName="SEO"
            onChange={this.seoChange}
            keyTitle="Name"
            valTitle="Values"
            setValue={this.state.seoItem}
          />
          <hr />
          <div className="row">
            <div className="form-group col-md-6">
              <Field
                name="Testimonial"
                label="Testimonial"
                component={MultiSelect}
                type="text"
                suggestionList={props.testimonialData}
                dataKey="Id"
                dataField="StudentName"
              />
            </div>
            {/* <div className="form-group col-md-6">
              <Field
                name="Batches"
                label="Batches"
                component={MultiSelect}
                type="text"
                suggestionList={props.batchData}
                dataKey="Id"
                dataField="Name"
              />
            </div> */}
          </div>
          <div className="py-2 mt-2">
            <Button
              type="submit"
              className="btn btn-sm btn-success"
              loading={props.formLoading}
              loadingText="Saving..."
              disabled={props.pristine}
            >
              <i className="fas fa-save mr-1" />
              Save
            </Button>
            <Button
              onClick={props.reset}
              className="btn btn-sm btn-outline-secondary ml-2"
              disabled={props.pristine}
            >
              <i className="fas fa-redo mr-1" />
              Reset
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

CoursePageForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  change: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  testimonialData: PropTypes.array
  // batchData: PropTypes.array
};

CoursePageForm.defaultProps = {
  formLoading: null,
  formError: null,
  change: null,
  testimonialData: null
  // batchData: null
};

const coursePageForm = reduxForm({
  form: 'Course Page Form',
  initialValues: {},
  enableReinitialize: false
})(CoursePageForm);

const mapStateToProps = ({ coursepage }) => ({
  initialValues: {
    ...coursepage.form,
    LearningObjectives: coursepage.form.LearningObjectives ? coursepage.form.LearningObjectives.join('~') : ''
  },
  testimonialData: coursepage.TestimonialList
  // batchData: coursepage.BatchList
});

export default connect(mapStateToProps)(coursePageForm);
