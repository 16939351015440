import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as actions from '../../redux/actions/actions-batch';
import Button from '../../components/Button';
import SearchBar from '../../components/SearchBar';
import BreadCrumb from '../../components/Breadcrumb';
import Loader from '../../components/Loader';

class Batch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: ''
    };
    props.getBatchList();
    // props.getBranchList();
  }


  deleteBatch = (id) => {
    confirmAlert({
      title: 'Delete Batch',
      message: 'Are you sure to delete the Batch?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.deleteBatch(id)
              .then(() => {
                toast.success('Deleted Successfully');
              }).catch((error) => {
                toast.error(error.message);
              });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleTableFilter = (search) => {
    this.setState({ searchVal: search });
  }

  setTableOption = () => (this.props.batchList && this.props.tableLoading ? <Loader /> : 'No Data Found');

  render() {
    const searchVal = this.state.searchVal.toLowerCase();
    const BatchData = this.props.batchList.filter((item) => item.Name.toLowerCase().includes(searchVal) || item.Code.toLowerCase().includes(searchVal));
    // const branchlist = this.props.branchList;
    const columns = [{
      dataField: 'Name',
      text: 'Batch Details',
      headerStyle: {
        width: '350px'
      },
      formatter: (cell, row) => (
        <>
          <span className="row ml-2">
            <label>Name :</label>
            <span className="ml-1">{row.Name}</span>
          </span>
          <span className="row ml-2">
            <label>Code :</label>
            <span className="ml-1">{row.Code}</span>
          </span>
          <span className="row ml-2 d-none">
            <label> Mode :</label>
            <span className="ml-1">{row.Mode}</span>
          </span>
        </>
      )
    }, {
      dataField: 'IsActive',
      text: 'Status',
      formatter: (cell, row) => (cell ? <label className="badge badge-success">Active</label> : <label className="badge badge-danger">In-Active</label>),
      headerStyle: {
        width: '120px'
      }
    }, {
      dataField: 'StartDate',
      text: 'Batch Date',
      headerStyle: {
        width: '200px'
      },
      formatter: (cell, row) => (
        <>
          {cell ? (
            <span>
              {' '}
              {new Date(cell).toLocaleDateString()}
              {'-'}
              {new Date(row.EndDate).toLocaleDateString()}
              {' '}
            </span>
          ) : '--' }
        </>
      )
    }, {
      dataField: 'CreatedDateTime',
      text: 'Created at',
      formatter: (cell, row) => (
        new Date(cell).toLocaleDateString()
      ),
      sort: true
    }, {
      dataField: 'Id',
      text: 'Actions',
      headerStyle: {
        width: '140px'
      },
      formatter: (cell, row) => (
        <>
          <span
            role="presentation"
            className="fa fa-pencil-alt tableEdit"
            onClick={() => this.props.history.push(`/batch-editor/${cell}`)}
          />
          <span
            role="presentation"
            className="fa fa-trash tableDelete"
            onClick={() => this.deleteBatch(cell)}
          />
        </>
      )
    }];

    const defaultSorted = [{
      dataField: 'CreatedDateTime',
      order: 'desc'
    }];

    return (
      <div>
        <div className="container">
          <BreadCrumb currentPage="Batch" />
          <div className="col-md-12 py-3 mb-5">
            <div className="card border-secondary">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    {/* <span className="fa fa-calendar-alt cardTitle"> Batch</span> */}
                    <SearchBar
                      placeholder="Search by Batch Name, Code"
                      handleChange={(e) => this.handleTableFilter(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2 text-right">
                    <Button
                      className="btn btn-sm btn-secondary"
                      onClick={() => this.props.history.push('/batch-editor/0')}
                    >
                      <span className="fas fa-plus" />
                      {' '}
                      Add New
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body text-secondary">

                <BootstrapTable
                  keyField="Id"
                  data={BatchData}
                  columns={columns}
                  pagination={paginationFactory()}
                  defaultSorted={defaultSorted}
                  noDataIndication={() => this.setTableOption()}
                  hover
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Batch.defaultProps = {
  tableLoading: false,
  batchList: null,
  branchList: null,
  getBatchList: null,
  // getBranchList: null,
  deleteBatch: null
};

Batch.propTypes = {
  tableLoading: PropTypes.bool,
  batchList: PropTypes.array,
  branchList: PropTypes.array,
  getBatchList: PropTypes.func,
  // getBranchList: PropTypes.func,
  deleteBatch: PropTypes.func
};

const mapStateToProps = ({ batch }) => ({
  tableLoading: batch.tableLoading,
  batchList: batch.batchList
  // branchList: batch.branchList
});

const mapDispatchToProps = (dispatch) => ({

  getBatchList: () => dispatch(actions.fetchBatch()),
  // getBranchList: () => dispatch(actions.fetchBranch()),
  deleteBatch: (id) => dispatch(actions.deleteBatch(id))

});

export default connect(mapStateToProps, mapDispatchToProps)(Batch);
