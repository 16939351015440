import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../Button';
import {
  InputField, SelectField, SwitchToggle, MultiSelect
} from '../ReduxFormComponents';
import DynamicMultiForm from './dynamicMultiForm';


const BatchForm = (props) => {
  const branchData = props.BranchesInitial ? props.BranchesInitial.map((item) => ({
    id: Math.random(), branch: item.BranchId, timing: `${item.StartTime}-${item.EndTime}`, price: item.Price, discountPrice: item.DiscountedPrice
  })) : [{
    id: Math.random(), branch: '', timing: '', price: '', discountPrice: ''
  }];


  const branchesChange = (fieldVal) => {
    props.change('branches', fieldVal);
  };

  return (
    <form onSubmit={props.handleSubmit}>
      <Field name="Id" component={InputField} type="hidden" className="form-control" />

      <div className="row form-group">
        <div className="col-md-4">
          <label className="text-left">Batch Name</label>
          <div>
            <Field name="Name" component={InputField} type="text" className="form-control" />
          </div>
        </div>
        <div className="col-md-3">
          <label className="text-left">Batch Code</label>
          <div>
            <Field name="Code" component={InputField} type="text" placeholder="CMPINRBT000XX" className="form-control" />
          </div>
        </div>
        <div className="col-md-3">
          <label className="text-left">Batch Mode</label>
          <div>
            <Field name="Mode" component={SelectField} className="form-control">
              <option value="">Select Mode</option>
              <option key="live" value="Cram Code">Cram Code</option>
              <option key="Online" value="Cram Class Online">Cram Class Online</option>
              <option key="Offline" value="Cram Class Offline">Cram Class Offline</option>
            </Field>
          </div>
        </div>
        <div className="col-md-2">
          <label className="text-left">Is Active</label>
          <div>
            <Field name="IsActive" component={SwitchToggle} type="text" className="form-control" />
          </div>
        </div>
      </div>

      <div className="row form-group">

        <div className="col-md-3">
          <label className="text-left">Duration</label>
          <div>
            <Field name="Duration" component={InputField} type="text" placeholder="ex: 1 Hours 20 Mins" className="form-control" />
          </div>
        </div>
        <div className="col-md-3">
          <label className="text-left">Start Date</label>
          <div>
            <Field name="StartDate" component={InputField} type="date" className="form-control" />
          </div>
        </div>
        <div className="col-md-3">
          <label className="text-left">End Date</label>
          <div>
            <Field name="EndDate" component={InputField} type="date" className="form-control" />
          </div>
        </div>
        <div className="col-md-3">
          <label className="text-left">Admission Deadline</label>
          <div>
            <Field name="AdmissionDeadline" component={InputField} type="date" className="form-control" />
          </div>
        </div>
      </div>

      <div className="row form-group">
        <div className="col-md-6">
          <label className="text-left">Thumbnail URL</label>
          <div>
            <Field name="ThumbnailURL" component={InputField} type="text" className="form-control" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="text-left">Coupons</label>
          <div>
            <Field name="Coupons" component={InputField} type="text" className="form-control" />
          </div>
        </div>
      </div>

      <div className="row form-group">
        <div className="col-md-6">
          <div>
            <Field
              name="Installment"
              label="Installment"
              component={MultiSelect}
              type="text"
              suggestionList={props.installmentData.sort((a, b) => a.Title.localeCompare(b.Title))}
              dataKey="Id"
              dataField="Title"
            />
          </div>
        </div>
        <div className="col-md-6">
          <Field
            name="Courses"
            label="Courses"
            component={MultiSelect}
            type="text"
            suggestionList={props.coursesData.sort((a, b) => a.Title.localeCompare(b.Title))}
            dataKey="Id"
            dataField="Title"
          />
        </div>
      </div>

      <hr />
      <DynamicMultiForm
        fieldName="Branches"
        onChange={branchesChange}
        branchTitle="Branch"
        branchData={props.branchData}
        timingTitle="Timing (ex: 11:30-14:00)"
        priceTitle="Price"
        discountTitle="Discount"
        setValue={branchData}
      />


      <div className="py-2 mt-2">
        <Button
          type="submit"
          className="btn btn-sm btn-success"
          loading={props.formLoading}
          loadingText="Saving..."
          disabled={props.pristine}
        >
          <i className="fas fa-save mr-1" />
          Save
        </Button>
        <Button
          onClick={props.reset}
          className="btn btn-sm btn-outline-secondary ml-2"
          disabled={props.pristine}
        >
          <i className="fas fa-redo mr-1" />
          Reset
        </Button>
      </div>
    </form>
  );
};

BatchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  branchData: PropTypes.array,
  installmentData: PropTypes.array,
  BranchesInitial: PropTypes.any,
  coursesData: PropTypes.array
};

BatchForm.defaultProps = {
  formLoading: null,
  formError: null,
  branchData: null,
  installmentData: null,
  BranchesInitial: null,
  coursesData: null
};

const batchForm = reduxForm({
  form: 'batch Form',
  initialValues: {},
  enableReinitialize: false
})(BatchForm);

const mapStateToProps = ({ batch }) => ({
  initialValues: batch.form,
  BranchesInitial: batch.form.Branches,
  branchData: batch.branchList,
  coursesData: batch.coursesList,
  installmentData: batch.installmentList
});

export default connect(mapStateToProps)(batchForm);
