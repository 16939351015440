import { toast } from 'react-toastify';
import queryString from 'query-string';
import Service from '../../lib/Service';
import { coursePageActions } from '../ActionTypes';

export const fetchCoursePage = () => async (dispatch) => {
  try {
    dispatch({
      type: coursePageActions.COURSE_PAGE_FETCH_START
    });
    const resData = await Service.GET({
      name: 'course-page',
      queryString: null
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: coursePageActions.COURSE_PAGE_FETCH_SUCCESS,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: coursePageActions.COURSE_PAGE_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};

export const saveCoursePage = (formData) => async (dispatch) => {
  try {
    const featuresChk = typeof formData.CourseFeatures !== 'undefined' ? formData.CourseFeatures : 'null';
    const CourseFeatures = formData.features ? formData.features.map((item) => ({
      param: item.keyName,
      value: item.value
    })) : featuresChk;

    const seoChk = typeof formData.SEO !== 'undefined' ? formData.SEO : 'null';
    const SEO = formData.seo ? formData.seo.map((item) => ({
      param: item.keyName,
      value: item.value
    })) : seoChk;

    const highlightsChk = typeof formData.CourseHighlights !== 'undefined' ? formData.CourseHighlights : 'null';
    const CourseHighlights = formData.highlights ? formData.highlights.map((item) => ({
      icon: item.keyName,
      title: item.value
    })) : highlightsChk;

    const FAQChk = typeof formData.FAQ !== 'undefined' ? formData.FAQ : 'null';
    const FAQ = formData.faq ? formData.faq.map((item, index) => ({
      Number: index + 1,
      Q: item.keyName,
      A: item.value
    })) : FAQChk;

    const ProjectChk = typeof formData.Projects !== 'undefined' ? formData.Projects : 'null';
    const Projects = formData.projects ? formData.projects.map((item) => ({
      Name: item.keyName,
      Description: item.value
    })) : ProjectChk;

    const CourseContentChk = typeof formData.CourseContent !== 'undefined' ? formData.CourseContent : 'null';
    const CourseContent = formData.modules ? formData.modules.map((item) => ({
      Modules: item.value.split('~'),
      Name: item.keyName,
      Description: item.descp
    })) : CourseContentChk;

    const LearningObjectives = formData.LearningObjectives ? formData.LearningObjectives.split('~') : null;

    const Testimonial = formData.Testimonial ? formData.Testimonial.map((item) => item.Id) : null;

    const ToolsChk = typeof formData.Tools !== 'undefined' ? formData.Tools : 'null';
    const Tools = formData.tools ? formData.tools.map((item) => ({
      No: item.keyName,
      ImageUrl: item.value
    })) : ToolsChk;

    const payload = {
      ...formData,
      LearningObjectives,
      CourseFeatures,
      CourseHighlights,
      SEO,
      FAQ,
      Projects,
      CourseContent,
      Testimonial,
      Tools
    };

    delete payload.faq;
    delete payload.projects;
    delete payload.features;
    delete payload.highlights;
    delete payload.modules;

    dispatch({
      type: coursePageActions.COURSE_PAGE_SAVE_START
    });
    await Service[formData.Id === '0' ? 'POST' : 'PUT']({
      name: 'course-page',
      payload
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(toast.error(res.message));
    });
    dispatch({
      type: coursePageActions.COURSE_PAGE_SAVE_SUCCESS
    });
    toast.success('Saved Successfully');
    setTimeout(() => { window.location.href = '/course-page'; }, 1000);
  } catch (error) {
    dispatch({
      type: coursePageActions.COURSE_PAGE_SAVE_FAILED,
      payload: { formError: error.message }
    });
  }
};

export const editCoursePage = (coursepageId) => async (dispatch, getState) => {
  try {
    const { CoursePageList } = getState().coursepage;
    const data = CoursePageList.find((item) => item.Id === coursepageId);
    if (!data) {
      throw new Error('Something went wrong');
    }
    dispatch({
      type: coursePageActions.COURSE_PAGE_SET_FORM,
      payload: { form: data }
    });
  } catch (error) {
    toast.error(error.message);
  }
};

export const resetForm = () => (dispatch) => dispatch({ type: coursePageActions.COURSE_PAGE_RESET_FORM });

export const deleteCoursePage = (Id) => async (dispatch) => Service.DELETE({
  name: 'course-page',
  payload: { Id }
}).then((res) => {
  if (res.success) {
    dispatch(fetchCoursePage());
    return true;
  }
  throw new Error(res.message);
});


export const cloneCoursePage = (Id) => async (dispatch) => {
  try {
    Service.GET({
      name: 'course-page/clone',
      queryString: queryString.stringify({ Id })
    }).then((res) => {
      if (res.success) {
        dispatch(fetchCoursePage());
        toast.success(res.message);
        return true;
      }
      throw new Error(res.message);
    });
  } catch (error) {
    toast.error(error.message);
  }
};


export const fetchTestimonial = () => async (dispatch) => {
  try {
    dispatch({
      type: coursePageActions.CP_TESTIMONIAL_FETCH_START
    });
    const resData = await Service.GET({
      name: 'testimonial',
      queryString: null
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: coursePageActions.CP_TESTIMONIAL_FETCH_SUCCESS,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: coursePageActions.CP_TESTIMONIAL_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};
