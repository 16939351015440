import { toast } from 'react-toastify';
import Service from '../../lib/Service';
import { emailTemplateActions } from '../ActionTypes';


export const fetchEmailTemplate = () => async (dispatch) => {
  try {
    dispatch({
      type: emailTemplateActions.EMAIL_TEMPLATE_FETCH_START
    });

    const resData = await Service.GET({
      name: 'email-template',
      queryString: ''
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: emailTemplateActions.EMAIL_TEMPLATE_FETCH_SUCCESS,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: emailTemplateActions.EMAIL_TEMPLATE_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};

export const saveEmailTemplate = (formData) => async (dispatch) => {
  try {
    const payload = {
      ...formData
    };
    dispatch({
      type: emailTemplateActions.EMAIL_TEMPLATE_SAVE_START
    });
    await Service[formData.Id === '0' ? 'POST' : 'PUT']({
      name: 'email-template',
      payload
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(toast.error(res.message));
    });
    dispatch({
      type: emailTemplateActions.EMAIL_TEMPLATE_SAVE_SUCCESS
    });
    toast.success('Saved Successfully');
    setTimeout(() => { window.location.href = '/email-template'; }, 1000);
  } catch (error) {
    dispatch({
      type: emailTemplateActions.EMAIL_TEMPLATE_SAVE_FAILED,
      payload: { formError: error.message }
    });
  }
};

export const editEmailTemplate = (tempName) => async (dispatch, getState) => {
  try {
    const { emailTemplateList } = getState().emailTemplate;
    const data = emailTemplateList.find((item) => item.templateName === tempName);
    if (!data) {
      throw new Error('Something went wrong');
    }
    dispatch({
      type: emailTemplateActions.EMAIL_TEMPLATE_SET_FORM,
      payload: { form: data }
    });
  } catch (error) {
    toast.error(error.message);
  }
};

export const resetForm = () => (dispatch) => dispatch({ type: emailTemplateActions.EMAIL_TEMPLATE_RESET_FORM });

export const deleteEmailTemplate = (templateName) => async (dispatch) => Service.DELETE({
  name: 'email-template',
  payload: { templateName }
}).then((res) => {
  if (res.success) {
    dispatch(fetchEmailTemplate());
    return true;
  }
  throw new Error(res.message);
});
