import { testimonialActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utils';

const initialFormData = {
  Id: '0',
  StudentName: '',
  Content: '',
  StudentPicURL: ''
};

const defaultState = {
  testimonialList: [],
  tableLoading: false,
  form: initialFormData,
  formLoading: false,
  formError: null,
  deleteId: null
};


export default (state = defaultState, actions) => {
  switch (actions.type) {
    case testimonialActions.TESTIMONIAL_FETCH_START:
      return updateState(state, { tableLoading: true });
    case testimonialActions.TESTIMONIAL_FETCH_SUCCESS:
      return updateState(state, { tableLoading: false, testimonialList: actions.payload });
    case testimonialActions.TESTIMONIAL_FETCH_ERROR:
      return updateState(state, { tableLoading: false, ...actions.payload });
    case testimonialActions.TESTIMONIAL_SAVE_START:
      return updateState(state, { formLoading: true, formError: null, form: initialFormData });
    case testimonialActions.TESTIMONIAL_SAVE_SUCCESS:
      return updateState(state, { formLoading: false, form: initialFormData });
    case testimonialActions.TESTIMONIAL_SAVE_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    case testimonialActions.TESTIMONIAL_SET_FORM:
      return updateState(state, { ...actions.payload, formError: null });
    case testimonialActions.TESTIMONIAL_RESET_FORM:
      return updateState(state, { form: initialFormData });
    default:
      return state;
  }
};
