import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../styles/SearchSelect.scss';

class SearchSelect extends Component {
  state = {
    searchText: '',
    showList: false,
    mouseOnList: false,
    isStateSet: false
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value && !state.isStateSet) {
      const selectedObj = props.list.find((i) => i[props.dataKey] === props.value);
      if (selectedObj) {
        return {
          ...state,
          searchText: selectedObj[props.dataField] || '',
          isStateSet: true
        };
      }
    }
    return null;
  }

  handleSelect = (item) => {
    if (!this.props.disabled) {
      this.setState({
        searchText: item[this.props.dataField],
        showList: false,
        mouseOnList: false
      }, this.props.onChange(item[this.props.dataKey]));
    }
  }

  handleClear = () => {
    if (!this.props.disabled) {
      this.setState({
        searchText: ''
      }, () => this.props.onChange(''));
    }
  }

  render() {
    const {
      list,
      className,
      placeholder,
      dataField,
      dataKey,
      disabled
    } = this.props;
    return (
      <div className="with-search">
        <div className="input-field">
          <input
            type="text"
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            value={this.state.searchText}
            onChange={(e) => this.setState({ searchText: e.target.value })}
            onFocus={() => this.setState({ showList: true })}
            onBlur={() => {
              if (!this.state.mouseOnList) {
                this.setState({
                  showList: false,
                  mouseOnList: false
                });
              }
            }}
          />
          {(this.state.searchText && !disabled) && <i role="presentation" onClick={this.handleClear} className="fas fa-times-circle" />}
        </div>
        {this.state.showList && (
          <ul onMouseEnter={() => this.setState({ mouseOnList: true })} onMouseLeave={() => this.setState({ mouseOnList: false })} className="list-group search-list">
            {list
              && list.length > 0
              && list.filter((item) => {
                if (this.state.searchText) {
                  return item[dataField] && item[dataField].toLowerCase().includes(this.state.searchText.toLowerCase());
                }
                return true;
              }).map((item, index) => (
                <li
                  role="presentation"
                  key={item[dataKey] || `${index}`}
                  onClick={() => this.handleSelect(item)}
                  className="list-group-item list-group-item-action list-group-item-light"
                >
                  {item[dataField] || '-'}
                </li>
              ))}
          </ul>
        )}
      </div>
    );
  }
}

SearchSelect.defaultProps = {
  list: [],
  dataKey: 'id',
  onChange: () => null,
  className: 'form-control',
  placeholder: null,
  value: null,
  disabled: false
};

SearchSelect.propTypes = {
  list: PropTypes.array,
  dataField: PropTypes.string.isRequired,
  dataKey: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool
};

export default SearchSelect;
