import React from 'react';
import PropTypes from 'prop-types';

const Modal = (props) => {
  if (props.show) {
    return (
      <div className={`custom-modal fade show ${props.additionalClassName}`} tabIndex="-1" role="dialog">
        <div className={`modal-dialog ${props.dialogClassName}`} role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{props.title}</h5>
              <button type="button" className="close" onClick={props.onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className={`modal-body ${props.modalBodyClassName}`}>
              {props.loading ? (
                <div className="w-100 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

Modal.defaultProps = {
  title: null,
  onClose: null,
  children: null,
  show: false,
  additionalClassName: null,
  modalBodyClassName: '',
  loading: false,
  dialogClassName: null
};

Modal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.any,
  show: PropTypes.bool,
  additionalClassName: PropTypes.string,
  modalBodyClassName: PropTypes.string,
  loading: PropTypes.bool,
  dialogClassName: PropTypes.string
};

export default Modal;
