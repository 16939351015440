import React, { Component } from 'react';

class Shop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <h2 className="font-weight">I Am Shop</h2>
        <input type="text" className="form-control" />
      </div>
    );
  }
}

export default Shop;
