import React, { Component } from 'react';
import {
  Route, Switch, Redirect
} from 'react-router-dom';
import * as Cookies from '../lib/Cookie';
import Login from '../containers/Login/Login';


class AuthRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Switch>
          <Route path="/" exact component={Login} />
          {Cookies.get('cadmin_token') ? <Redirect to={window.location.pathname} /> : <Redirect to="/" /> }
        </Switch>
      </div>
    );
  }
}

export default AuthRoutes;
