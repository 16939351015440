import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import NavLink from './NavLink';
import * as actions from '../redux/actions/actions-login';

class Heading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="sticky-top">
        <nav className="navbar bg-dark navbar-expand-lg navbar navbar-dark flex-md-nowrap p-0">
          <Link to="/" className="navbar-brand pl-3">CRAMPETE ADMIN</Link>
          {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarColor01">
            <ul className="navbar-nav mr-auto">
              <NavLink to="/" className="nav-link">HOME</NavLink>
              <NavLink to="/blog" className="nav-link">BLOG</NavLink>
              <NavLink to="/courses" className="nav-link">COURSES</NavLink>
              <NavLink to="/course-page" className="nav-link">COURSE PAGE</NavLink>
              <NavLink to="/leads" className="nav-link">LEADS</NavLink>
              <li className="nav-item dropdown">
                <Link to="/" className="nav-link dropdown-toggle">
                  RESOURCES
                </Link>
                <div className="dropdown-menu">
                  <Link to="/branch" className="dropdown-item">BRANCH</Link>
                  <Link to="/webinar" className="dropdown-item">WEBINAR</Link>
                  <Link to="/contest" className="dropdown-item">CONTEST</Link>
                  <Link to="/guide" className="dropdown-item">GUIDE</Link>
                  <Link to="/page" className="dropdown-item">PAGE</Link>
                </div>
              </li>
            </ul>
          </div> */}
          <ul className="navbar-nav mr-auto" />
          <div
            className="pull-right"
            style={{ color: '#fff' }}
          >
            <ul className="navbar-nav">
              <li className="nav-item pr-3">
                <span className="fa fa-user">
                  {' '}
                </span>
                &nbsp;
                {' '}
                {this.props.AdminName}
              </li>
              <li
                className="nav-item pr-3"
                role="presentation"
                onClick={() => this.props.Logout()}
                style={{ cursor: 'pointer' }}
              >
                <span
                  role="button"
                  className="fa fa-sign-out-alt"
                >
                  {' '}
                </span>
                &nbsp;&nbsp;logout
              </li>
            </ul>

          </div>
        </nav>

      </div>
    );
  }
}


Heading.propTypes = {
  Logout: PropTypes.func.isRequired,
  AdminName: PropTypes.string.isRequired
};
const mapStateToProps = ({ auth }) => ({
  AdminName: auth.AdminName
});

const mapDispatchToProps = (dispatch) => ({
  Logout: () => dispatch(actions.logout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Heading);
