import queryString from 'query-string';
import { toast } from 'react-toastify';
import Service from '../../lib/Service';
import { learningModuleActions } from '../ActionTypes';


export const fetchLM = (CourseId) => async (dispatch) => {
  try {
    dispatch({
      type: learningModuleActions.LM_FETCH_START
    });

    const resData = await Service.GET({
      name: 'learning-module',
      queryString: queryString.stringify({ CourseId })
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: learningModuleActions.LM_FETCH_SUCCESS,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: learningModuleActions.LM_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};
export const saveLM = (formData) => async (dispatch) => {
  try {
    const payload = {
      ...formData
    };
    dispatch({
      type: learningModuleActions.LM_SAVE_START
    });
    await Service[formData.Id === '0' ? 'POST' : 'PUT']({
      name: 'learning-module',
      payload
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(toast.error(res.message));
    });
    dispatch({
      type: learningModuleActions.LM_SAVE_SUCCESS
    });
    toast.success('Saved Successfully');

    setTimeout(() => { window.location.href = `/learning-module/${formData.CourseId}`; }, 1000);
  } catch (error) {
    dispatch({
      type: learningModuleActions.LM_SAVE_FAILED,
      payload: { formError: error.message }
    });
  }
};

export const editLM = (LMId) => async (dispatch, getState) => {
  try {
    const { lmList } = getState().learningModule;
    const data = lmList.find((item) => item.Id === LMId);
    if (!data) {
      throw new Error('Something went wrong');
    }
    dispatch({
      type: learningModuleActions.LM_SET_FORM,
      payload: { form: data }
    });
  } catch (error) {
    toast.error(error.message);
  }
};

export const resetForm = () => (dispatch) => dispatch({ type: learningModuleActions.LM_RESET_FORM });

export const deleteLM = (Id, CourseId) => async (dispatch) => Service.DELETE({
  name: 'learning-module',
  payload: { Id }
}).then((res) => {
  if (res.success) {
    dispatch(fetchLM(CourseId));
    return true;
  }
  throw new Error(res.message);
});
