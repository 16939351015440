import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import * as actions from '../../redux/actions/actions-adminRole';
import RoleAccordian from '../../components/AdminRoles/RoleAccordian';
import RoleEditor from '../../components/AdminRoles/RoleEditor';
import Loader from '../../components/Loader';
import '../../styles/AdminRole.scss';

const AdminRoleMappping = (props) => {
  useEffect(() => {
    props.fetchMenuList(props.match.params.id);
  }, []);

  const confirmDelete = (id, name) => {
    confirmAlert({
      title: 'Delete Role',
      message: (
        <span>
          Are you sure you want to delete
          {' '}
          <strong>{name}</strong>
          ?
        </span>
      ),
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const toastId = toast.info('Deleting...');
            try {
              await props.handleDelete(props.match.params.id, id);
              toast.dismiss(toastId);
              toast.success('Deleted Successfully...');
              props.fetchMenuList(props.match.params.id);
            } catch (error) {
              toast.dismiss(toastId);
              toast.error(error.message);
            }
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const renderRoles = (parentId) => props.adminRoleList
    .filter((i) => i.ParentId === parentId)
    .sort((a, b) => Number(a.OrderNumber) - Number(b.OrderNumber))
    .map((menu) => {
      const subRoles = renderRoles(menu.Id);
      return (
        <RoleAccordian
          key={menu.Id}
          roleId={props.match.params.id}
          menuDetails={menu}
          editId={props.editId}
          handleEdit={props.handleEdit}
          handleDelete={() => confirmDelete(menu.Id, menu.MenuName)}
        >
          {subRoles}
        </RoleAccordian>
      );
    });
  return (
    
    <div className="admin-role-container">
       
      <p>
        <h4>
          <strong>{props.match.params.id.toUpperCase()}</strong>
          {' '}
          role mapping.
        </h4>
        Add, Edit and Manage the menu access and it&apos;s corresponding action permissions here for
        {' '}
        <b>{props.match.params.id}</b>
        {' '}
        role.
      </p>
      {props.menuLoading ? <div className="py-4"><Loader/> </div> : renderRoles('0')}
      <RoleEditor parentId="0" roleId={props.match.params.id} />
    </div>
  );
};

AdminRoleMappping.defaultProps = {
  adminRoleList: [],
  editId: null
};

AdminRoleMappping.propTypes = {
  match: PropTypes.object.isRequired,
  fetchMenuList: PropTypes.func.isRequired,
  menuLoading: PropTypes.bool.isRequired,
  adminRoleList: PropTypes.array,
  handleEdit: PropTypes.func.isRequired,
  editId: PropTypes.string,
  handleDelete: PropTypes.func.isRequired
};

const mapStateToProps = ({ adminRoles }) => ({
  menuLoading: adminRoles.menuLoading,
  adminRoleList: adminRoles.adminRoleList,
  editId: adminRoles.editId
});

const mapDispatchToProps = (dispatch) => ({
  fetchMenuList: (roleId) => dispatch(actions.fetchMenuList(roleId)),
  handleEdit: (id) => dispatch(actions.handleEdit(id)),
  handleDelete: async (roleId, id) => dispatch(actions.handleDelete(roleId, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminRoleMappping);
