import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import AdminsForm from '../../components/Admins/AdminsForm';
import BreadCrumb from '../../components/Breadcrumb';
import * as actions from '../../redux/actions/actions-admins';


const AddAdmins = ({
  saveAdmins, setForm, resetForm, match, history
}) => {
  const adminId = match.params.id;

  useEffect(() => {
    if (adminId !== '0') {
      setForm(adminId);
    } else {
      resetForm();
    }
  }, [adminId]);

  const handleSubmit = (FormData) => {
    saveAdmins(FormData);
  };

  return (
    <>
      <div className="container">
        <BreadCrumb currentPage="Admins" />
        <div className="col-md-12 py-3 mb-5">
          <div className="card border-secondary">
            <div className="card-header">
              <div className="row">
                <div className="col-md-10">
                  { adminId !== '0'
                    ? <span className="fa fa-pencil-alt cardTitle"> Edit Admins</span>
                    : <span className="fa fa-plus-square cardTitle"> Create New Admins</span>}

                </div>
                <div className="col-md-2 text-right">
                  <Button
                    className="btn btn-sm btn-secondary"
                    onClick={() => history.goBack()}
                  >
                    <span className="fa fa-arrow-left" />
                    {' '}
                    Back
                  </Button>
                </div>
              </div>
            </div>
            <div className="card-body text-secondary">
              {/* <h3>fnkbfgjbfgb</h3> */}
              <AdminsForm
                onSubmit={handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddAdmins.propTypes = {
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  saveAdmins: PropTypes.func,
  setForm: PropTypes.func,
  resetForm: PropTypes.func,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};


AddAdmins.defaultProps = {
  formLoading: null,
  formError: null,
  saveAdmins: null,
  setForm: null,
  resetForm: null
};

const mapStateToProps = ({ admins }) => ({});


const mapDispatchToProps = (dispatch) => ({
  saveAdmins: (data) => dispatch(actions.saveAdmins(data)),
  setForm: (id) => dispatch(actions.editAdmins(id)),
  resetForm: () => dispatch(actions.resetForm())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAdmins));
