import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../Button';
import RoleForm from './RoleForm';
import * as actions from '../../redux/actions/actions-adminRole';

class RoleEditor extends Component {
  state = {
    showForm: false,
    editMode: false
  }

  static getDerivedStateFromProps(props, state) {
    if (props.editMode) {
      return {
        ...state,
        showForm: props.editMode,
        editMode: props.editMode
      };
    }
    return null;
  }

  saveRoleFormData = (formData) => {
    try {
      const selectedPageDetails = this.props.menuList.find((i) => i.Id === formData.MenuId);
      const convertToArr = (obj, column, dataKey = null) => Object.keys(obj).map((key) => {
        if (obj[key]) {
          return selectedPageDetails[column].find((i) => (dataKey ? i[dataKey] : i) === key);
        }
        return null;
      }).filter((i) => i);
      const RelativeMenus = formData.RelativeMenus ? convertToArr(formData.RelativeMenus, 'RelativeMenus', 'Component') : [];
      this.props.saveMenuMappingRole({
        ...formData,
        Component: selectedPageDetails.Component,
        MenuUrl: selectedPageDetails.MenuUrl,
        ParentId: this.props.parentId,
        RoleId: this.props.roleId,
        RelativeMenus
      });
      this.setState({
        showForm: false
      });
    } catch (error) {
      toast.error(error.message);
    }
  }

  handleCancel = () => {
    if (this.props.editMode) {
      this.props.cancelEdit();
    } else {
      this.setState({
        showForm: false
      });
    }
  }

  frameInitialValues = () => {
    const {
      MenuName, MenuId, Id, RelativeMenus, OrderNumber, Icon
    } = this.props.menuDetails;

    const reduceObj = (arr, dataKey = null) => arr.map((i) => ({
      [dataKey ? i[dataKey] : i]: true
    })).reduce((result, item) => {
      const key = Object.keys(item)[0];
      const tempResult = result;
      tempResult[key] = item[key];
      return result;
    }, {});
    return {
      MenuName,
      MenuId,
      Id,
      OrderNumber,
      Icon,
      RelativeMenus: reduceObj(RelativeMenus || [], 'Component')
    };
  }

  render() {
    const { title } = this.props;
    if (this.state.showForm) {
      return (
        <div className="w-100 role-editor-form">
          <div className="header">
            <p className="m-0 title">
              {this.state.editMode ? (
                <>
                  Edit
                  {' '}
                  <strong>{this.props.menuDetails.MenuName}</strong>
                </>
              ) : 'Add Menu role'}

            </p>
            <Button
              className="btn btn-sm btn-outline-danger"
              onClick={this.handleCancel}
            >
              <i className="fas fa-times" />
              &nbsp;Cancel
            </Button>
          </div>
          <hr />
          <div className="content">
            <RoleForm
              menuList={this.props.menuList}
              onSubmit={this.saveRoleFormData}
              initialValues={this.props.editMode ? this.frameInitialValues() : undefined}
            />
          </div>
        </div>
      );
    }
    return (
      <Button
        className="btn btn-outline-success btn-block"
        onClick={() => this.setState({
          showForm: true
        })}
      >
        <i className="fas fa-plus mr-3" />
        Add a Menu Role
        {title && ' in '}
        <strong>{title}</strong>
      </Button>
    );
  }
}

RoleEditor.defaultProps = {
  title: null,
  menuList: [],
  editMode: false,
  cancelEdit: () => null,
  menuDetails: {}
};

RoleEditor.propTypes = {
  title: PropTypes.string,
  parentId: PropTypes.string.isRequired,
  menuList: PropTypes.array,
  saveMenuMappingRole: PropTypes.func.isRequired,
  roleId: PropTypes.string.isRequired,
  editMode: PropTypes.bool,
  cancelEdit: PropTypes.func,
  menuDetails: PropTypes.object
};

const mapStateToProps = ({ adminRoles }) => ({
  menuList: adminRoles.menuList,
  formLoading: adminRoles.formLoading,
  formError: adminRoles.formError 
});

const mapDispatchToProps = (dispatch) => ({
  saveMenuMappingRole: (formData) => dispatch(actions.saveMenuMappingRole(formData))
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleEditor);
