import React, { Component } from 'react';
import PropTypes from 'prop-types';

class dynamicMultiForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldArray: [{
        id: new Date().getTime(),
        branch: '',
        timing: '',
        price: '',
        discountPrice: ''
      }],
    };
  }

  componentDidMount() {
    if (this.props.setValue) {
      this.setState(() => ({ fieldArray: this.props.setValue }));
    }
  }

    incFieldCount = () => {
      const payloadArr = this.state.fieldArray.slice();
      payloadArr.push({
        id: new Date().getTime(),
        branch: '',
        timing: '',
        price: '',
        discountPrice: ''
      });
      this.setState(() => ({ fieldArray: payloadArr }));
    };

      decFieldCount = (id) => {
        const selPayloadArr = this.state.fieldArray.slice();
        const delIndex = selPayloadArr.findIndex((i) => i.id === id);
        if (delIndex > -1) {
          selPayloadArr.splice(delIndex, 1);
          this.setState(() => ({
            fieldArray: selPayloadArr
          }), () => this.props.onChange(selPayloadArr));
        }
      }

      handleTimingChange = ({ id }, value) => {
        const selPayloadArr = this.state.fieldArray.slice();
        const delIndex = selPayloadArr.findIndex((i) => i.id === id);
        if (delIndex > -1) {
          selPayloadArr[delIndex].timing = value;
          this.setState(() => ({
            fieldArray: selPayloadArr
          }), () => this.props.onChange(selPayloadArr));
        }
      }

      handlePriceChange = ({ id }, value) => {
        const selPayloadArr = this.state.fieldArray.slice();
        const delIndex = selPayloadArr.findIndex((i) => i.id === id);
        if (delIndex > -1) {
          selPayloadArr[delIndex].price = value;
          this.setState(() => ({
            fieldArray: selPayloadArr
          }), () => this.props.onChange(selPayloadArr));
        }
      }

      handleDiscountChange = ({ id }, value) => {
        const selPayloadArr = this.state.fieldArray.slice();
        const delIndex = selPayloadArr.findIndex((i) => i.id === id);
        if (delIndex > -1) {
          selPayloadArr[delIndex].discountPrice = value;
          this.setState(() => ({
            fieldArray: selPayloadArr
          }), () => this.props.onChange(selPayloadArr));
        }
      }

      handleBranchChange = ({ id }, value) => {
        const selPayloadArr = this.state.fieldArray.slice();
        console.log('selPayloadArr', selPayloadArr);
        const delIndex = selPayloadArr.findIndex((i) => i.id === id);
        console.log('delIndex', delIndex);

        if (delIndex > -1) {
          selPayloadArr[delIndex].branch = value;
          this.setState(() => ({
            fieldArray: selPayloadArr
          }), () => this.props.onChange(selPayloadArr));
        }
        console.log('selPayloadArrAfter', selPayloadArr);
        console.log('fieldArray', this.state.fieldArray);
      }

      render() {
        return (
          <>
            <div className="form-group">
              <label className="col-form-label"><strong>{this.props.fieldName}</strong></label>
            </div>
            {this.state.fieldArray.map((p, index) => (
              <div className="form-group" key={p.id}>
                <div className="row">
                  <div className="col-md-3">
                    <label className="text-left">{this.props.branchTitle}</label>
                    <div>
                      <select
                        name="branch"
                        className="form-control"
                        value={this.state.fieldArray[index].branch}
                        onChange={(e) => this.handleBranchChange(p, e.target.value)}
                      >
                        <option value="">Select Branch</option>
                        {this.props.branchData.map(({ Name, Id }) => <option key={Id} value={Id}>{Name}</option>)}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-3">
                    <label>
                      {' '}
                      {this.props.timingTitle}
                    </label>
                    <input
                      type="text"
                      value={p.timing}
                      className="form-control"
                      placeholder=""
                      onChange={(e) => this.handleTimingChange(p, e.target.value)}
                    />
                  </div>


                  <div className="col-md-2">
                    <label>
                      {' '}
                      {this.props.priceTitle}
                    </label>
                    <input
                      type="text"
                      value={p.price}
                      className="form-control"
                      placeholder=""
                      onChange={(e) => this.handlePriceChange(p, e.target.value)}
                    />
                  </div>
                  <div className="col-md-2">
                    <label>
                      {' '}
                      {this.props.discountTitle}
                    </label>
                    <input
                      type="text"
                      value={p.discountPrice}
                      className="form-control"
                      placeholder=""
                      onChange={(e) => this.handleDiscountChange(p, e.target.value)}
                    />
                  </div>
                  <div className="col-md-1">
                    {
                      index === 0 ? (
                        <div
                          className="row mt-5"
                          onKeyPress={this.incFieldCount}
                          role="link"
                          tabIndex="0"
                          style={{ outline: 'none' }}
                          onClick={this.incFieldCount}
                        >
                          <i className="fas fa-plus-circle fa-lg" style={{ cursor: 'pointer' }} />
                        </div>
                      ) : (
                        <div
                          className="row mt-5"
                          onKeyPress={() => this.decFieldCount(p.id)}
                          role="link"
                          tabIndex="0"
                          onClick={() => this.decFieldCount(p.id)}
                        >
                          <i className="fas fa-times-circle fa-lg" style={{ cursor: 'pointer' }} />
                        </div>
                      )
                    }

                  </div>
                </div>

              </div>
            ))}
          </>
        );
      }
}


dynamicMultiForm.propTypes = {
  onChange: PropTypes.func,
  setValue: PropTypes.array
};

dynamicMultiForm.defaultProps = {
  onChange: null,
  setValue: []
};


export default dynamicMultiForm;
