import { adminRoleActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utils';

const initialFormData = {
  Id: '0',
  Title: '',
  IsSuperUser: false
};

const defaultState = {
  adminRoleList: [],
  tableLoading: false,
  form: initialFormData,
  formLoading: false,
  formError: null,
  showCrudModal: false,
  menuLoading: false
};


export default (state = defaultState, actions) => {
  switch (actions.type) {
    case adminRoleActions.ADMINROLES_FETCH_START:
      return updateState(state, { tableLoading: true });
    case adminRoleActions.ADMINROLES_FETCH_SUCCESS:
      return updateState(state, { tableLoading: false, adminRoleList: actions.payload });
    case adminRoleActions.ADMINROLES_FETCH_ERROR:
      return updateState(state, { tableLoading: false, ...actions.payload });
    case adminRoleActions.ADMINROLES_SAVE_START:
      return updateState(state, { formLoading: true, formError: null, form: initialFormData });
    case adminRoleActions.ADMINROLES_SAVE_SUCCESS:
      return updateState(state, { formLoading: false, form: initialFormData, showCrudModal: false });
    case adminRoleActions.ADMINROLES_SAVE_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    case adminRoleActions.ADMINROLES_SET_FORM:
      return updateState(state, { ...actions.payload, formError: null, showCrudModal: true });
    case adminRoleActions.ADMINROLES_RESET_FORM:
      return updateState(state, { form: initialFormData });
    case adminRoleActions.ADMINROLES_TOGGLE_MODAL:
      return updateState(state, {
        ...actions.payload, formError: null, formLoading: false, form: initialFormData
      });
      
      // Menus
    case adminRoleActions.ADMINROLE_MENU_FETCH_START:
      return updateState(state, {
        menuLoading: true, menuList: [], adminRoleList: [], editId: null
      });
    case adminRoleActions.ADMINROLE_MENU_FETCH_SUCCESS:
      return updateState(state, {
        menuLoading: false,
        menuList: actions.payload.menus,
        adminRoleList: actions.payload.adminMenus
      });
    case adminRoleActions.ADMINROLE_MENU_FETCH_FAILED:
      return updateState(state, { menuLoading: false });
    case adminRoleActions.ADMINROLE_MENU_EDIT:
      return updateState(state, { editId: actions.payload });

    case adminRoleActions.ADMINROLE_MAPPING_SAVE_START:
      return updateState(state, { formLoading: true, formError: null });
    case adminRoleActions.ADMINROLE_MAPPING_SAVE_SUCCESS:
      return updateState(state, { formLoading: false });
    case adminRoleActions.ADMINROLE_MAPPING_SAVE_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    default:
      return state;
  }
};
