import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../Button';
import {
  InputField, TinyMCEEditor, SelectField
} from '../ReduxFormComponents';


const LMForm = (props) => {
  const [type, setType] = useState(props.defaultType);
  return (
    <form onSubmit={props.handleSubmit}>
      <Field name="Id" component={InputField} type="hidden" className="form-control" />
      <div className="row form-group">
        <div className="col-md-6">
          <label className="text-left">Name</label>
          <div>
            <Field name="Name" component={InputField} type="text" className="form-control" />
          </div>
        </div>
        <div className="col-md-6">
          <label className="text-left">Type</label>
          <div>
            <Field name="Type" id="Type" component={SelectField} onChange={(e) => setType(e.target.value)} className="form-control">
              <option value="">Select Type</option>
              <option key="Video" value="Video">Video</option>
              <option key="HTML Editor" value="HTML Editor">HTML Editor</option>
              <option key="Code Editor" value="Code Editor">Code Editor</option>
              <option key="Quiz" value="Quiz">Quiz</option>
            </Field>
          </div>
        </div>
      </div>

      <div className={`${type === 'Video' ? 'd-block' : 'd-none'}`}>
        <div className="row form-group">
          <div className="col-md-6">
            <label className="text-left">Vimeo URL</label>
            <div>
              <Field name="VimeoURL" component={InputField} type="text" className="form-control" />
            </div>
          </div>
          <div className="col-md-6">
            <label className="text-left">Youtube URL</label>
            <div>
              <Field name="YoutubeURL" component={InputField} type="text" className="form-control" />
            </div>
          </div>
        </div>
      </div>

      <div className={`row form-group ${type === 'HTML Editor' ? 'd-block' : 'd-none'}`}>
        <div className="col-12">
          <label>Content</label>
          <Field
            name="Content"
            className="form-control"
            component={TinyMCEEditor}
            minHeight={500}
            inlineEditor
          />
        </div>
      </div>

      <div className="form-group ">
        <label>Description</label>
        <div>
          <Field
            name="Description"
            className="form-control"
            component={TinyMCEEditor}
            minHeight={300}
            inlineEditor
          />
        </div>
      </div>
      <div className="py-2 mt-2">
        <Button
          type="submit"
          className="btn btn-sm btn-success"
          loading={props.formLoading}
          loadingText="Saving..."
          disabled={props.pristine}
        >
          <i className="fas fa-save mr-1" />
          Save
        </Button>
        <Button
          onClick={props.reset}
          className="btn btn-sm btn-outline-secondary ml-2"
          disabled={props.pristine}
        >
          <i className="fas fa-redo mr-1" />
          Reset
        </Button>
      </div>
    </form>
  );
};

LMForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  defaultType: PropTypes.string
};

LMForm.defaultProps = {
  formLoading: null,
  formError: null,
  defaultType: null
};

const lmForm = reduxForm({
  form: 'LM Form',
  initialValues: {},
  enableReinitialize: false
})(LMForm);

const mapStateToProps = ({ learningObject }) => ({
  initialValues: learningObject.form,
  defaultType: learningObject.form.Type
});

export default connect(mapStateToProps)(lmForm);
