import { toast } from 'react-toastify';
import Service from '../../lib/Service';
import { contestActions } from '../ActionTypes';


export const fetchContest = () => async (dispatch) => {
  try {
    dispatch({
      type: contestActions.CONTEST_FETCH_START
    });
    const resData = await Service.GET({
      name: 'contest',
      queryString: ''
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: contestActions.CONTEST_FETCH_SUCCESS,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: contestActions.CONTEST_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};

export const saveContest = (formData) => async (dispatch) => {
  try {
    const payload = {
      ...formData,
      OrderNumber: Number(formData.OrderNumber)
    };
    dispatch({
      type: contestActions.CONTEST_SAVE_START
    });
    await Service[formData.Id === '0' ? 'POST' : 'PUT']({
      name: 'contest',
      payload
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(toast.error(res.message));
    });
    dispatch({
      type: contestActions.CONTEST_SAVE_SUCCESS
    });
    toast.success('Saved Successfully');
    setTimeout(() => { window.location.href = '/contest'; }, 1000);
  } catch (error) {
    dispatch({
      type: contestActions.CONTEST_SAVE_FAILED,
      payload: { formError: error.message }
    });
  }
};

export const editContest = (contestId) => async (dispatch, getState) => {
  try {
    const { contestList } = getState().contest;
    const data = contestList.find((item) => item.Id === contestId);
    if (!data) {
      throw new Error('Something went wrong');
    }
    dispatch({
      type: contestActions.CONTEST_SET_FORM,
      payload: { form: data }
    });
  } catch (error) {
    toast.error(error.message);
  }
};

export const resetForm = () => (dispatch) => dispatch({ type: contestActions.CONTEST_RESET_FORM });

export const deleteContest = (Id) => async (dispatch) => Service.DELETE({
  name: 'contest',
  payload: { Id }
}).then((res) => {
  if (res.success) {
    dispatch(fetchContest());
    return true;
  }
  throw new Error(res.message);
});
