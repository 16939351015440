import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../Button';
import {
  InputField, TinyMCEEditor
} from '../ReduxFormComponents';
import { PageValidation } from '../../middlewares/formValidator';

const PageForm = (props) => (
  <form onSubmit={props.handleSubmit}>
    <Field name="Id" component={InputField} type="hidden" className="form-control" />
    <div className="row form-group">
      <div className="col-md-6">
        <label className="text-left">Title</label>
        <div>
          <Field name="Title" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <label className="text-left">Slug URL</label>
        <div>
          <Field name="SlugURL" component={InputField} type="text" className="form-control" />
        </div>
      </div>
    </div>
    <div className="form-group ">
      <label>Content</label>
      <div>

        <Field
          name="Content"
          className="form-control"
          component={TinyMCEEditor}
          minHeight={600}
          inlineEditor
        />
      </div>
    </div>
    <div className="py-2 mt-2">
      <Button
        type="submit"
        className="btn btn-sm btn-success"
        loading={props.formLoading}
        loadingText="Saving..."
        disabled={props.pristine}
      >
        <i className="fas fa-save mr-1" />
        Save
      </Button>
      <Button
        onClick={props.reset}
        className="btn btn-sm btn-outline-secondary ml-2"
        disabled={props.pristine}
      >
        <i className="fas fa-redo mr-1" />
        Reset
      </Button>
    </div>
  </form>
);

PageForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  pristine: PropTypes.bool.isRequired
};

PageForm.defaultProps = {
  formLoading: null,
  formError: null
};

const pageForm = reduxForm({
  form: 'Page Form',
  initialValues: {},
  validate: PageValidation,
  enableReinitialize: false
})(PageForm);

const mapStateToProps = ({ page }) => ({
  initialValues: page.form
});

export default connect(mapStateToProps)(pageForm);
