import React, { Component } from 'react';
import PropTypes from 'prop-types';

class dynamicForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldArray: [{
        id: new Date().getTime(),
        keyName: '',
        value: ''
      }],
    };
  }

  componentDidMount() {
    if (this.props.setValue) {
      this.setState(() => ({ fieldArray: this.props.setValue }));
    }
  }


    incFieldCount = () => {
      const payloadArr = this.state.fieldArray.slice();
      payloadArr.push({
        id: new Date().getTime(),
        keyName: '',
        value: ''
      });
      this.setState(() => ({ fieldArray: payloadArr }));
    };

      decFieldCount = (id) => {
        const selPayloadArr = this.state.fieldArray.slice();
        const delIndex = selPayloadArr.findIndex((i) => i.id === id);
        if (delIndex > -1) {
          selPayloadArr.splice(delIndex, 1);
          this.setState(() => ({
            fieldArray: selPayloadArr
          }), () => this.props.onChange(selPayloadArr));
        }
      }

      handleKeyChange = ({ id }, value) => {
        const selPayloadArr = this.state.fieldArray.slice();
        const delIndex = selPayloadArr.findIndex((i) => i.id === id);
        if (delIndex > -1) {
          selPayloadArr[delIndex].keyName = value;
          this.setState(() => ({
            fieldArray: selPayloadArr
          }), () => this.props.onChange(selPayloadArr));
        }
      }

      handleValueChange = ({ id }, value) => {
        const selPayloadArr = this.state.fieldArray.slice();
        const delIndex = selPayloadArr.findIndex((i) => i.id === id);
        if (delIndex > -1) {
          selPayloadArr[delIndex].value = value;
          this.setState(() => ({
            fieldArray: selPayloadArr
          }), () => this.props.onChange(selPayloadArr));
        }
      }

      render() {
        return (
          <>
            <div className="form-group">
              <label className="col-form-label"><strong>{this.props.fieldName}</strong></label>
            </div>
            {this.state.fieldArray.map((p, index) => (
              <div className="form-group" key={p.id}>
                <div className="row">
                  <div className="col-md-5">
                    <label>
                      {' '}
                      {this.props.keyTitle}
                    </label>
                    <input
                      type="text"
                      value={p.keyName}
                      className="form-control"
                      placeholder="Enter Key"
                      onChange={(e) => this.handleKeyChange(p, e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>
                      {' '}
                      {this.props.valTitle}
                    </label>
                    <input
                      type="text"
                      value={p.value}
                      className="form-control"
                      placeholder="Enter Value"
                      onChange={(e) => this.handleValueChange(p, e.target.value)}
                    />
                  </div>
                  <div className="col-md-1">
                    {
                      index === 0 ? (
                        <div
                          className="row mt-5"
                          onKeyPress={this.incFieldCount}
                          role="link"
                          tabIndex="0"
                          onClick={this.incFieldCount}
                        >
                          <i className="fas fa-plus-circle fa-lg" style={{ cursor: 'pointer' }} />
                        </div>
                      ) : (
                        <div
                          className="row mt-5"
                          onKeyPress={() => this.decFieldCount(p.id)}
                          role="link"
                          tabIndex="0"
                          onClick={() => this.decFieldCount(p.id)}
                        >
                          <i className="fas fa-times-circle fa-lg" style={{ cursor: 'pointer' }} />
                        </div>
                      )
                    }

                  </div>
                </div>
              </div>
            ))}
          </>
        );
      }
}


dynamicForm.propTypes = {
  onChange: PropTypes.func,
  setValue: PropTypes.array
};

dynamicForm.defaultProps = {
  onChange: null,
  setValue: []
};


export default dynamicForm;
