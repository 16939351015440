import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as actions from '../redux/actions/actions-blog';
import Button from '../components/Button';
import SearchBar from '../components/SearchBar';
import BreadCrumb from '../components/Breadcrumb';
import Loader from '../components/Loader';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: ''
    };
    props.getBloglist();
  }


  deleteBlog = (id) => {
    confirmAlert({
      title: 'Delete Blog',
      message: 'Are you sure to delete the blog?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.deleteBlog(id)
              .then(() => {
                toast.success('Deleted Successfully');
              }).catch((error) => {
                toast.error(error.message);
              });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleTableFilter = (search) => {
    this.setState({ searchVal: search });
  }

  setTableOption = () => (this.props.blogList && this.props.tableLoading ? <Loader /> : 'No Data Found');

  render() {
    const searchVal = this.state.searchVal.toLowerCase();
    const blogs = this.props.blogList.filter((item) => item.Title.toLowerCase().includes(searchVal) || item.Category.toLowerCase().includes(searchVal));
    // {
    //   dataField: 'CreatedDateTime',
    //   text: 'Created at',
    //   formatter: (cell, row) => (
    //     cell ? new Date(cell).toLocaleDateString() : '--'
    //   ),
    //   sort: true
    // },
    const columns = [{
      dataField: 'Title',
      text: 'Title'
    }, {
      dataField: 'Category',
      text: 'Category',
    }, {
      dataField: 'SlugURL',
      text: 'Slug URL'
    }, {
      dataField: 'Id',
      text: 'Actions',
      headerStyle: {
        width: '140px'
      },
      formatter: (cell, row) => (
        <>
          <span
            role="presentation"
            className="fa fa-pencil-alt tableEdit"
            onClick={() => this.props.history.push(`/blog-editor/${cell}`)}
          />
          <span
            role="presentation"
            className="fa fa-trash tableDelete"
            onClick={() => this.deleteBlog(cell)}
          />
        </>
      )
    }];


    return (
      <div>
        <div className="container">
          <BreadCrumb currentPage="Blog" />
          <div className="col-md-12 py-3 mb-5">
            <div className="card border-secondary">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    {/* <span className="fa fa-blog cardTitle"> Blog</span> */}
                    <SearchBar
                      placeholder="Search by Title, Category"
                      handleChange={(e) => this.handleTableFilter(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2 text-right">
                    <Button
                      className="btn btn-sm btn-secondary"
                      onClick={() => this.props.history.push('/blog-editor/0')}
                    >
                      <span className="fas fa-plus" />
                      {' '}
                      Add New
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body text-secondary">
                <BootstrapTable
                  keyField="Id"
                  data={blogs}
                  columns={columns}
                  pagination={paginationFactory()}
                  noDataIndication={() => this.setTableOption()}
                  hover
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Home.defaultProps = {
  tableLoading: false,
  blogList: null,
  getBloglist: null,
  deleteBlog: null
};

Home.propTypes = {
  tableLoading: PropTypes.bool,
  blogList: PropTypes.array,
  getBloglist: PropTypes.func,
  deleteBlog: PropTypes.func
};

const mapStateToProps = ({ blog }) => ({
  tableLoading: blog.tableLoading,
  blogList: blog.blogList
});

const mapDispatchToProps = (dispatch) => ({

  getBloglist: () => dispatch(actions.fetchBlog()),
  deleteBlog: (id) => dispatch(actions.deleteBlog(id))

});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
