import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../Button';
import {
  InputField
} from '../ReduxFormComponents';


const InstallmentForm = (props) => (
  <form onSubmit={props.handleSubmit}>
    <Field name="Id" component={InputField} type="hidden" className="form-control" />
    <div className="row form-group">
      <div className="col-md-3">
        <label className="text-left">Title</label>
        <div>
          <Field name="Title" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-3">
        <label className="text-left">Terms</label>
        <div>
          <Field name="Terms" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-3">
        <label className="text-left">Interval Days</label>
        <div>
          <Field name="Interval" component={InputField} type="text" className="form-control" />
        </div>
      </div>
    </div>
    <div className="row form-group">
      <div className="col-md-3">
        <label className="text-left">Additional days</label>
        <div>
          <Field name="AdditionalDays" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-3">
        <label className="text-left">Fine Percentage </label>
        <div>
          <Field name="FinePercentage" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-3">
        <label className="text-left">Interest Rate</label>
        <div>
          <Field name="InterestRate" component={InputField} type="text" className="form-control" />
        </div>
      </div>
    </div>
    <div className="py-2 mt-2">
      <Button
        type="submit"
        className="btn btn-sm btn-success"
        loading={props.formLoading}
        loadingText="Saving..."
        disabled={props.pristine}
      >
        <i className="fas fa-save mr-1" />
        Save
      </Button>
      <Button
        onClick={props.reset}
        className="btn btn-sm btn-outline-secondary ml-2"
        disabled={props.pristine}
      >
        <i className="fas fa-redo mr-1" />
        Reset
      </Button>
    </div>
  </form>
);

InstallmentForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  pristine: PropTypes.bool.isRequired
};

InstallmentForm.defaultProps = {
  formLoading: null,
  formError: null
};

const installmentForm = reduxForm({
  form: 'installment Form',
  initialValues: {},
  enableReinitialize: false
})(InstallmentForm);

const mapStateToProps = ({ installment }) => ({
  initialValues: installment.form
});

export default connect(mapStateToProps)(installmentForm);
