import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../Button';
import {
  InputField, TinyMCEEditor, SwitchToggle
} from '../ReduxFormComponents';
import { EmailTemplateValidation } from '../../middlewares/formValidator';

const EmailTemplateForm = (props) => (
  <form onSubmit={props.handleSubmit}>
    <Field name="Id" component={InputField} type="hidden" className="form-control" />
    <div className="row form-group">
      <div className="col-md-6">
        <label className="text-left">Template Name</label>
        <div>
          <Field name="templateName" component={InputField} disabled={props.disabled} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <label className="text-left">Subject Part</label>
        <div>
          <Field name="subjectPart" component={InputField} type="text" className="form-control" />
        </div>
      </div>
    </div>

    <div className="row form-group">
      <div className="col-md-6">
        <label className="text-left">Text Part</label>
        <div>
          <Field name="textPart" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <label className="text-left">Is Deletable</label>
        <div>
          <Field name="isDeletable" component={SwitchToggle} type="text" className="form-control" />
        </div>
      </div>
    </div>

    <div className="form-group ">
      <label>Html Part</label>
      <div>
        <Field
          name="htmlPart"
          className="form-control"
          component={TinyMCEEditor}
          minHeight={400}
          inlineEditor={false}
        />
      </div>
    </div>

    <div className="py-2 mt-2">
      <Button
        type="submit"
        className="btn btn-sm btn-success"
        loading={props.formLoading}
        loadingText="Saving..."
        disabled={props.pristine}
      >
        <i className="fas fa-save mr-1" />
        Save
      </Button>
      <Button
        onClick={props.reset}
        className="btn btn-sm btn-outline-secondary ml-2"
        disabled={props.pristine}
      >
        <i className="fas fa-redo mr-1" />
        Reset
      </Button>
    </div>
  </form>
);

EmailTemplateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired
};

EmailTemplateForm.defaultProps = {
  formLoading: null,
  formError: null
};

const emailTemplateForm = reduxForm({
  form: 'EmailTemplateForm',
  validate: EmailTemplateValidation,
  initialValues: {},
  enableReinitialize: false
})(EmailTemplateForm);

const mapStateToProps = ({ emailTemplate }) => ({
  initialValues: {
    ...emailTemplate.form,
    Id: emailTemplate.form.templateName || '0'
  },
  disabled: !!emailTemplate.form.templateName
});

export default connect(mapStateToProps)(emailTemplateForm);
