import { batchActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utils';

const initialFormData = {
  Id: '0',
  Name: '',
  Code: '',
  StartDate: '',
  EndDate: '',
  Mode: '',
  Duration: '',
  AdmissionDeadline: '',
  Coupons: '',
  ThumbnailURL: '',
  Installment: '',
  Courses: ''
};

const defaultState = {
  batchList: [],
  branchList: [],
  coursesList: [],
  installmentList: [],
  tableLoading: false,
  form: initialFormData,
  formLoading: false,
  formError: null,
  deleteId: null
};


export default (state = defaultState, actions) => {
  switch (actions.type) {
    case batchActions.BATCH_FETCH_START:
      return updateState(state, { tableLoading: true });
    case batchActions.BATCH_FETCH_SUCCESS:
      return updateState(state, { tableLoading: false, batchList: actions.payload });
    case batchActions.BATCH_FETCH_ERROR:
      return updateState(state, { tableLoading: false, ...actions.payload });
    case batchActions.BATCH_BRANCH_FETCH_START:
      return updateState(state, { formLoading: true });
    case batchActions.BATCH_BRANCH_FETCH_SUCCESS:
      return updateState(state, { formLoading: false, branchList: actions.payload });
    case batchActions.BATCH_BRANCH_FETCH_ERROR:
      return updateState(state, { formLoading: false, ...actions.payload });
    case batchActions.BATCH_COURSES_FETCH_START:
      return updateState(state, { formLoading: true });
    case batchActions.BATCH_COURSES_FETCH_SUCCESS:
      return updateState(state, { formLoading: false, coursesList: actions.payload });
    case batchActions.BATCH_COURSES_FETCH_ERROR:
      return updateState(state, { formLoading: false, ...actions.payload });
    case batchActions.BATCH_INSTALLMENT_FETCH_START:
      return updateState(state, { formLoading: true });
    case batchActions.BATCH_INSTALLMENT_FETCH_SUCCESS:
      return updateState(state, { formLoading: false, installmentList: actions.payload });
    case batchActions.BATCH_INSTALLMENT_FETCH_ERROR:
      return updateState(state, { formLoading: false, ...actions.payload });
    case batchActions.BATCH_SAVE_START:
      return updateState(state, { formLoading: true, formError: null, form: initialFormData });
    case batchActions.BATCH_SAVE_SUCCESS:
      return updateState(state, { formLoading: false, form: initialFormData });
    case batchActions.BATCH_SAVE_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    case batchActions.BATCH_SET_FORM:
      return updateState(state, { ...actions.payload, formError: null });
    case batchActions.BATCH_RESET_FORM:
      return updateState(state, { form: initialFormData });
    default:
      return state;
  }
};
