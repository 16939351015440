import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Button from '../Button';
import {
  InputField, SelectField, TextArea
} from '../ReduxFormComponents';
import { StatusValidation } from '../../middlewares/formValidator';

import Loader from '../Loader';

const StatusForm = (props) => {
  const statusData = props.currentLead.LeadLogs ? props.currentLead.LeadLogs : [];

  const setTableOption = () => (statusData.length ? <Loader /> : 'No Data Found');

  const columns = [{
    dataField: 'assignedTo',
    text: 'Assigned To',
    formatter: (cell, row) => {
      const assignTo = props.adminData.find((p) => p.Id === cell);
      return assignTo ? assignTo.AdminName || '--' : '--';
    },
  },
  {
    dataField: 'status',
    text: 'Status'
  },
  {
    dataField: 'remarks',
    text: 'Remarks'
  },
  {
    dataField: 'assignedBy',
    text: 'Assigned By',
    formatter: (cell, row) => {
      const assignTo = props.adminData.find((p) => p.Id === cell);
      return assignTo ? assignTo.AdminName || '--' : '--';
    },
  },
  {
    dataField: 'followUpDate',
    text: 'FollowUp Date',
    formatter: (cell, row) => (cell ? new Date(cell).toLocaleDateString() : '--'),
  },
  {
    dataField: 'createdDateTime',
    text: 'Created at',
    formatter: (cell, row) => (
      new Date(cell).toLocaleDateString()
    ),
    sort: true
  }];

  const defaultSorted = [{
    dataField: 'createdDateTime',
    order: 'desc'
  }];


  return (
    <>
      <form onSubmit={props.handleSubmit}>
        <Field name="leadId" component={InputField} type="hidden" className="form-control" />
        <Field name="formType" component={InputField} type="hidden" className="form-control" />
        <div className="row form-group">
          <div className="col-md-3">
            <label className="text-left">Status</label>
            <div>
              <Field name="status" id="Status" component={SelectField} className="form-control">
                <option value="">Select Status</option>
                <option key="new" value="new">New</option>
                <option key="hot" value="hot">Hot</option>
                <option key="warm" value="warm">Warm</option>
                <option key="cold" value="cold">Cold</option>
                <option key="junk" value="junk">Junk</option>
              </Field>
            </div>
          </div>
          <div className="col-md-3">
            <label className="text-left">Assigned To</label>
            <div>
              <Field name="assignedTo" id="assignedTo" component={SelectField} className="form-control">
                <option value="">Select Team</option>
                {props.adminData && props.adminData.map((item) => <option key={item.Id} value={item.Id}>{item.AdminName}</option>)}
              </Field>
            </div>
          </div>
          <div className="col-md-3">
            <label className="text-left">FollowUp Data</label>
            <div>
              <Field type="date" component={InputField} name="followUpDate" className="form-control" />
              {/* <input type="date" name="followupDate" className="form-control" /> */}
              <small>(Leave blank if you don’t want to create a follow-up)</small>
            </div>
          </div>
          <div className="col-md-3">
            <label className="text-left">Remarks</label>
            <div>
              <Field name="remarks" component={TextArea} type="text" className="form-control" />
            </div>
          </div>
        </div>

        <div className="py-2 mt-2">
          <Button
            type="submit"
            className="btn btn-sm btn-success"
            loading={props.formLoading}
            loadingText="Saving..."
            disabled={props.pristine}
          >
            <i className="fas fa-save mr-1" />
            Save
          </Button>
          <Button
            onClick={props.reset}
            className="btn btn-sm btn-outline-secondary ml-2"
            disabled={props.pristine}
          >
            <i className="fas fa-redo mr-1" />
            Reset
          </Button>
        </div>
      </form>

      <div className="mt-3">
        <BootstrapTable
          keyField="id"
          data={statusData}
          columns={columns}
          pagination={paginationFactory()}
          defaultSorted={defaultSorted}
          noDataIndication={() => setTableOption()}
          hover
        />
      </div>

    </>
  );
};

StatusForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  pristine: PropTypes.bool.isRequired,
  adminData: PropTypes.array,
  currentLead: PropTypes.object
};

StatusForm.defaultProps = {
  formLoading: null,
  adminData: [],
  currentLead: {}
};

const statusForm = reduxForm({
  form: 'Status Form',
  validate: StatusValidation,
  initialValues: {},
  enableReinitialize: false
})(StatusForm);

const mapStateToProps = ({ leads }) => ({
  currentLead: leads.form,
  adminData: leads.adminsList,
  initialValues: { ...leads.statusForm, leadId: leads.form.Id, formType: 'status' },
  statusData: leads.statusList,
  tableStatusLoading: leads.tableStatusLoading
});


export default connect(mapStateToProps)(statusForm);
