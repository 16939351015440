import React from 'react';
import { Route, Switch } from 'react-router-dom';
import WithLayout from '../containers/Layout';

import Home from '../containers/Home';
import AddBlog from '../containers/addBlog';
import Heading from '../containers/Heading';
import Courses from '../containers/Courses/Courses';
import AddCourses from '../containers/Courses/addCourses';
import Webinar from '../containers/Webinar/Webinar';
import AddWebinar from '../containers/Webinar/addWebinar';
import Contest from '../containers/Contest/Contest';
import AddContest from '../containers/Contest/addContest';
import Guide from '../containers/Guide/Guide';
import AddGuide from '../containers/Guide/addGuide';
import CoursePage from '../containers/CoursePage/CoursePage';
import AddCoursePage from '../containers/CoursePage/addCoursePage';
import Page from '../containers/Page/Page';
import AddPage from '../containers/Page/addPage';
import Leads from '../containers/Leads/Leads';
import AddLeads from '../containers/Leads/addLeads';
import LearningModule from '../containers/LearningModule/LearningModule';
import AddLearningModule from '../containers/LearningModule/addLearningModule';
import LearningObject from '../containers/LearningObject/LearningObject';
import AddLearningObject from '../containers/LearningObject/addLearningObject';
import Branch from '../containers/Branch/Branch';
import AddBranch from '../containers/Branch/addBranch';
import Batch from '../containers/Batch/Batch';
import AddBatch from '../containers/Batch/addBatch';
import Testimonial from '../containers/Testimonial/Testimonial';
import AddTestimonial from '../containers/Testimonial/addTestimonial';
import EmailTemplate from '../containers/EmailTemplate/EmailTemplate';
import AddEmailTemplate from '../containers/EmailTemplate/addEmailTemplate';
import Transaction from '../containers/Transaction/Transaction';
import Users from '../containers/Users/Users';
import AddUsers from '../containers/Users/addUsers';
import Installment from '../containers/Installment/Installment';
import AddInstallment from '../containers/Installment/addInstallment';
import Admins from '../containers/Admins/Admins';
import AddAdmins from '../containers/Admins/AddAdmins';
import Shop from '../containers/Shop';
import About from '../containers/About';
import AdminRoles from '../containers/AdminRoles/AdminRole';
import AdminRoleMapping from '../containers/AdminRoles/AdminRoleMapping';

const AppRoutes = () => (
  <>
    <Heading />
    <Switch>
      <Route path="/" exact component={WithLayout(Home)} />
      <Route path="/blog" exact component={WithLayout(Home)} />
      <Route path="/blog-editor/:id" exact component={WithLayout(AddBlog)} />
      <Route path="/courses" exact component={WithLayout(Courses)} />
      <Route path="/courses-editor/:id" exact component={WithLayout(AddCourses)} />
      <Route path="/webinar" exact component={WithLayout(Webinar)} />
      <Route path="/webinar-editor/:id" exact component={WithLayout(AddWebinar)} />
      <Route path="/contest" exact component={WithLayout(Contest)} />
      <Route path="/contest-editor/:id" exact component={WithLayout(AddContest)} />
      <Route path="/guide" exact component={WithLayout(Guide)} />
      <Route path="/guide-editor/:id" exact component={WithLayout(AddGuide)} />
      <Route path="/course-page" exact component={WithLayout(CoursePage)} />
      <Route path="/course-page-editor/:id" exact component={WithLayout(AddCoursePage)} />
      <Route path="/page" exact component={WithLayout(Page)} />
      <Route path="/page-editor/:id" exact component={WithLayout(AddPage)} />
      <Route path="/leads" exact component={WithLayout(Leads)} />
      <Route path="/leads-editor/:id" exact component={WithLayout(AddLeads)} />
      <Route path="/courses/learning-module/:id" exact component={WithLayout(LearningModule)} />
      <Route path="/courses/learning-module-editor/:id" exact component={WithLayout(AddLearningModule)} />
      <Route path="/courses/learning-object/:id" exact component={WithLayout(LearningObject)} />
      <Route path="/courses/learning-object-editor/:id" exact component={WithLayout(AddLearningObject)} />
      <Route path="/branch" exact component={WithLayout(Branch)} />
      <Route path="/branch-editor/:id" exact component={WithLayout(AddBranch)} />
      <Route path="/batch" exact component={WithLayout(Batch)} />
      <Route path="/batch-editor/:id" exact component={WithLayout(AddBatch)} />
      <Route path="/testimonial" exact component={WithLayout(Testimonial)} />
      <Route path="/testimonial-editor/:id" exact component={WithLayout(AddTestimonial)} />
      <Route path="/email-template" exact component={WithLayout(EmailTemplate)} />
      <Route path="/email-template-editor/:id" exact component={WithLayout(AddEmailTemplate)} />
      <Route path="/users" exact component={WithLayout(Users)} />
      <Route path="/users-editor/:id" exact component={WithLayout(AddUsers)} />
      <Route path="/admins" exact component={WithLayout(Admins)} />
      <Route path="/admins-editor/:id" exact component={WithLayout(AddAdmins)} />
      <Route path="/installment" exact component={WithLayout(Installment)} />
      <Route path="/installment-editor/:id" exact component={WithLayout(AddInstallment)} />
      <Route path="/transaction" exact component={WithLayout(Transaction)} />
      <Route path="/shop" component={WithLayout(Shop)} />
      <Route path="/about" component={WithLayout(About)} />
      <Route path="/admin-roles" component={WithLayout(AdminRoles)} />
      <Route path="/adminrole/:id" component={WithLayout(AdminRoleMapping)} />
    </Switch>
  </>
);

export default AppRoutes;
