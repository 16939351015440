import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as actions from '../../redux/actions/actions-leads';
import Button from '../../components/Button';
import SearchBar from '../../components/SearchBar';
import BreadCrumb from '../../components/Breadcrumb';
import Loader from '../../components/Loader';

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: ''
    };
    props.getLeadslist();
  }

  deleteLeads = (id) => {
    confirmAlert({
      title: 'Delete Leads',
      message: 'Are you sure to delete the Leads?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.deleteLeads(id)
              .then(() => {
                toast.success('Deleted Successfully');
              }).catch((error) => {
                toast.error(error.message);
              });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleTableFilter = (search) => {
    this.setState({ searchVal: search });
  }


  setTableOption = () => (this.props.LeadsData && this.props.tableLoading ? <Loader /> : 'No Data Found');

  render() {
    const searchVal = this.state.searchVal.toLowerCase();
    const leads = this.props.LeadsData.filter((item) => (item.Name && item.Name.toLowerCase().includes(searchVal)) || (item.EmailID && item.EmailID.toLowerCase().includes(searchVal)) || (item.MobileNumber && item.MobileNumber.includes(searchVal)) || (item.Source && item.Source.toLowerCase().includes(searchVal)));

    const columns = [{
      dataField: 'Name',
      text: 'Leads Name'
    }, {
      dataField: 'EmailID',
      text: 'EmailId'
    }, {
      dataField: 'MobileNumber',
      text: 'Mobile No',
      formatter: (cell) => (cell || '--')
    }, {
      dataField: 'Country',
      text: 'Country',
      formatter: (cell) => (cell || '--')
    }, {
      dataField: 'Source',
      text: 'Source',
      formatter: (cell) => (cell || '--')
    }, {
      dataField: 'CreatedDateTime',
      text: 'Created at',
      formatter: (cell) => new Date(cell).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }), // return new Date(cDate).toISOString();

      sort: true
    }, {
      dataField: 'Id',
      text: 'Actions',
      formatter: (cell, row) => (
        <>
          <span
            role="presentation"
            className="fa fa-pencil-alt tableEdit"
            title="Edit"
            data-tooltip
            onClick={() => this.props.history.push(`/leads-editor/${cell}`)}
          />
          <span
            role="presentation"
            title="Delete"
            data-tooltip
            className="fa fa-trash tableDelete"
            onClick={() => this.deleteLeads(cell)}
          />
        </>
      )
    }];

    const defaultSorted = [{
      dataField: 'CreatedDateTime',
      order: 'desc'
    }];

    return (
      <div>
        <div className="container">
          <BreadCrumb currentPage="Leads" />
          <div className="col-md-12 py-3 mb-5">
            <div className="card border-secondary">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    {/* <span className="fa fa-users cardTitle"> Leads</span> */}
                    <SearchBar
                      placeholder="Search by Name, EmailID, MobileNumber,Source"
                      handleChange={(e) => this.handleTableFilter(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2 text-right">
                    <Button
                      className="btn btn-sm btn-secondary ml-0"
                      onClick={() => this.props.history.push('/leads-editor/0')}
                    >
                      <span className="fas fa-plus" />
                      {' '}
                      Add New
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body text-secondary">
                <BootstrapTable
                  keyField="Id"
                  data={leads}
                  columns={columns}
                  pagination={paginationFactory()}
                  defaultSorted={defaultSorted}
                  noDataIndication={() => this.setTableOption()}
                  hover
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Leads.defaultProps = {
  tableLoading: false,
  LeadsData: null,
  getLeadslist: null,
  deleteLeads: null
};

Leads.propTypes = {
  tableLoading: PropTypes.bool,
  LeadsData: PropTypes.array,
  getLeadslist: PropTypes.func,
  deleteLeads: PropTypes.func
};

const mapStateToProps = ({ leads }) => ({
  tableLoading: leads.tableLoading,
  LeadsData: leads.leadList
});

const mapDispatchToProps = (dispatch) => ({

  getLeadslist: () => dispatch(actions.fetchLeads()),
  deleteLeads: (id) => dispatch(actions.deleteLeads(id))

});

export default connect(mapStateToProps, mapDispatchToProps)(Leads);
