import { pageActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utils';

const initialFormData = {
  Id: '0',
  Title: '',
  Content: '',
  SlugURL: ''
};

const defaultState = {
  pageList: [],
  tableLoading: false,
  form: initialFormData,
  formLoading: false,
  formError: null,
  deleteId: null
};


export default (state = defaultState, actions) => {
  switch (actions.type) {
    case pageActions.PAGE_FETCH_START:
      return updateState(state, { tableLoading: true });
    case pageActions.PAGE_FETCH_SUCCESS:
      return updateState(state, { tableLoading: false, pageList: actions.payload });
    case pageActions.PAGE_FETCH_ERROR:
      return updateState(state, { tableLoading: false, ...actions.payload });
    case pageActions.PAGE_SAVE_START:
      return updateState(state, { formLoading: true, formError: null, form: initialFormData });
    case pageActions.PAGE_SAVE_SUCCESS:
      return updateState(state, { formLoading: false, form: initialFormData });
    case pageActions.PAGE_SAVE_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    case pageActions.PAGE_SET_FORM:
      return updateState(state, { ...actions.payload, formError: null });
    case pageActions.PAGE_RESET_FORM:
      return updateState(state, { form: initialFormData });
    default:
      return state;
  }
};
