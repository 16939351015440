import { blogActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utils';

const initialFormData = {
  Id: '0',
  Title: '',
  Category: '',
  SlugURL: '',
  FeaturedImageURL: '',
  Excerpt: '',
  PostedDate: '',
  OrderNumber: '',
  Content: ''
};

const defaultState = {
  blogList: [],
  tableLoading: false,
  form: initialFormData,
  formLoading: false,
  formError: null,
  deleteId: null
};


export default (state = defaultState, actions) => {
  switch (actions.type) {
    case blogActions.BLOG_FETCH_START:
      return updateState(state, { tableLoading: true });
    case blogActions.BLOG_FETCH_SUCCESS:
      return updateState(state, { tableLoading: false, blogList: actions.payload });
    case blogActions.BLOG_FETCH_ERROR:
      return updateState(state, { tableLoading: false, ...actions.payload });
    case blogActions.BLOG_SAVE_START:
      return updateState(state, { formLoading: true, formError: null, form: initialFormData });
    case blogActions.BLOG_SAVE_SUCCESS:
      return updateState(state, { formLoading: false, form: initialFormData });
    case blogActions.BLOG_SAVE_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    case blogActions.BLOG_SET_FORM:
      return updateState(state, { ...actions.payload, formError: null });
    case blogActions.BLOG_RESET_FORM:
      return updateState(state, { form: initialFormData });
    default:
      return state;
  }
};
