import { authActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utils';


const defaultState = {
  token: '',
  formLoading: false,
  loading: false,
  error: null
};

export default (state = defaultState, actions) => {
  switch (actions.type) {
    case authActions.AUTH_LOGIN_START:
      return updateState(state, { formLoading: true, error: null });
    case authActions.AUTH_LOGIN_SUCCESS:
      return updateState(state, { formLoading: false, ...actions.payload });
    case authActions.AUTH_LOGIN_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    case authActions.AUTH_VALIDATE_TOKEN:
      return updateState(state, { loading: false, ...actions.payload });
    case authActions.AUTH_VALIDATE_TOKEN_FAILED:
      return updateState(state, { ...defaultState, loading: false });
    default: return state;
  }
};
