import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import '../../styles/Login.scss';
import { InputField } from '../../components/ReduxFormComponents';
import * as actions from '../../redux/actions/actions-login';
import Logo from '../../assets/images/crampete.png';

const LoginFormWrapper = (props) => (
  <form onSubmit={props.handleSubmit}>
    <label className="textTitle">Username</label>
    <Field type="text" component={InputField} name="LoginId" className="textField" />
    <label className="textTitle">Password</label>
    <Field type="password" component={InputField} name="Password" className="textField" />
    <br />
    <button
      type="submit"
      className="loginSubmitBtn"
      disabled={props.loading}
    >
      {props.loading ? 'Loading...' : 'Login' }
    </button>
  </form>
);

LoginFormWrapper.defaultProps = {
  handleSubmit: '',
  loading: false
};
LoginFormWrapper.propTypes = {
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool
};

const LoginReduxForm = reduxForm({
  form: 'Login Form',
  enableReinitialize: false
})(LoginFormWrapper);


const Login = (props) => (
  <>
    <div className="loginbox">
      <img src={Logo} alt="logo" className="avatar" />
      <span className="loginHead">Login Here</span>
      <br />
      <LoginReduxForm
        onSubmit={(values) => props.LoginSubmit(values)}
        loading={props.formloading}
      />
    </div>
  </>
);

Login.defaultProps = {
  LoginSubmit: '',
  formloading: false
};
Login.propTypes = {
  LoginSubmit: PropTypes.func,
  formloading: PropTypes.bool
};

const mapStateToProps = ({ auth }) => ({
  formloading: auth.formLoading,
  error: auth.error
});

const mapDispatchToProps = (dispatch) => ({
  LoginSubmit: (FormData) => dispatch(actions.loginSubmit(FormData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
