import React from 'react';
import PropTypes from 'prop-types';

const SearchBar = (props) => (
  <div>
    <input
      type="text"
      placeholder={props.placeholder}
      className="form-control"
      onChange={props.handleChange}
    />
  </div>
);

SearchBar.defaultProps = {
  handleChange: null
};
SearchBar.propTypes = {
  handleChange: PropTypes.func,
  placeholder: PropTypes.any.isRequired
};
export default SearchBar;
