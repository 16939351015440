import { transActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utils';

const defaultState = {
  transactionList: [],
  tableLoading: false
};

export default (state = defaultState, actions) => {
  switch (actions.type) {
    case transActions.TRANSACTION_FETCH_START:
      return updateState(state, { tableLoading: true });
    case transActions.TRANSACTION_FETCH_SUCCESS:
      return updateState(state, { tableLoading: false, transactionList: actions.payload });
    case transActions.TRANSACTION_FETCH_FAILED:
      return updateState(state, { tableLoading: false, ...actions.payload });
    default:
      return state;
  }
};
