import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class NavLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const isActive = this.props.to !== '/' ? window.location.pathname.includes(this.props.to) : false;
    const className = isActive ? 'active' : '';

    return (
      <li className={`nav-item ${className}`}>
        <Link {...this.props}>
          {this.props.children}
        </Link>
      </li>
    );
  }
}

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

export default NavLink;
