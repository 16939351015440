import queryString from 'query-string';
import { toast } from 'react-toastify';
import Service from '../../lib/Service';
import * as Cookies from '../../lib/Cookie';
import { authActions } from '../ActionTypes';

const storeAuthCookies = (data) => {
  Cookies.set('cadmin_token', data.token, 1);
};


export const loginSubmit = (formData) => async (dispatch) => {
  dispatch({
    type: authActions.AUTH_LOGIN_START
  });
  try {
    const payload = {
      loginId: formData.LoginId.trim(),
      password: formData.Password
    };
    const data = await Service.POST({
      name: '/login',
      payload
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(toast.error(res.message));
    });
    storeAuthCookies(data);
    dispatch({
      type: authActions.AUTH_LOGIN_SUCCESS,
      payload: data
    });
  } catch (err) {
    dispatch({
      type: authActions.AUTH_LOGIN_FAILED,
      payload: { error: err }
    });
  }
};

export const validateToken = () => async (dispatch) => {
  try {
    const token = Cookies.get('cadmin_token');
    const resData = await Service.GET({
      name: 'validate-auth-token',
      queryString: queryString.stringify({ token })
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    storeAuthCookies(resData);
    dispatch({
      type: authActions.AUTH_VALIDATE_TOKEN,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: authActions.AUTH_VALIDATE_TOKEN_FAILED
    });
  }
};


export const logout = () => async (dispatch, getState) => {
  await Cookies.eraseAllSync();
  window.location = '/login';
};
