import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import {
  SelectField, InputField, SwitchToggle
} from '../ReduxFormComponents';
import Button from '../Button';
import { validateAdminRoleMappingform } from '../../middlewares/formValidator';

class Form extends Component {
  state = {
    RelativeMenus: []
  }

  componentDidMount() {
    if (this.props.initialValues.Id !== '0') {
      this.props.initialize(this.props.initialValues);
      const selectedMenuDetails = this.props.menuList.find((i) => i.Id === this.props.initialValues.MenuId);
      this.setState({
        RelativeMenus: (selectedMenuDetails && selectedMenuDetails.RelativeMenus) || []
      });
    }
  }

  handlePageSelect = (id) => {
    const selectedMenuDetails = this.props.menuList.find((i) => i.Id === id);
    this.setState({
      RelativeMenus: selectedMenuDetails ? selectedMenuDetails.RelativeMenus : []
    });
    this.props.change('MenuId', id);
    this.props.change('MenuName', selectedMenuDetails.MenuName);
    this.props.change('Icon', selectedMenuDetails.Icon || '');
    this.props.change('RelativeMenus', {});
  }

  render() {
    const {
      handleSubmit, pristine, menuList, formLoading, reset
    } = this.props;
    return (
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 col-md-6">
            <Field type="hidden" name="Id" component="input" />
            <div className="form-group">
              <label>Order No</label>
              <Field name="OrderNumber" component={InputField} type="number" className="form-control no-arrows" placeholder="Enter Order Number" />
            </div>
            <div className="form-group">
              <label>Select Page</label>
              <Field
                name="MenuId"
                className="form-control"
                component={SelectField}
                onChange={(e) => this.handlePageSelect(e.target.value)}
              >
                <option value="">Select Page</option>
                {menuList
                  .sort((a, b) => {
                    if (a.MenuName < b.MenuName) { return -1; }
                    if (a.MenuName > b.MenuName) { return 1; }
                    return 0;
                  })
                  .map((menu) => (
                    <option key={menu.Id} value={menu.Id}>{menu.MenuName}</option>
                  ))}
              </Field>
            </div>
          </div>
          <div className="col-12 col-md-6">
            {!!this.state.RelativeMenus.length && (
              <>
                <small className="text-muted">Relative Menus</small>
                <p>Select the role access below for the selected Page</p>
                {this.state.RelativeMenus.map((menu) => (
                  <div key={menu.Component} className="form-group">
                    <Field
                      name={`RelativeMenus.${menu.Component}`}
                      label={menu.MenuName}
                      component={SwitchToggle}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <hr />
        <div className="form-group">
          <Button
            type="submit"
            className="btn btn-sm btn-success mr-2"
            loading={formLoading}
            loadingText="Saving..."
            disabled={pristine}
          >
            <i className="fa fa-save" />
            &nbsp;Save
          </Button>
          <Button className="btn btn-sm btn-outline-primary" onClick={reset} disabled={pristine}>
            <i className="fa fa-redo" />
            &nbsp;Reset
          </Button>
        </div>
      </form>
    );
  }
}

Form.defaultProps = {
  menuList: [],
  formLoading: false,
  formError: null,
};

Form.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  menuList: PropTypes.array,
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  reset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  initialize: PropTypes.func.isRequired
};

const RoleForm = reduxForm({
  form: 'RoleForm',
  enableReinitialize: true,
  initialValues: {
    MenuName: '',
    MenuId: '',
    OrderNumber: '',
    RelativeMenus: null,
    Icon: '',
    Id: '0'
  },
  validate: validateAdminRoleMappingform
})(Form);

const mapStateToProps = ({ adminRoles }) => ({
  formLoading: adminRoles.formLoading,
  formError: adminRoles.formError
});

export default connect(mapStateToProps, null)(RoleForm);
