import React, { Component } from 'react';
import NavLink from './NavLink';

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <nav className="col-md-2 bg-light pl-0">
          <div className="sidebar-sticky sidebar">
            <ul className="navbar-nav mr-auto">
              <NavLink to="/" className="nav-link">
                <span className="fa fa-home faIcon" />
                Home
              </NavLink>
              <NavLink to="/admins" className="nav-link">
                <span className="fa fa-users faIcon" />
                Admins
              </NavLink>
              <NavLink to="/admin-roles" className="nav-link">
                <span className="fa fa-key faIcon" />
                Admin Roles
              </NavLink>
              <NavLink to="/blog" className="nav-link">
                <span className="fa fa-blog faIcon" />
                Blog
              </NavLink>
              <NavLink to="/courses" className="nav-link">
                <span className="fa fa-graduation-cap faIcon" />
                Courses
              </NavLink>
              <NavLink to="/course-page" className="nav-link">
                <span className="fa fa-layer-group faIcon" />
                Course Page
              </NavLink>
              <NavLink to="/leads" className="nav-link">
                <span className="fa fa-address-card faIcon" />
                Leads
              </NavLink>
              <NavLink to="/users" className="nav-link">
                <span className="fa fa-users faIcon" />
                Users
              </NavLink>
              <NavLink to="/branch" className="nav-link">
                <span className="fa fa-cubes faIcon" />
                Branch
              </NavLink>
              <NavLink to="/batch" className="nav-link">
                <span className="fa fa-calendar-alt faIcon" />
                Batch
              </NavLink>
              <NavLink to="/webinar" className="nav-link">
                <span className="fa fa-laptop-code faIcon" />
                Webinar
              </NavLink>
              <NavLink to="/contest" className="nav-link">
                <span className="fa fa-trophy faIcon" />
                Contest
              </NavLink>
              <NavLink to="/guide" className="nav-link">
                <span className="fa fa-book-reader faIcon" />
                Guide
              </NavLink>
              <NavLink to="/testimonial" className="nav-link">
                <span className="fa fa-quote-left faIcon" />
                Testimonial
              </NavLink>
              <NavLink to="/page" className="nav-link">
                <span className="fa fa-file-alt faIcon" />
                Page
              </NavLink>
              <NavLink to="/email-template" className="nav-link">
                <span className="fa fa-paper-plane faIcon" />
                Email Template
              </NavLink>
              <NavLink to="/transaction" className="nav-link">
                <span className="fa fa-exchange-alt faIcon" />
                Transaction
              </NavLink>
              <NavLink to="/installment" className="nav-link">
                <span className="fa fa-file-invoice-dollar faIcon" />
                Installment
              </NavLink>
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default SideBar;
