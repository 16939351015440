import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../Button';
import {
  InputField, TinyMCEEditor, RepeaterTwoFields
} from '../ReduxFormComponents';
import { BlogValidation } from '../../middlewares/formValidator';

const BlogForm = (props) => (
  <form onSubmit={props.handleSubmit}>
    <Field name="Id" component={InputField} type="hidden" className="form-control" />
    <div className="row form-group">
      <div className="col-md-6">
        <label className="text-left">Title</label>
        <div>
          <Field name="Title" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <label className="text-left">Category</label>
        <div>
          <Field name="Category" component={InputField} type="text" className="form-control" />
        </div>
      </div>
    </div>

    <div className="row form-group">
      <div className="col-md-4">
        <label className="text-left">Slug URL</label>
        <div>
          <Field name="SlugURL" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-4">
        <label className="text-left">Featured Image URL</label>
        <div>
          <Field name="FeaturedImageURL" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-4">
        <label>Posted Date</label>
        <div>
          <Field name="PostedDate" type="text" component={InputField} className="form-control" />
        </div>
      </div>
    </div>
    <div className="row form-group mt-2">
      <div className="col-md-6">
        <label className="text-left">OrderNumber</label>
        <div>
          <Field name="OrderNumber" component={InputField} type="text" className="form-control" />
        </div>
      </div>
    </div>
    <div className="form-group ">
      <label>Excerpt</label>
      <div>
        <Field
          name="Excerpt"
          className="form-control"
          component={TinyMCEEditor}
          minHeight={600}
          inlineEditor
        />
      </div>
    </div>
    <div>
      <label>Contents</label>
      <div>
        <Field
          name="Content"
          className=""
          component={TinyMCEEditor}
          minHeight={600}
          inlineEditor
        />
      </div>
    </div>

    <div className="form-group mt-3">
      <label>SEO</label>
      <div>
        <Field
          name="SEO"
          className="form-control"
          component={RepeaterTwoFields}
          handleRepeaterChange={(repData) => props.repeaterChange(repData)}
          dataKey="Key"
          dataField="Value"
          setValue={props.seoValue}
        />
      </div>
    </div>

    <div className="py-2 mt-2">
      <Button
        type="submit"
        className="btn btn-sm btn-success"
        loading={props.formLoading}
        loadingText="Saving..."
        disabled={props.pristine}
      >
        <i className="fas fa-save mr-1" />
        Save
      </Button>
      <Button
        onClick={props.reset}
        className="btn btn-sm btn-outline-secondary ml-2"
        disabled={props.pristine}
      >
        <i className="fas fa-redo mr-1" />
        Reset
      </Button>
    </div>
  </form>
);

BlogForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  pristine: PropTypes.bool.isRequired,
  seoValue: PropTypes.array,
  repeaterChange: PropTypes.func.isRequired
};

BlogForm.defaultProps = {
  formLoading: null,
  formError: null,
  seoValue: []
};

const blogForm = reduxForm({
  form: 'BlogForm',
  initialValues: {},
  validate: BlogValidation,
  enableReinitialize: false
})(BlogForm);

const mapStateToProps = ({ blog }) => ({
  initialValues: blog.form,
  seoValue: blog.form.SEO
});

export default connect(mapStateToProps)(blogForm);
