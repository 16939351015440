import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../Button';
import {
  InputField, SwitchToggle
} from '../ReduxFormComponents';
import { AdminsValidation } from '../../middlewares/formValidator';


const AdminsForm = (props) => (
  <form onSubmit={props.handleSubmit}>
    <Field name="Id" component={InputField} type="hidden" className="form-control" />
    <div className="row form-group">
      <div className="col-md-6">
        <label className="text-left">Admin Name</label>
        <div>
          <Field name="AdminName" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-4">
        <label className="text-left">Mobile Number</label>
        <div>
          <Field name="MobileNumber" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-2">
        <label className="text-left">Is Active</label>
        <div>
          <Field name="IsActive" component={SwitchToggle} type="text" className="form-control" />
        </div>
      </div>
    </div>

    <div className="row form-group">
      <div className="col-md-4">
        <label className="text-left">Email Id</label>
        <div>
          <Field name="EmailId" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-4">
        <label className="text-left">Login Id</label>
        <div>
          <Field name="LoginId" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-4">
        <label className="text-left">Password</label>
        <div>
          <Field name="Password" component={InputField} type="text" className="form-control" />
        </div>
      </div>
    </div>

    <div className="py-2 mt-2">
      <Button
        type="submit"
        className="btn btn-sm btn-success"
        loading={props.formLoading}
        loadingText="Saving..."
        disabled={props.pristine}
      >
        <i className="fas fa-save mr-1" />
        Save
      </Button>
      <Button
        onClick={props.reset}
        className="btn btn-sm btn-outline-secondary ml-2"
        disabled={props.pristine}
      >
        <i className="fas fa-redo mr-1" />
        Reset
      </Button>
    </div>
  </form>
);

AdminsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  pristine: PropTypes.bool.isRequired
};

AdminsForm.defaultProps = {
  formLoading: null
};

const adminsForm = reduxForm({
  form: 'Admins Form',
  initialValues: {},
  validate: AdminsValidation,
  enableReinitialize: true
})(AdminsForm);

const mapStateToProps = ({ admins }) => ({
  initialValues: admins.form
});

export default connect(mapStateToProps)(adminsForm);
