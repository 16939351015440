import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as actions from '../../redux/actions/actions-coursepage';
import Button from '../../components/Button';
import SearchBar from '../../components/SearchBar';
import BreadCrumb from '../../components/Breadcrumb';
import Loader from '../../components/Loader';

class CoursePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchVal: ''
    };
    props.getCoursePagelist();
  }


  deleteCoursePage = (id) => {
    confirmAlert({
      title: 'Delete CoursePage',
      message: 'Are you sure to delete the CoursePage?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.props.deleteCoursePage(id)
              .then(() => {
                toast.success('Deleted Successfully');
              }).catch((error) => {
                toast.error(error.message);
              });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  handleTableFilter = (search) => {
    this.setState({ searchVal: search });
  }

  setTableOption = () => (this.props.CoursePageList && this.props.tableLoading ? <Loader /> : 'No Data Found');

  render() {
    const searchVal = this.state.searchVal.toLowerCase();
    const coursePage = this.props.CoursePageList.filter((item) => item.Title.toLowerCase().includes(searchVal) || item.Type.toLowerCase().includes(searchVal));

    const columns = [{
      dataField: 'Title',
      text: 'Title'
    }, {
      dataField: 'SlugURL',
      text: 'Slug URL'
    }, {
      dataField: 'Type',
      text: 'Type',
      formatter: (cell) => (cell || '--')
    }, {
      dataField: 'CreatedDateTime',
      text: 'Created at',
      formatter: (cell) => (
        new Date(cell).toLocaleDateString()
      ),
      sort: true
    }, {
      dataField: 'Id',
      text: 'Actions',
      headerStyle: {
        width: '160px'
      },
      formatter: (cell, row) => (
        <>
          <span
            role="presentation"
            className="fa fa-pencil-alt tableEdit"
            onClick={() => this.props.history.push(`/course-page-editor/${cell}`)}
          />
          <span
            role="presentation"
            className="fa fa-trash tableDelete"
            onClick={() => this.deleteCoursePage(cell)}
          />
          <span
            role="presentation"
            className="fa fa-clone tableLM"
            onClick={() => this.props.cloneCoursePage(cell)}
          />
        </>
      )
    }];

    const defaultSorted = [{
      dataField: 'CreatedDateTime',
      order: 'desc'
    }];

    return (
      <div>
        <div className="container">
          <BreadCrumb currentPage="Course Page" />
          <div className="col-md-12 py-3 mb-5">
            <div className="card border-secondary">
              <div className="card-header">
                <div className="row">
                  <div className="col-md-10">
                    {/* <span className="fa fa-layer-group cardTitle"> CoursePage</span> */}
                    <SearchBar
                      placeholder="Search by Title, Type"
                      handleChange={(e) => this.handleTableFilter(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2 text-right">
                    <Button
                      className="btn btn-sm btn-secondary"
                      onClick={() => this.props.history.push('/course-page-editor/0')}
                    >
                      <span className="fas fa-plus" />
                      {' '}
                      Add New
                    </Button>
                  </div>
                </div>
              </div>
              <div className="card-body text-secondary">

                <BootstrapTable
                  keyField="Id"
                  data={coursePage}
                  columns={columns}
                  pagination={paginationFactory()}
                  defaultSorted={defaultSorted}
                  noDataIndication={() => this.setTableOption()}
                  hover
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CoursePage.defaultProps = {
  tableLoading: false,
  CoursePageList: null,
  getCoursePagelist: null,
  deleteCoursePage: null,
  cloneCoursePage: null
};

CoursePage.propTypes = {
  tableLoading: PropTypes.bool,
  CoursePageList: PropTypes.array,
  getCoursePagelist: PropTypes.func,
  deleteCoursePage: PropTypes.func,
  cloneCoursePage: PropTypes.func
};

const mapStateToProps = ({ coursepage }) => ({
  tableLoading: coursepage.tableLoading,
  CoursePageList: coursepage.CoursePageList
});

const mapDispatchToProps = (dispatch) => ({

  getCoursePagelist: () => dispatch(actions.fetchCoursePage()),
  deleteCoursePage: (id) => dispatch(actions.deleteCoursePage(id)),
  cloneCoursePage: (id) => dispatch(actions.cloneCoursePage(id))

});

export default connect(mapStateToProps, mapDispatchToProps)(CoursePage);
