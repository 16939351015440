import React from 'react';
import SideBar from './SideBar';

const WithLayout = (ComposedComponent) => (props) => (
  <div className="container-fluid">
    <div className="row">
      <SideBar />
      <div className="col-md-9 ml-sm-auto col-lg-10 px-4">
        <ComposedComponent {...props} />
      </div>
    </div>
  </div>
);

export default WithLayout;
