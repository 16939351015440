import { learningModuleActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utils';

const initialFormData = {
  Id: '0',
  Name: '',
  Description: ''
};

const defaultState = {
  lmList: [],
  tableLoading: false,
  form: initialFormData,
  formLoading: false,
  formError: null,
  deleteId: null
};


export default (state = defaultState, actions) => {
  switch (actions.type) {
    case learningModuleActions.LM_FETCH_START:
      return updateState(state, { tableLoading: true });
    case learningModuleActions.LM_FETCH_SUCCESS:
      return updateState(state, { tableLoading: false, lmList: actions.payload });
    case learningModuleActions.LM_FETCH_ERROR:
      return updateState(state, { tableLoading: false, ...actions.payload });
    case learningModuleActions.LM_SAVE_START:
      return updateState(state, { formLoading: true, formError: null, form: initialFormData });
    case learningModuleActions.LM_SAVE_SUCCESS:
      return updateState(state, { formLoading: false, form: initialFormData });
    case learningModuleActions.LM_SAVE_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    case learningModuleActions.LM_SET_FORM:
      return updateState(state, { ...actions.payload, formError: null });
    case learningModuleActions.LM_RESET_FORM:
      return updateState(state, { form: initialFormData });
    default:
      return state;
  }
};
