import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Button from '../../components/Button';
import SearchBar from '../../components/SearchBar';
import BreadCrumb from '../../components/Breadcrumb';
import * as actions from '../../redux/actions/actions-admins';
import Loader from '../../components/Loader';


const Admins = ({
  adminsData, tableLoading, getAdmins, deleteAdmins, history
}) => {
  const [searchVal, setSearchval] = useState('');

  useEffect(() => {
    getAdmins();
  }, []);

  const setTableOption = () => (adminsData && tableLoading ? <Loader /> : 'No Data Found');

  const searchvalue = searchVal && searchVal.toLowerCase();
  const adminData = adminsData.filter((item) => (item.AdminName && item.AdminName.toLowerCase().includes(searchvalue)) || (item.EmailId && item.EmailId.toLowerCase().includes(searchvalue)));

  const handleTableFilter = (search) => {
    setSearchval(search);
  };

  const deleteAdmin = (id) => {
    confirmAlert({
      title: 'Delete Admin',
      message: 'Are you sure to delete the Admin?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            deleteAdmins(id)
              .then(() => {
                toast.success('Deleted Successfully');
              }).catch((error) => {
                toast.error(error.message);
              });
          }
        },
        {
          label: 'No'
        }
      ]
    });
  };

  const columns = [{
    dataField: 'AdminName',
    text: 'Admin Name'
  },
  {
    dataField: 'LoginId',
    text: 'Login Id'
  },
  {
    dataField: 'EmailId',
    text: 'Email Id'
  },
  {
    dataField: 'Password',
    text: 'Password'
  },
  {
    dataField: 'CreatedDateTime',
    text: 'Created at',
    formatter: (cell, row) => (
      new Date(cell).toLocaleDateString()
    ),
    sort: true
  }, {
    dataField: 'Id',
    text: 'Actions',
    headerStyle: {
      width: '140px'
    },
    formatter: (cell) => (
      <>
        <span
          role="presentation"
          className="fa fa-pencil-alt tableEdit"
          onClick={() => history.push(`/admins-editor/${cell}`)}
        />
        <span
          role="presentation"
          className="fa fa-trash tableDelete"
          onClick={() => deleteAdmin(cell)}
        />
      </>
    )
  }];

  const defaultSorted = [{
    dataField: 'CreatedDateTime',
    order: 'desc'
  }];


  return (
    <div>
      <div className="container">
        <BreadCrumb currentPage="Admins" />
        <div className="col-md-12 py-3 mb-5">
          <div className="card border-secondary">
            <div className="card-header">
              <div className="row">
                <div className="col-md-10">
                  {/* <span className="fa fa-file-alt cardTitle"> Users</span> */}
                  <SearchBar
                    placeholder="Search by Admin Name, Email Id"
                    handleChange={(e) => handleTableFilter(e.target.value)}
                  />
                </div>
                <div className="col-md-2">
                  <Button
                    className="btn btn-sm btn-secondary ml-3"
                    onClick={() => history.push('/admins-editor/0')}
                  >
                    <span className="fas fa-plus" />
                    {' '}
                    Add New
                  </Button>
                </div>
              </div>
            </div>
            <div className="card-body text-secondary">

              <BootstrapTable
                keyField="Id"
                data={adminData}
                columns={columns}
                pagination={paginationFactory()}
                defaultSorted={defaultSorted}
                noDataIndication={() => setTableOption()}
                hover
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


Admins.defaultProps = {
  tableLoading: false,
  adminsData: [],
  getAdmins: null,
  deleteAdmins: null
};

Admins.propTypes = {
  tableLoading: PropTypes.bool,
  adminsData: PropTypes.array,
  getAdmins: PropTypes.func,
  deleteAdmins: PropTypes.func,
  history: PropTypes.object.isRequired
};

const mapStateToProps = ({ admins }) => ({
  adminsData: admins.adminsList,
  tableLoading: admins.tableLoading
});

const mapDispatchToProps = (dispatch) => ({
  getAdmins: () => dispatch(actions.fetchAdmins()),
  deleteAdmins: (id) => dispatch(actions.deleteAdmins(id))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admins));
