import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import Button from '../Button';
import {
  InputField, SelectField
} from '../ReduxFormComponents';


const BranchForm = (props) => (
  <form onSubmit={props.handleSubmit}>
    <Field name="Id" component={InputField} type="hidden" className="form-control" />
    <div className="row form-group">
      <div className="col-md-6">
        <label className="text-left">Branch Name</label>
        <div>
          <Field name="Name" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-6">
        <label className="text-left">Branch Code</label>
        <div>
          <Field name="Code" component={InputField} type="text" placeholder="CMPINRBR000XX" className="form-control" />
        </div>
      </div>
    </div>

    <div className="row form-group">
      <div className="col-md-4">
        <label className="text-left">Country (ex : India)</label>
        <div>
          <Field name="Country" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-4">
        <label className="text-left">State / Province</label>
        <div>
          <Field name="State" component={InputField} type="text" className="form-control" />
        </div>
      </div>
      <div className="col-md-4">
        <label className="text-left">Payment Type</label>
        <div>
          <Field name="PaymentType" component={SelectField} className="form-control">
            <option value="">Select</option>
            <option key="INR" value="INR">INR</option>
            <option key="SGD" value="SGD">SGD</option>
            <option key="MYR" value="MYR">MYR</option>
            <option key="USD" value="USD">USD</option>
          </Field>
        </div>
      </div>
    </div>

    <div className="py-2 mt-2">
      <Button
        type="submit"
        className="btn btn-sm btn-success"
        loading={props.formLoading}
        loadingText="Saving..."
        disabled={props.pristine}
      >
        <i className="fas fa-save mr-1" />
        Save
      </Button>
      <Button
        onClick={props.reset}
        className="btn btn-sm btn-outline-secondary ml-2"
        disabled={props.pristine}
      >
        <i className="fas fa-redo mr-1" />
        Reset
      </Button>
    </div>
  </form>
);

BranchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  formLoading: PropTypes.bool,
  formError: PropTypes.string,
  pristine: PropTypes.bool.isRequired
};

BranchForm.defaultProps = {
  formLoading: null,
  formError: null
};

const branchForm = reduxForm({
  form: 'branch Form',
  initialValues: {},
  enableReinitialize: false
})(BranchForm);

const mapStateToProps = ({ branch }) => ({
  initialValues: branch.form
});

export default connect(mapStateToProps)(branchForm);
