import { toast } from 'react-toastify';
import Service from '../../lib/Service';
import { adminRoleActions } from '../ActionTypes';


export const fetchAdminRoles = () => async (dispatch) => {
  try {
    dispatch({
      type: adminRoleActions.ADMINROLES_FETCH_START
    });

    const resData = await Service.GET({
      name: 'admin-roles',
      queryString: ''
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: adminRoleActions.ADMINROLES_FETCH_SUCCESS,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: adminRoleActions.ADMINROLES_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};

export const saveAdminRoles = (formData) => async (dispatch) => {
  try {
    const payload = {
      ...formData
    };
    dispatch({
      type: adminRoleActions.ADMINROLES_SAVE_START
    });
    await Service[formData.Id === '0' ? 'POST' : 'PUT']({
      name: 'admin-roles',
      payload
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(toast.error(res.message));
    });
    dispatch({
      type: adminRoleActions.ADMINROLES_SAVE_SUCCESS
    });
    toast.success('Saved Successfully');
    dispatch(fetchAdminRoles());
  } catch (error) {
    dispatch({
      type: adminRoleActions.ADMINROLES_SAVE_FAILED,
      payload: { formError: error.message }
    });
  }
};

export const editAdminRoles = (adminRoleId) => async (dispatch, getState) => {
  try {
    const { adminRoleList } = getState().adminRoles;
    const data = adminRoleList.find((item) => item.Id === adminRoleId);
    if (!data) {
      throw new Error('Something went wrong');
    }
    dispatch({
      type: adminRoleActions.ADMINROLES_SET_FORM,
      payload: { form: data }
    });
  } catch (error) {
    toast.error(error.message);
  }
};

export const resetForm = () => (dispatch) => dispatch({ type: adminRoleActions.ADMINROLES_RESET_FORM });

export const toggleAdminRoleModal = (show) => (dispatch) => {
  dispatch({
    type: adminRoleActions.ADMINROLES_TOGGLE_MODAL,
    payload: {
      showCrudModal: show
    }
  });
};

export const deleteAdminRoles = (Id) => async (dispatch) => Service.DELETE({
  name: 'admin-roles',
  payload: { Id }
}).then((res) => {
  if (res.success) {
    dispatch(fetchAdminRoles());
    return true;
  }
  throw new Error(res.message);
});

// AdminRole Mapping
export const fetchMenuList = (roleId) => async (dispatch) => {
  try {
    dispatch({
      type: adminRoleActions.ADMINROLE_MENU_FETCH_START
    });
    const data = await Service.GET({
      name: 'menu/role',
      queryString: `RoleId=${roleId}`
    }).then((res) => {
      if (res.success) return res.data;
      throw new Error(res.message);
    });
    dispatch({
      type: adminRoleActions.ADMINROLE_MENU_FETCH_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: adminRoleActions.ADMINROLE_MENU_FETCH_FAILED,
      payload: { error: error.message }
    });
    toast.error('Error fetching menus');
    console.log(error);
  }
};

export const handleEdit = (id) => (dispatch) => {
  dispatch({
    type: adminRoleActions.ADMINROLE_MENU_EDIT,
    payload: id
  });
};

export const saveMenuMappingRole = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: adminRoleActions.ADMINROLE_MAPPING_SAVE_START
    });
    const payload = {
      ...formData,
      OrderNumber: String(formData.OrderNumber),
      RelativeMenus: JSON.stringify(formData.RelativeMenus)
    };
    await Service.POST({
      name: 'menu/role',
      payload
    }).then((res) => {
      if (res.success) return res.data;
      throw new Error(res.message);
    });
    toast.success('Saved Successfully');
    dispatch(fetchMenuList(formData.RoleId));
    dispatch({
      type: adminRoleActions.ADMINROLE_MAPPING_SAVE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: adminRoleActions.ADMINROLE_MAPPING_SAVE_FAILED,
      payload: {
        formError: error.message
      }
    });
  }
};

export const handleDelete = (RoleId, Id) => async () => Service.DELETE({
  name: 'admin-roles',
  queryString: `RoleId=${RoleId}`,
  payload: { Id }
}).then((res) => {
  if (res.success) return true;
  throw new Error(res.message);
});
