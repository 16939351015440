import { coursePageActions } from '../ActionTypes';
import { updateState } from '../../middlewares/utils';

const initialFormData = {
  Id: '0',
  Title: '',
  SlugURL: '',
  FeaturedYoutubeId: '',
  LearningObjectives: '',
  BannerDescription: '',
  Type: '',
  LongDescription: '',
  CertificateURL: '',
  BrochureURL: '',
  YoutubeThumbnailURL: '',
  ThumbnailURL: '',
  Testimonial: ''
};

const defaultState = {
  CoursePageList: [],
  TestimonialList: [],
  tableLoading: false,
  form: initialFormData,
  formLoading: false,
  formError: null,
  deleteId: null
};


export default (state = defaultState, actions) => {
  switch (actions.type) {
    case coursePageActions.COURSE_PAGE_FETCH_START:
      return updateState(state, { tableLoading: true });
    case coursePageActions.COURSE_PAGE_FETCH_SUCCESS:
      return updateState(state, { tableLoading: false, CoursePageList: actions.payload });
    case coursePageActions.COURSE_PAGE_FETCH_ERROR:
      return updateState(state, { tableLoading: false, ...actions.payload });
    case coursePageActions.CP_TESTIMONIAL_FETCH_START:
      return updateState(state, { formLoading: true });
    case coursePageActions.CP_TESTIMONIAL_FETCH_SUCCESS:
      return updateState(state, { formLoading: false, TestimonialList: actions.payload });
    case coursePageActions.CP_TESTIMONIAL_FETCH_ERROR:
      return updateState(state, { formLoading: false, ...actions.payload });
    case coursePageActions.COURSE_PAGE_SAVE_START:
      return updateState(state, { formLoading: true, formError: null, form: initialFormData });
    case coursePageActions.COURSE_PAGE_SAVE_SUCCESS:
      return updateState(state, { formLoading: false, form: initialFormData });
    case coursePageActions.COURSE_PAGE_SAVE_FAILED:
      return updateState(state, { formLoading: false, ...actions.payload });
    case coursePageActions.COURSE_PAGE_SET_FORM:
      return updateState(state, { ...actions.payload, formError: null });
    case coursePageActions.COURSE_PAGE_RESET_FORM:
      return updateState(state, { form: initialFormData });
    default:
      return state;
  }
};
