/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => (
  <button
    type={props.type}
    className={props.className}
    title={props.title}
    id={props.id}
    disabled={!!(props.disabled || props.loading)}
    onClick={props.onClick}
  >
    {(props.loading && props.loadingText) || props.children}
  </button>
);

Button.defaultProps = {
  type: 'button',
  className: 'btn btn-sm btn-primary',
  id: null,
  title: null,
  disabled: false,
  onClick: null,
  loading: false,
  loadingText: '',
  children: '<i class="fa fa-sync"></i>',
};

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  loadingText: PropTypes.any,
  children: PropTypes.any
};

export default Button;
