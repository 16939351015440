import { toast } from 'react-toastify';
import Service from '../../lib/Service';
import { batchActions } from '../ActionTypes';


export const fetchBatch = () => async (dispatch) => {
  try {
    dispatch({
      type: batchActions.BATCH_FETCH_START
    });
    const resData = await Service.GET({
      name: 'batch',
      queryString: ''
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: batchActions.BATCH_FETCH_SUCCESS,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: batchActions.BATCH_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};

// get branch
export const fetchBranch = () => async (dispatch) => {
  try {
    dispatch({
      type: batchActions.BATCH_BRANCH_FETCH_START
    });

    const resData = await Service.GET({
      name: 'branch',
      queryString: ''
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: batchActions.BATCH_BRANCH_FETCH_SUCCESS,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: batchActions.BATCH_BRANCH_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};

// get CoursesPage
export const fetchCourses = () => async (dispatch) => {
  try {
    dispatch({
      type: batchActions.BATCH_COURSES_FETCH_START
    });

    const resData = await Service.GET({
      name: 'course-page',
      queryString: ''
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: batchActions.BATCH_COURSES_FETCH_SUCCESS,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: batchActions.BATCH_COURSES_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};

// get Installment
export const fetchInstallment = () => async (dispatch) => {
  try {
    dispatch({
      type: batchActions.BATCH_INSTALLMENT_FETCH_START
    });

    const resData = await Service.GET({
      name: 'installment',
      queryString: ''
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: batchActions.BATCH_INSTALLMENT_FETCH_SUCCESS,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: batchActions.BATCH_INSTALLMENT_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};

export const saveBatch = (formData) => async (dispatch) => {
  try {
    const Courses = formData.Courses ? formData.Courses.map((item) => (item.Id ? item.Id : item)) : [];
    const Installment = formData.Installment ? formData.Installment.map((item) => (item.Id ? item.Id : item)) : [];

    const BranchesChk = typeof formData.Branches !== 'undefined' ? formData.Branches : 'null';

    const Branches = formData.branches ? formData.branches.map((item) => ({
      BranchId: item.branch,
      StartTime: item.timing.split('-')[0],
      EndTime: item.timing.split('-')[1],
      Price: item.price,
      DiscountedPrice: item.discountPrice
    })) : BranchesChk;

    const payload = {
      ...formData,
      Courses,
      Installment,
      Branches
    };

    dispatch({
      type: batchActions.BATCH_SAVE_START
    });

    await Service[formData.Id === '0' ? 'POST' : 'PUT']({
      name: 'batch',
      payload
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(toast.error(res.message));
    });
    dispatch({
      type: batchActions.BATCH_SAVE_SUCCESS
    });
    toast.success('Saved Successfully');
    setTimeout(() => { window.location.href = '/batch'; }, 1000);
  } catch (error) {
    dispatch({
      type: batchActions.BATCH_SAVE_FAILED,
      payload: { formError: error.message }
    });
  }
};

export const editBatch = (batchId) => async (dispatch, getState) => {
  try {
    const { batchList } = getState().batch;
    const data = batchList.find((item) => item.Id === batchId);
    if (!data) {
      throw new Error('Something went wrong');
    }
    dispatch({
      type: batchActions.BATCH_SET_FORM,
      payload: { form: data }
    });
  } catch (error) {
    toast.error(error.message);
  }
};

export const resetForm = () => (dispatch) => dispatch({ type: batchActions.BATCH_RESET_FORM });

export const deleteBatch = (Id) => async (dispatch) => Service.DELETE({
  name: 'batch',
  payload: { Id }
}).then((res) => {
  if (res.success) {
    dispatch(fetchBatch());
    return true;
  }
  throw new Error(res.message);
});
