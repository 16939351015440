import queryString from 'query-string';
import { toast } from 'react-toastify';
import Service from '../../lib/Service';
import { learningObjectActions } from '../ActionTypes';


export const fetchLOBJ = (LearningModuleId) => async (dispatch) => {
  try {
    dispatch({
      type: learningObjectActions.LOBJ_FETCH_START
    });

    const resData = await Service.GET({
      name: 'learning-object',
      queryString: queryString.stringify({ LearningModuleId })
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(res.message);
    });
    dispatch({
      type: learningObjectActions.LOBJ_FETCH_SUCCESS,
      payload: resData
    });
  } catch (error) {
    dispatch({
      type: learningObjectActions.LOBJ_FETCH_FAILED,
      payload: { error: error.message }
    });
  }
};

export const saveLOBJ = (formData) => async (dispatch) => {
  try {
    const payload = {
      ...formData
    };
    dispatch({
      type: learningObjectActions.LOBJ_SAVE_START
    });
    await Service[formData.Id === '0' ? 'POST' : 'PUT']({
      name: 'learning-object',
      payload
    }).then((res) => {
      if (res.success) {
        return res.data;
      }
      throw new Error(toast.error(res.message));
    });
    dispatch({
      type: learningObjectActions.LOBJ_SAVE_SUCCESS
    });
    toast.success('Saved Successfully');

    setTimeout(() => { window.location.href = `/learning-object/${formData.LearningModuleId}`; }, 1000);
  } catch (error) {
    dispatch({
      type: learningObjectActions.LOBJ_SAVE_FAILED,
      payload: { formError: error.message }
    });
  }
};

export const editLOBJ = (LOBJId) => async (dispatch, getState) => {
  try {
    const { lobjList } = getState().learningObject;
    const data = lobjList.find((item) => item.Id === LOBJId);
    if (!data) {
      throw new Error('Something went wrong');
    }
    dispatch({
      type: learningObjectActions.LOBJ_SET_FORM,
      payload: { form: data }
    });
  } catch (error) {
    toast.error(error.message);
  }
};

export const resetForm = () => (dispatch) => dispatch({ type: learningObjectActions.LOBJ_RESET_FORM });

export const deleteLOBJ = (Id, LearningModuleId) => async (dispatch) => Service.DELETE({
  name: 'learning-object',
  payload: { Id }
}).then((res) => {
  if (res.success) {
    dispatch(fetchLOBJ(LearningModuleId));
    return true;
  }
  throw new Error(res.message);
});
