/* eslint-disable no-bitwise */
export const updateState = (state, newState) => ({
  ...state,
  ...newState
});

export const capitalizeWord = (value) => {
  const string = String(value);
  if (value) {
    return string[0].toUpperCase() + string.slice(1);
  }
  return null;
};

export const convertTimeFormat = (time) => {
  let newTime = time;
  newTime = newTime.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [newTime];
  if (newTime.length > 1) {
    newTime = newTime.slice(1);
    newTime[5] = +newTime[0] < 12 ? ' AM' : ' PM';
    newTime[0] = +newTime[0] % 12 || 12;
  }
  return newTime.join('');
};
